import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Container } from "react-bootstrap";
import { Auth } from "aws-amplify";
import config from '../../config';
import store from 'store';
import CONSTANTS from '../../libs/constants';
import { FaSpotify, FaYoutube, FaMusic } from 'react-icons/fa';
import { useQuery } from "../../libs/hooksLib";
import { useHistory } from 'react-router-dom';

import {isSafari, isMobile} from "react-device-detect";



const STAGE_GET_PLAYLISTS = 'get_playlists';
const STAGE_GET_YOUTUBE_PLAYLISTS = 'get_youtube_playlists';
const STAGE_GET_MUSINGO_PLAYLISTS = 'get_musingo_playlists';


async function getUser() {
  let info = await Auth.currentUserInfo();

  if(!info || !info.attributes){
    return null;
  }

  return info.attributes.sub
}


const Spotify = () => {

  const query = useQuery();

  const [returnPage, setReturnPage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();


  


  const [user, setUser] = useState("");


  useEffect(() => {


    async function onLoad() {


      let currentUser = await getUser();

      if(currentUser){

      store.set(CONSTANTS.STORE.MUSINGO_USER, currentUser);
      setUser(currentUser);
      }


    }

    onLoad();

    if (query.get(CONSTANTS.QUERY.RETURN_PAGE)) {
      setReturnPage(query.get(CONSTANTS.QUERY.RETURN_PAGE));
    }
    else{
      setReturnPage(CONSTANTS.PAGE.WIZARD);
      store.set(CONSTANTS.STORE.WIZARD_STAGE, STAGE_GET_PLAYLISTS);
      
    }



  }, [query, user]);

  const addReturnPage = () =>{

    if(returnPage){

      return `?${CONSTANTS.QUERY.RETURN_PAGE}=${returnPage}`;

    }
    else{
      return '';
    }

  }


  const handleYoutubeClick = (event) => {

    event.preventDefault();
    store.set(CONSTANTS.STORE.WIZARD_STAGE, STAGE_GET_YOUTUBE_PLAYLISTS);

    history.go(0);

  }

  const handleMusingoPlaylistClick = (event) => {

    event.preventDefault();
    store.set(CONSTANTS.STORE.WIZARD_STAGE, STAGE_GET_MUSINGO_PLAYLISTS);

    history.go(0);

  }


  return (
    <div className="content-wrap">
      {/* <Link to="/" >
            <img alt="logo" src="/images/logo.png" className="App-logo" />
            </Link> */}
      <Container>
        <Row className="justify-content-md-center">
          <Col xs md='6' className='justify-content-md-center text-center'>
            <h1>Setup Wizard (2)</h1>

            <p>Click on the button below to <strong> connect Musingo Bingo </strong> with your Spotify account</p>

            { isSubmitting &&
                         <p>  Connecting...  </p>
                            }

          {!isMobile && !isSafari &&  <div>
              <a rel="noopener noreferrer" href={`${config.openApiGateway.URL}/spotify/login/${user}${addReturnPage()}`}>
                <Button variant="success" onClick={()=>{setIsSubmitting(true)}} size="lg" className="spotify">
                  <FaSpotify/>
                  Click Here
                </Button>

              </a>
            </div> }


            {isMobile && isSafari &&  <div>

              <p><strong>The Spotify player is not available on the Mobile or Safari</strong></p>
              {/* <a rel="noopener noreferrer" href={`${config.openApiGateway.URL}/spotify/login/${user}${addReturnPage()}`}>
                <Button variant="success" onClick={()=>{setIsSubmitting(true)}} size="lg" className="spotify">
                  <FaSpotify/>
                  Click Here
                </Button>

              </a> */}
            </div> }


          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col xs md='6' className='justify-content-md-center text-center'>
          {!isMobile && !isSafari && <h1>OR</h1> }

            <p>Click on the button below to <strong> use a YouTube Playlist</strong> </p>

            <div>          
                <Button variant="success" onClick={handleYoutubeClick} size="lg" className="youtube">
                  <FaYoutube/>
                  Click Here
                </Button>

            </div>


          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col xs md='6' className='justify-content-md-center text-center'>
            <h1>OR</h1>

            <p>Click on the button below to <strong> use a Curated Musingo Playlist</strong> </p>

            <div>          
                <Button variant="success" onClick={handleMusingoPlaylistClick} size="lg" className="musingo">
                  <FaMusic/>
                  Click Here
                </Button>

            </div>


          </Col>
        </Row>

      </Container>
    </div>
  );
}

export default Spotify;