import React, {useState, useEffect, useCallback } from "react";
import { Row, Col, Container, Form, Alert, Button, Modal } from "react-bootstrap";
import { useFormFields, useLoaded } from "../libs/hooksLib";
import LoaderButton from "../components/LoaderButton";
import { updateplaylistManual, removePlaylist } from "../libs/apiLib";
import { limitText } from "../libs/util";
import CONSTANTS from '../libs/constants';
import store from 'store';
import { useHistory } from 'react-router-dom';




export default function Playlist() {


  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [messageVariant, setMessageVariant] = useState('info');
  const [playlistName, setPlaylistName] = useState('');
  const [playlistType, setPlaylistType] = useState('');
  const [tracks, setTracks] = useState([]);
  // const [playlistDetails, setPlaylistDetails] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [editTrack, setEditTrack] = useState({});
  const [editTrackIndex, setEditTrackIndex] = useState(-1);
  // const [addAsOwner, setAddAsOwner] =  useState(false);
  const [playlistid] = useState(store.get(CONSTANTS.STORE.PLAYLIST) ? store.get(CONSTANTS.STORE.PLAYLIST) : '');
  const history = useHistory();

  // console.log(playlistDetails);

  const loaded = useCallback(useLoaded('playlistOne', setIsLoading, playlistid));

  useEffect(() => {

    let isCurrent = true;

    if (loaded.error) {

      if (loaded.error.status !== 404) {

        if (isCurrent) {
        setShowMessage(true);
        setMessageText(loaded.error.message);
        setMessageVariant('danger');
        }
      }


    }
    else if(loaded.data) {


     
      // let selectedFields  = (({ image, images, name, playlist, tracks, type }) => ({ image, images, name, playlist, tracks, type }))(loaded.data);
      // console.log('selectedFields', selectedFields);
   
      if (isCurrent) {
      setTracks(loaded.data.tracks);
      setPlaylistName(loaded.data.name);
      setPlaylistType(loaded.data.type)
      // setPlaylistDetails(selectedFields);
      }

    }

    return () => {
      isCurrent = false;
    }


  }, [loaded]);
  


  const [fields, handleFieldChange] = useFormFields({
    playlisturl: '',
    editableName: ''
   

  });

  const handleErroClose = () => {
    setShowMessage(false);
  }

  async function handleDeletePlaylist(){

   
    try{

      await removePlaylist(playlistid);

      setShowMessage(true);
      setMessageText('Playlist Deleted');
      setMessageVariant('success');
      history.push('/playlists');



      }
      catch(e){

        console.log('error', e);

        setShowMessage(true);
        setMessageText('Something went wrong');
        setMessageVariant('danger');

      }

  }


  async function handleAddPlaylist(){

    setIsLoadingAdd(true);

    if(tracks && tracks.length > 0){

      let playlistDetails = {

        playlist: playlistid,
        tracks


      };


      try{

      await updateplaylistManual(playlistDetails);

      setShowMessage(true);
      setMessageText('Playlist Updated');
      setMessageVariant('success');



      }
      catch(e){

        console.log('error', e);

        setShowMessage(true);
        setMessageText('Something went wrong');
        setMessageVariant('danger');

      }

    }

    setIsLoadingAdd(false);

  }


  async function handleEditName(event){

    event.preventDefault();

    let updatedTracks = [...tracks];

    updatedTracks[editTrackIndex].name = fields.editableName;

    setTracks(updatedTracks);

    fields.editableName = '';

    setShowModalEdit(false);

  }



async function handleTrackEdit(track, index){

  setEditTrack(track);
  setEditTrackIndex(index);

  setShowModalEdit(true);

}

async function handleDeleteTrack(event){

  event.preventDefault();

  console.log('handleDeleteTrack', editTrackIndex, tracks);

   let updatedTracks = [...tracks];

   updatedTracks.splice(editTrackIndex, 1);

   console.log('updatedTracks',updatedTracks);

   setTracks(updatedTracks);

   setShowModalEdit(false);

}

function handleModalEditClose() {

  setShowModalEdit(false);
  setEditTrack({});
  fields.editableName = '';
}

  return (
    <div id="bingo" className="content-wrap">

      <Container fluid>

        <Row className="justify-content-center">
          <Col xs md="6" className="justify-content-center text-center">
            <h1>Playlist</h1>

            <br/>

            {
              isLoading && <p>Loading...</p>
            }

            <h2>{playlistName}</h2>

          {playlistType === 'youtube' &&  <p><br/> Click on the track to edit</p> }
            {/* <br/>
            <h2>{playlistType}</h2> */}

         

      
        <Row className="card-wrap">


           {tracks.length > 0 &&

                 tracks.map(
                   (track, i) =>
                     <Col key={i} xs="6" md="4" lg="3" className="d-flex align-items-stretch">
                       <div className="d-flex align-items-stretch">
                       {/* <Button  > */}
                         <Button onClick={() => handleTrackEdit(track, i)} disabled={playlistType !== 'youtube'}  >

                           <img alt={track.name} src={track.images[0].url ? track.images[0].url : track.images[0]}   />
                           <span>{ track.name ? limitText(track.name) : 'No Name' }</span> {' '}
                                                      
                         </Button>
                         
                       </div>
                     </Col>

               )
               }

        </Row>

      
        {tracks.length > 0 &&

                <>
                    <LoaderButton
                    className="pull-right"
                    // block
                    type="submit"
                    isLoading={isLoadingAdd}
                    text="Delete"
                    loadingText="Deleting..."
                    disabled={false}
                    onClick={handleDeletePlaylist}
                  />   {' '}

                      <LoaderButton
                      className="pull-right"
                      // block
                      type="submit"
                      isLoading={isLoadingAdd}
                      text="Update"
                      loadingText="Updating..."
                      disabled={false}
                      onClick={handleAddPlaylist}
                    /> 


                  </>
            }   

        <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                      {messageText}
                    </Alert>

              </Col>
        </Row>


  { editTrack.images && <Modal show={showModalEdit} onHide={handleModalEditClose}>
          <Modal.Header closeButton>
                Musingobingo.com
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xs={12}>
                  <img alt={editTrack.name} src={editTrack.id ? editTrack.images[0] : editTrack.images[0]}   />
                    <br/>
                    <br/>
                    <p>{limitText(editTrack.name, false, false)}</p>
                    <Form onSubmit={handleEditName}>
                    <Form.Group controlId="editableName">
                        <Form.Control
                            autoFocus
                            type="text"
                            placeholder="Update name"
                            aria-label="Playlist Name"
                            value={fields.editableName}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                 

                  <Row>
                    <Col xs={6} className="d-flex align-items-end">
                    <LoaderButton
                      className="pull-right"
                      block
                      type="submit"
                      isLoading={isLoading}
                      text="Update"
                      loadingText="Updating..."
                      
                    />
                    </Col>

                    <Col xs={6} className="d-flex align-items-end">
                      <Button  aria-label="select" block onClick={handleDeleteTrack} >Delete</Button>
                    </Col>
                  </Row>
              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                {messageText}
              </Alert>
            </Form>
                    {/* <Button variant="primary" onClick={handleModalEditClose}>Close</Button> */}
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>
            
            }

      </Container>
    </div>
  );
}