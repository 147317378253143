import React from "react";
import { Row, Col, Container, Card, Table} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { FaCheckCircle } from 'react-icons/fa';




export default function Help() {
  
  const { isAuthenticated } = useAppContext();


  return (
    <div id="help" className="content-wrap">
             {!isAuthenticated &&
        <Link to="/" >
          <img alt="logo" src="/images/logo.png" className="App-logo" />
        </Link>
      }

      <Container fluid>

        <Row className="justify-content-center">
          <Col xs md="6" className="justify-content-center text-center">
            <h1>Help</h1>

            <p><strong>Follow these simple steps to create your Musingo Bingo party.</strong></p>

            {/* TODO: move this to a better place */}
            <div>
            <h4 id="theme">Step 1 - PERSONALISE YOUR GAME </h4>
            <p>All Musingo Bingo games come with our basic theme, but if it’s a special occasion, a corporate social or part of your own event we know you might want to give it your own stamp. Head to the THEME page and create your own invitation, bingo game message, theme picture and even the design of the bingo card tiles.</p>
            <p>Copy the URL code and send your Musingo Bingo game link to your friends. Once they open the link they will see your invite message and receive their first bingo card.</p>

            <iframe title="personalise" width="504" height="283" src="https://www.youtube.com/embed/f36AuYjEDFo" frameBorder="0" allow="accelerometer; encrypted-media" allowFullScreen></iframe>
            </div>

            <div>
            <h4 id="playlist">Step 2 - ADD YOUR PLAYLISTS</h4>
            <p>Create your Musingo Bingo playlist on Spotify. Your playlists should be <strong>public</strong> and have a minimum of <strong>12</strong> songs and a recommended maximum of <strong>24</strong>. DJ’s you should also create Spotify playlists of your planned set.</p>
            <p>Tap on the ‘Add Playlist’ button to connect your Spotify account to your Musingo Bingo game and select your chosen playlists.</p>
            <iframe title="addplaylist" width="504" height="283" src="https://www.youtube.com/embed/qXhV50QBWj4" frameBorder="0" allow="accelerometer; encrypted-media" allowFullScreen></iframe>
            </div>

            <div>
            <h4 id="create">Step 3 - CREATE A GAME</h4>
            <p>Create a bingo round game by selecting the ‘Create a Game’ button. Give your bingo round a name and pair it with its playlist by tapping ‘Select Game’. You will have the option to send the URL link to your players at this stage, but remember you can also do this from your Game page too. You are now ready to play Musingo Bingo.</p>

            <iframe title="creategame" width="504" height="283" src="https://www.youtube.com/embed/8v7wKbpSiec" frameBorder="0" allow="accelerometer; encrypted-media" allowFullScreen></iframe>
            </div>

            <div>
            <h4 id="spotify">Step 4 - CONNECT TO SPOTIFY</h4>
            <p>Connect your Musingo Bingo game to Spotify by tapping on your playlist. You will then be directed to Spotify, tap on the ‘Devices Available’ button on Spotify and connect it to Musingo Bingo and you’re ready to play.</p>

            <iframe title="spotify" width="504" height="283" src="https://www.youtube.com/embed/L3hPMVmFtkc" frameBorder="0" allow="accelerometer; encrypted-media" allowFullScreen></iframe>
            </div>

           

            <div>
            <h4 id="switch">Step 5 - START A NEW GAME ROUND</h4>
            <p>Once a player has won a Musingo Bingo game, you can start a new round by tapping the ‘Switch Game’ round.</p>

            <iframe title="newgame" width="504" height="283" src="https://www.youtube.com/embed/tm51MZBm91U" frameBorder="0" allow="accelerometer; encrypted-media" allowFullScreen></iframe>
            </div>


            <div>
            <h4 id="play">HOW TO PLAY MUSINGO BINGO</h4>
            <p>Tap the 'Play' button to enter the game. You will see your automatically generated bingo card. When you hear the song, tap the tile, until.....BINGO!!</p>

            <iframe title="play" width="504" height="283" src="https://www.youtube.com/embed/uTz9CSoVdAs" frameBorder="0" allow="accelerometer; encrypted-media" allowFullScreen></iframe>
            </div>


            <h4 id="support">BROWSER SUPPORT</h4>

            <Card>
                     
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Feature</th>
                              <th>Chrome</th>
                              <th>IE</th>
                              <th>Safari</th>
                              <th>Firefox</th>
                              <th>Mobile</th>
                            </tr>
                          </thead>
                          <tbody>


                          
                                <tr >
                                  <td>
                                   Bingo card
                                  </td>
                                  <td>
                                    <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                </tr>

                                <tr >
                                  <td>
                                    <span>Web Player</span>
                                  </td>
                                  <td>
                                    <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  
                                  </td>
                                </tr>

                                <tr >
                                  <td>
                                   Theme
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                </tr>

                                <tr >
                                  <td>
                                   Playlist
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                  <td>
                                  <FaCheckCircle/>
                                  </td>
                                </tr>

                                
                            
                          </tbody>
                        </Table>
                      
                    </Card>

          
           
          </Col>
        </Row>
      </Container>
    </div>
  );
}