import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { load } from "../libs/apiLib";
import config from '../config';
import PubNub from 'pubnub';


const pubnub = new PubNub({
  subscribeKey: config.pubnub.SUBSCRIBE_KEY,
  publishKey: config.pubnub.PUBLISH_KEY
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      
      setValues({
        ...fields,
        [event.target.id]: event.target.value
      });
    }
  ];
}


export function useQuery() {
  return new URLSearchParams(useLocation().search); 
}

export function   useLoaded(type, setIsLoading, input){
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  
  useEffect(() => {

      let isCurrent = true;

      if(setIsLoading) setIsLoading(true);

      load(type)(input)
      .then(data =>{
          if(isCurrent) setData(data);
          if(isCurrent && setIsLoading) setIsLoading(false);
          setDataLoaded(true);
      })
      .catch(e =>{

          let message = 'Something went wrong';
          let status = '';

          if(e.response){
          
          message = e.response.data ? e.response.data.message : e.message;
          status = e.response.status ? e.response.status : null;
          
          }

          if(isCurrent && setIsLoading) setIsLoading(false);
          if(isCurrent) setError({message, status});

          
      })

      return () => {
          isCurrent = false;
      }

  }, [type, input, setIsLoading]);

  

  return {data, error, dataLoaded};

}

export function useWebsocketSubscription(channels, messageAction){

  useEffect(() => {
    pubnub.addListener({
      message: messageEvent => {
        if(messageAction){
          messageAction(messageEvent);
        }
      },
    });
  
    pubnub.subscribe({ channels });
  }, [channels, messageAction]);

}

export function useWebsocketPublish(){

  return useCallback(
    async (message,channel)  => {
     
      await pubnub.publish({
        channel,
        message,
      });
  
    },
    []
  );
}














