import React, { useState } from "react";
// import { useHistory, Link } from "react-router-dom";
import { Link } from "react-router-dom";
import {FormGroup, FormControl, Row, Container, Col, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
// import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from "../../libs/hooksLib";
// import { onError } from "../../libs/errorLib";
import { API } from "aws-amplify";
import validator from 'validator'

export default function RequestInvite() {
    const [fields, handleFieldChange] = useFormFields({
        emailaddress: "",
        name: "",
        organisation: ""
    });
 
    // const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [messageVariant, setMessageVariant] = useState('info');
    const [showMessage, setShowMessage] = useState(false);
    const [messageText, setMessageText] = useState("");

    function validateForm() {
        return (
            fields.emailaddress.length > 0 &&
            fields.name.length > 0 &&
            validator.isEmail(fields.emailaddress)
            
            
        );
    }

   

    async function requestInvite(values){

       
            let requestBody = { body:values};
        
            return await API.post('openapi', '/requestinvite', requestBody);
       
       
    }

    function handleErroClose(){
        setShowMessage(false);
    }

    
    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {

            let values = {
                emailaddress: fields.emailaddress,
                name: fields.name,
                mobilenumber: fields.mobilenumber,
                organisation: fields.organisation
            }

            await requestInvite(values);
        
            setIsLoading(false);

            setShowMessage(true);
            setMessageText('Thank you, your interest has been registered. We will be in touch soon.');
            setMessageVariant('info');

            // history.push('/');
            
            
        }
        catch (e) {
            console.log(e.response);
            // onError(e);
            setShowMessage(true);
            setMessageText(e.response.data.message);
            setMessageVariant('danger');
            setIsLoading(false);
        }
    }

    function renderForm() {
        return (
            <form onSubmit={handleSubmit}>
                <FormGroup controlId="name" bssize="large">
                    <FormControl
                        autoFocus
                        type="text"
                        placeholder="Name"
                        aria-label="name"
                        value={fields.username}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup controlId="emailaddress" bssize="large">
                    <FormControl
                        type="email"
                        placeholder="Email"
                        aria-label="Email"
                        value={fields.emailaddress}
                        // value={"emmanuel+6@omegaalexis.com"}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup controlId="organisation" bssize="large">
                    <FormControl
                        type="text"
                        placeholder="Organisation"
                        aria-label="Organisation"
                        value={fields.organisation}
                        // value={"emmanuel+6@omegaalexis.com"}
                        onChange={handleFieldChange}
                    />
                </FormGroup>

                <LoaderButton
                    className="pull-right"
                    block
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                    text="Request Invite"
                    loadingText="Requesting..."
                />

               
            </form>

            

        );
    }

    return (
        <div className="content-wrap">
             <Link to="/" >
            <img alt="logo" src="/images/logo.png" className="App-logo" />
            </Link>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs md="6" lg="4">
                        {renderForm()}
                    
                    <Alert style={{marginTop:'8px'}} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                        {messageText} 
                        
                        </Alert>
                    {  showMessage && 
                        <Link style={{marginTop:'8px'}}  to="/" className="btn btn-link btn-block">Home</Link>
                    }
 
                    </Col>
                    
                </Row>
                
            </Container>
        </div>
    );
}