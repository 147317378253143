import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { FormGroup, FormControl, Row, Container, Col, Alert, Button, Modal } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { useFormFields, useLoaded } from "../../libs/hooksLib";
import { addGame } from "../../libs/apiLib";
import CONSTANTS from '../../libs/constants';
import { BsPencilSquare } from 'react-icons/bs';
import { FaCheckCircle, FaPlus } from 'react-icons/fa';
import { trackEvent } from "../../libs/events";
import store from 'store';
import { getRandomColouredLogo } from "../../libs/util";
import YTImageSquare from "../../components/YTImageSquare";

import config from "../../config";

const NEW_GAME_LABEL = '';
const clipboardCopy = 'Copy link to clipboard';

export default function AddGame() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPlaylist, setSelectePlaylist] = useState(null);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [modalMessageBig, setModalMessageBig] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [playlists, setPlaylists] = useState(store.get(CONSTANTS.STORE.PLAYLISTS) ? store.get(CONSTANTS.STORE.PLAYLISTS) : []);

  const [fields, handleFieldChange] = useFormFields({
    playlist: '',
    name: store.get(CONSTANTS.STORE.NEW_GAME_NAME) ? store.get(CONSTANTS.STORE.NEW_GAME_NAME) : NEW_GAME_LABEL
  });

  const [messageVariant, setMessageVariant] = useState('info');
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [copyLinkCopy, setCopyLinkCopy] = useState(clipboardCopy);
  const history = useHistory();


  const loaded = useLoaded('playlist', setIsLoading);



  useEffect(() => {

    if (loaded.error) {

      if (loaded.error.status !== 404) {

        setShowMessage(true);
        setMessageText(loaded.error.message);
        setMessageVariant('danger');
      }


    }
    else if(loaded.data) {

      setPlaylists(loaded.data);
      store.set(CONSTANTS.STORE.PLAYLISTS, loaded.data);
      

    }

  }, [loaded]);



  const [gameLink, setGameLink] = useState('');

  const validateForm = () => {
    return (
      selectedPlaylist && fields.name && fields.name !== NEW_GAME_LABEL

    );
  }

  const handleErroClose = () => {
    setShowMessage(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

      if(store.get(CONSTANTS.STORE.NEW_GAME_NAME) ){
        store.remove(CONSTANTS.STORE.NEW_GAME_NAME) 
      }

    let image;

    if (selectedPlaylist && selectedPlaylist.image) {
      image = selectedPlaylist.image;
    }

    // setIsLoading(true);
    setIsSubmitting(true);

    try {

      const values = {
        playlist: selectedPlaylist.playlist,
        playlistname: selectedPlaylist.name,
        name: fields.name,
        image

      }

      if(selectedPlaylist.type){

        values.playlisttype = selectedPlaylist.type;
      }

      

      const gameData = await addGame(values);

      const gameLink = encodeURI(`${config.bingo.URL}/invite?user=${gameData.user}&game=${gameData.game}&gamename=${gameData.name}`);
      setGameLink(gameLink);
      // setIsLoading(false);
      setIsSubmitting(false);

      store.set(CONSTANTS.STORE.WIZARD_STAGE, 'confirmation');
      store.set(CONSTANTS.STORE.CURRENT_GAME, gameData);
      history.go(0);

      // setShowShareModal(true);
      trackEvent('gameCreated', {selectedPlaylist});



    }
    catch (e) {

      if (e && e.response && e.response.status === 402) {
        trackEvent('upgradeRequired', {selectedPlaylist});
        setModalMessageBig(CONSTANTS.MESSAGE.UPGRADE);
        setModalMessage("You have reached the maximum number of games on your current plan. Upgrade now!");
        // setIsLoading(false);
        setIsSubmitting(false);
        setShowModalMessage(true);
      }
      else {
        setShowMessage(true);
        // const message = 
        // setMessageText(e.response.data.message);
        setMessageText(e.response.data ? e.response.data.message : 'Something went wrong' );
        setMessageVariant('danger');
        // setIsLoading(false);
        setIsSubmitting(false);

      }


    }
  }



  const handleAddPlaylist = (playlist) => {

    
    setSelectePlaylist(playlist);

  

  }


  const handleNewPlaylistClick = (event) => {
    event.preventDefault();
    if(fields.name){
        store.set(CONSTANTS.STORE.NEW_GAME_NAME, fields.name);
    }

    history.push(CONSTANTS.PAGE.SPOTIFYPLAYLISTS);
  }

  const handleModalClose = () => {

    setShowModalMessage(false);

  }

  const handleRoundCreated = () => {

    console.log('handleRoundCreated')
;
    store.set(CONSTANTS.STORE.WIZARD_STAGE, 'confirmation');
    history.go(0);

  }

  const handleShareModalClose = () => {

    setShowShareModal(false);
    setSelectePlaylist(null);
    fields.name = '';
    setCopyLinkCopy(clipboardCopy);
   

  }

  const renderPlaylists = () => {
    return (
      <div id='bingo' className='content-wrap'>

        <Container fluid>

          <Row className='justify-content-md-center'>
            <Col xs md='6' className='justify-content-md-center text-center'>

             

              <form onSubmit={handleSubmit}>
               
               <br/>

                <LoaderButton
                  className="pull-right"
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={!validateForm()}
                  text="Next"
                  size="lg"
                  variant="success"
                  loadingText="Adding Round"
                />


                <FormGroup controlId="name">
                  <FormControl
                    autoFocus
                    type="text"
                    placeholder="Round Name"
                    aria-label="Enter Game Name"
                    value={fields.name}
                    onChange={handleFieldChange}
                    className="h1"
                  />
                  <BsPencilSquare />

                </FormGroup>
                {/* </Col> */}
                {/* </Row> */}

                {
                          isLoading && <p>Loading...</p>
                    }


                <h2 className="lines">Choose your Playlist!</h2>

                <Row className='card-wrap'>

             <Col xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                    <div className='d-flex align-items-stretch'>

                      <Button onClick={handleNewPlaylistClick} className="card">
                        {/* <img alt='new game' src='/images/ydbb_elements-03.png' /> */}
                        <FaPlus className="plus"/>
                        <span>ADD PLAYLIST</span>
                      </Button>


                    </div>
                  </Col> 

                  {playlists &&
                    playlists.map(
                      (playlist, i) =>
                        <Col key={i} xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                          <div className='d-flex align-items-stretch'>

                            <Button onClick={() => handleAddPlaylist(playlist)} className="card" disabled={isLoading}>
                            {playlist.type === 'youtube' &&  <YTImageSquare images={playlist.images} /> }
                            {playlist.type !== 'youtube' &&  <img alt={playlist.name} src={playlist.image} /> }
                              {/* <img alt={playlist.name} src={playlist.image} /> */}
                              <span>{playlist.name ? playlist.name : 'No Name'}</span>
                              {selectedPlaylist && playlist.playlist === selectedPlaylist.playlist &&

                                <FaCheckCircle className="tick" />
                              }
                            </Button>


                          </div>
                        </Col>

                    )
                  }




                </Row>

                <LoaderButton
                  className="pull-right"
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={!validateForm()}
                  text="Next"
                  size="lg"
                  variant="success"
                  loadingText="Adding Round"
                />

              </form>

              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible='true' variant={messageVariant} onClose={handleErroClose}>
                <p> {messageText} </p> </Alert>

              <Modal show={showShareModal} onHide={handleShareModalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  <Row className="justify-content-md-center">
                    <Col>

                      <h1> Round Created</h1>


                     

                       {<p>Your round has been created</p>}


                      <div className="text-center">
                      <Button variant="success" onClick={handleRoundCreated} size="lg" className="spotify">
                          Next
                       </Button>
                      </div>

                     

                    </Col>

                  </Row>

                </Modal.Body>
              </Modal>

              <Modal show={showModalMessage} onHide={handleModalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col>
                      <h1>{modalMessageBig}</h1>
                      <p>{modalMessage}</p>
                      {/* <Button variant="primary" onClick={handleModalClose}>Close</Button> */}
                      <Button variant="primary" onClick={() => history.push(CONSTANTS.PAGE.BUY)}>Upgrade</Button>
                    </Col>
                    <Col className="d-flex align-items-end">
                      <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                    </Col>
                  </Row>

                </Modal.Body>
              </Modal>


            </Col>
          </Row>
        </Container>
      </div>
    );
  }




  return (
    <>
      {renderPlaylists()}
    </>
  )
}