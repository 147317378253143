import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {
  // HelpBlock,
  FormGroup,
  // Glyphicon,
  FormControl,
  FormLabel, Alert
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { useFormFields } from "../../libs/hooksLib";
import validator from 'validator'


export default function ResetPassword() {
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  const [messageVariant, setMessageVariant] = useState('info');
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState("");


  function validateCodeForm() {
    // return fields.email.length > 0 && 
    // !validator.isEmail(fields.email)

    return validator.isEmail(fields.email)
  }

  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function handleErroClose(){
    setShowMessage(false);
}

  async function handleSendCodeClick(event) {
    event.preventDefault();

    setShowMessage(false);

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
    } catch (e) {
      
     
      setShowMessage(true);
      if(e.code ){

        if(e.code === 'UserNotFoundException'){
          setMessageText('Emaill address not found');
        }
        else{
          setMessageText(e.message);
        }

      }
      
      setMessageVariant('danger');
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    setShowMessage(false);

    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (e) {
      setShowMessage(true);
      setMessageText(e.message);
      setMessageVariant('danger');
      setIsConfirming(false);
    }
  }

  function renderRequestCodeForm() {
    return (
      <form onSubmit={handleSendCodeClick}>
        <FormGroup bssize="large" controlId="email">
          <FormLabel>Email*</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        *Add your email and we will send you a confirmation code to reset your password.
        <LoaderButton
          block
          type="submit"
          bssize="large"
          isLoading={isSendingCode}
          disabled={!validateCodeForm()}
          text="Send Confirmation"
          loadingText="Sending..."
       />
        
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick}>
        <FormGroup bssize="large" controlId="code">
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            value={fields.code}
            onChange={handleFieldChange}
          />
          {/* <HelpBlock>
            Please check your email ({fields.email}) for the confirmation code.
          </HelpBlock> */}
        </FormGroup>
        <hr />
        <FormGroup bssize="large" controlId="password">
          <FormLabel>New Password</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup bssize="large" controlId="confirmPassword">
          <FormLabel>Confirm Password</FormLabel>
          <FormControl
            type="password"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bssize="large"
          isLoading={isConfirming}
          disabled={!validateResetForm()}
          text="Confirm"
        >
         
        </LoaderButton>
      </form>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className="success">
        {/* <Glyphicon glyph="ok" /> */}
        <p>Your password has been reset.</p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div className="ResetPassword">
       <Link to="/" >
            <img alt="logo" src="/images/logo.png" className="App-logo" />
            </Link>
      {!codeSent
        ? renderRequestCodeForm()
        : !confirmed
        ? renderConfirmationForm()
        : renderSuccessMessage()}
         <Alert style={{marginTop:'8px', maxWidth:'320px',  margin: '0 auto'}} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                        {messageText} 
                        </Alert>
    </div>
  );
}
