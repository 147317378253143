import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Container } from "react-bootstrap";
import { Auth } from "aws-amplify";
import config from '../config';
import store from 'store';
import CONSTANTS from '../libs/constants';
import { FaSpotify } from 'react-icons/fa';
import { useQuery } from "../libs/hooksLib";


async function getUser() {
  let info = await Auth.currentUserInfo();

  if(!info || !info.attributes){
    return null;
  }

  return info.attributes.sub
}


const Spotify = () => {

  const query = useQuery();

  const [returnPage, setReturnPage] = useState(null);

  


  const [user, setUser] = useState("");


  useEffect(() => {

    async function onLoad() {


      let currentUser = await getUser();

      if(currentUser){

      store.set(CONSTANTS.STORE.MUSINGO_USER, currentUser);
      setUser(currentUser);
      }


    }

    onLoad();

    if (query.get(CONSTANTS.QUERY.RETURN_PAGE)) {
      setReturnPage(query.get(CONSTANTS.QUERY.RETURN_PAGE));
    }
    



  }, [query, user]);

  const addReturnPage = () =>{

    if(returnPage){

      return `?${CONSTANTS.QUERY.RETURN_PAGE}=${returnPage}`;

    }
    else{
      return '';
    }

  }


  return (
    <div className="content-wrap">
      {/* <Link to="/" >
            <img alt="logo" src="/images/logo.png" className="App-logo" />
            </Link> */}
      <Container>
        <Row className="justify-content-md-center">
          <Col xs md='6' className='justify-content-md-center text-center'>
            <h1>Connect to Spotify</h1>

            <div>
              <a rel="noopener noreferrer" href={`${config.openApiGateway.URL}/spotify/login/${user}${addReturnPage()}`}>
                <Button variant="success" size="lg" className="spotify">
                  <FaSpotify/>
                  Click Here
                </Button>
              </a>
            </div>


          </Col>
        </Row>
        
      </Container>
    </div>
  );
}

export default Spotify;