import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import {Amplify, Auth} from 'aws-amplify';
import config from './config';
import { initSentry } from './libs/errorLib';
import CONSTANTS from './libs/constants';
import ReactGA from 'react-ga';
import store from 'store';

ReactGA.initialize('UA-165432535-2');
ReactGA.pageview(window.location.pathname + window.location.search);


initSentry();





Amplify.configure({
    Auth: {
      // mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    Storage: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
      identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
      endpoints: [
        {
          name: "api",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
          custom_header: async () => { 
            return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
            }
          
        },
        {
          name: "openapi",
          endpoint: config.openApiGateway.URL,
          region: config.openApiGateway.REGION,
         
          
        },
        {
          name: "spotify",
          endpoint: config.SPOTIFY_API,
          // region: config.SPOTIFY_API.REGION,
          custom_header: async () => { 
            return { Authorization: `Bearer ${store.get(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN)}` }
            }
          
        },
      ]
    }
  });

ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById('root')
  );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
