import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Container, Button, Table } from 'react-bootstrap';
import { useLoaded } from '../../libs/hooksLib';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { FaRedoAlt  } from 'react-icons/fa';






export default function System() {

  const [isLoading, setIsLoading] = useState(false);
  const [systemDataDaily, setSystemDataDaily] = useState(null);
  const [systemDataTotal, setSystemDataTotal] = useState(null);
  const  history = useHistory();
  


  const loaded = useCallback(useLoaded('system', setIsLoading));

  useEffect(() => {

    if (loaded.error) {
      console.log(loaded.error);

    }
    else if (loaded.data) {

      setSystemDataDaily(loaded.data.daily);
      setSystemDataTotal(loaded.data.total[0]);

    }

  }, [loaded.dataLoaded]);


  



  return (
    <div id='bingo' className='content-wrap'>

      <Container fluid>

        <Row className='justify-content-md-center'>
          <Col xs md='6' className='justify-content-md-center text-center'>
            <h1>System</h1>

            {
              isLoading && <p>Loading...</p>
            }

{systemDataDaily    &&      <div>

<Button  onClick={() =>{ history.go(0)}} aria-label="select" size="lg" variant='link'  >
               <FaRedoAlt/>     
               </Button>
               </div>
}


            {systemDataDaily &&
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Games</th>
                              <th>Users</th>
                              <th>Playlists</th>
                              <th>Winners</th>
                              <th>Payments</th>
                              {/* <th>Receipt</th> */}
                            </tr>
                          </thead>
                          <tbody>

                        {  systemDataTotal  &&     <tr>
                                  <td>
                                    <span>Totals</span>
                                  </td>
                                  <td>
                                    <span>{systemDataTotal.gameCount} </span>
                                  </td>
                                  <td>
                                    <span>{systemDataTotal.userCount} </span>
                                  </td>
                                  <td>
                                    <span>{systemDataTotal.playlistCount} </span>
                                  </td>
                                  <td>
                                    <span>{systemDataTotal.winnerCount} </span>
                                  </td>
                                  <td>
                                    <span>{systemDataTotal.paymentCount} </span>
                                  </td>
                                  {/* <td>
                                    <span><a href={billing.meta.receipt_url} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">Receipt</a> </span>
                                  </td> */}
                                </tr>
                        }


                            {systemDataDaily.map(
                              (system, i) =>
                                <tr key={i}>
                                  <td>
                                    <span>{moment.unix(system.day / 1000).format('DD MMM YY')}</span>
                                  </td>
                                  <td>
                                    <span>{system.gameCount} </span>
                                  </td>
                                  <td>
                                    <span>{system.userCount} </span>
                                  </td>
                                  <td>
                                    <span>{system.playlistCount} </span>
                                  </td>
                                  <td>
                                    <span>{system.winnerCount} </span>
                                  </td>
                                  <td>
                                    <span>{system.paymentCount} </span>
                                  </td>
                                  {/* <td>
                                    <span><a href={billing.meta.receipt_url} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">Receipt</a> </span>
                                  </td> */}
                                </tr>
                            )}
                          </tbody>
                        </Table>


                                }

                      


          

          </Col>
        </Row>
      </Container>

    </div>
  );
}

