import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Container, Button, Card, Table } from 'react-bootstrap';
import { useLoaded } from '../libs/hooksLib';
import { getBillingHistory } from "../libs/apiLib";
import { Link } from "react-router-dom";
import moment from 'moment';






export default function Account() {

  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [billingHistory, setBillingHistory] = useState(null);
  const [noBills, setNoBills] = useState(false);


  const loaded = useCallback(useLoaded('me', setIsLoading));

  useEffect(() => {

    if (loaded.error) {
      console.log(loaded.error);

      // history.push('theme');
      // setShowMessage(true);
      // setMessageText(loaded.error);
      // setMessageVariant('danger');
    }
    else if (loaded.data) {

      setUserDetails(loaded.data);

    }

  }, [loaded.dataLoaded]);


  const handleBillingClick = async () => {

    setNoBills(false);

    try {

      const billing = await getBillingHistory();
      setBillingHistory(billing);


    }
    catch (e) {

      if(e.response && e.response.status === 404){
        setNoBills(true);
      }

   
    }

  }



  return (
    <div id='bingo' className='content-wrap'>

      <Container fluid>

        <Row className='justify-content-md-center'>
          <Col xs md='6' className='justify-content-md-center text-center'>
            <h1>Account</h1>

            {
              isLoading && <p>Loading...</p>
            }


            {
              userDetails &&

              <>
                <Row>
                  <Col sm={7} md={7}>
                    <Card>
                      <h6>Username:</h6>
                      <p>{userDetails.username}</p>
                      <h6>Current Tier:</h6>
                      <p>{userDetails.tier}</p>
                      <h6>Total Rounds:</h6>
                      <p>{userDetails.availableGames}</p>
                      <h6>Unused Rounds:</h6>
                      <p>{userDetails.availableGames - (userDetails.gameCount === null || userDetails.gameCount === undefined ? 0: userDetails.gameCount)}</p>
                      <h6>Rounds Created:</h6>
                      <p>{userDetails.gameCount ? userDetails.gameCount : 0}</p>
                      <h6>Custom theme:</h6>
                      <p>{userDetails.theme ? 'Yes' : 'No'}  </p>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Link to="/buy" className="btn btn-link btn-block">Buy Rounds</Link>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      {!billingHistory &&
                        <Button onClick={handleBillingClick} >Showing Billing</Button>
                      }

                      {billingHistory &&
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Tier</th>
                              <th>Price</th>
                              {/* <th>Receipt</th> */}
                            </tr>
                          </thead>
                          <tbody>


                            {billingHistory.map(
                              (billing, i) =>
                                <tr key={i}>
                                  <td>
                                    <span>{moment.unix(billing.date / 1000).format('DD MMM YY HH:mm')}</span>
                                  </td>
                                  <td>
                                    <span>{billing.tier} </span>
                                  </td>
                                  <td>
                                    <span>£{billing.amount / 100} </span>
                                  </td>
                                  {/* <td>
                                    <span><a href={billing.meta.receipt_url} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">Receipt</a> </span>
                                  </td> */}
                                </tr>
                            )}
                          </tbody>
                        </Table>
                      }
                    </Card>

                  {noBills &&
                  
                      <p> There are no bills to view </p>
                  }

                  </Col>
                </Row>

              </>


            }


            <Row className='card-wrap'>

              {/* <Col xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                <div className='d-flex align-items-stretch'>
                </div>
              </Col> */}


              {/* <Alert style={{marginTop:'8px'}} key='message' show={showMessage} dismissible='true' variant={messageVariant} onClose={handleErroClose}>
                    <p> {messageText} </p> </Alert> */}


            </Row>

          </Col>
        </Row>
      </Container>

    </div>
  );
}

