


export function trackEvent(name, data){

    window.heap.track(name, data);
}

export async function identifyEvent(id){

    await window.heap.identify(id);          
}