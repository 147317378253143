import React, { useState } from "react";
// import { useHistory, Link } from "react-router-dom";
import { Link } from "react-router-dom";
import {FormGroup, FormControl, FormCheck, Row, Container, Col, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
// import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from "../../libs/hooksLib";
// import { onError } from "../../libs/errorLib";
import { API } from "aws-amplify";
import validator from 'validator';

export default function Signup() {
    const [fields, handleFieldChange] = useFormFields({
        emailaddress: "",
        username: ""
    });
    // const history = useHistory();
    // const [isError, setIsError] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");
    // const [newUser, setNewUser] = useState(null);
    // const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [messageVariant, setMessageVariant] = useState('info');
    const [showMessage, setShowMessage] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [tandcCheckbox, setTandcCheckbox] = useState(false);

    function validateForm() {
        return (
            fields.emailaddress.length > 0 &&
            fields.username.length > 0 &&
            tandcCheckbox === true &&
            validator.isEmail(fields.emailaddress)
            
            
            
        );
    }

  
   

    async function registerUser(values){

       
            let myInit = { body:values};
        
            return await API.post('openapi', '/register', myInit);
       
       
    }

    function handleErroClose(){
        setShowMessage(false);
    }

    // function handleCheckbox(event) {

    //     const [fields, setValues] = useState(initialState);

    //     return [
    //         fields,
    //         function(event) {
            
    //         setValues({
    //             ...fields,
    //             [event.target.id]: event.target.checked
    //         });
    //         }
    //     ];

    // }

    
    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {

            let values = {
                emailaddress: fields.emailaddress,
                username: fields.username,
                tandc: tandcCheckbox
                // mobilenumber: fields.mobilenumber
            }

            await registerUser(values);
        
            setIsLoading(false);

            setShowMessage(true);
            setMessageText('Thank you, a new password has been sent to your email account');
            setMessageVariant('info');

            // history.push('/');
            
            
        }
        catch (e) {
            console.log(e.response);
            // onError(e);
            setShowMessage(true);
            if(e.response && e.response.data && e.response.data.message){

                if(e.response.data.code){
                    
                    if(e.response.data.code === 'InvalidParameterException'){
                    setMessageText('Your username can only contain letters and numbers and no spaces');
                    }
                    else if (e.response.data.code === "UsernameExistsException"){

                        if(e.response.data.message && e.response.data.message.includes('email')){
                            setMessageText(e.response.data.message);
                        }
                        else{
                            setMessageText('Username is already taken, please choose another one ');
                        }
                        
                    }else{
                        setMessageText(e.response.data.message);
                    }
                }
                else{

                    setMessageText(e.response.data.message);
                }


            }
            else{
                setMessageText("Something went wrong. Please try again");
            }
           
            setMessageVariant('danger');
            setIsLoading(false);
        }
    }

    function renderForm() {
        return (
            <div className="form-wrap">
                <h1>Sign Up</h1>
                <form onSubmit={handleSubmit}>
                <FormGroup controlId="username" bssize="large">
                        <FormControl
                            type="text"
                            placeholder="Username**"
                            aria-label="Username"
                            value={fields.username}
                            // value={"emmanuel-6"}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="emailaddress" bssize="large">
                        <FormControl
                            autoFocus
                            type="email"
                            placeholder="Email"
                            aria-label="Email"
                            value={fields.emailaddress}
                            // value={"emmanuel+6@omegaalexis.com"}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>
                    

                    <FormGroup controlId="tandcCheckbox" className="text-left">
                        <FormCheck type="checkbox" label={
                        <div>
                            <span>I have read the </span>
                            {/* <Link to={'/terms-conditions'} target="_blank" rel="noopener noreferrer">terms and conditions</Link> */}
                            <a target="_blank" rel="noopener noreferrer" alt="terms and conditions" href="https://musingobingo.com/terms-conditions/"> terms and conditions </a>
                        </div>
                    } value={tandcCheckbox} onChange={ () => {setTandcCheckbox(!tandcCheckbox) }}/>
                    </FormGroup>
                    
                    <p><small>**Case sensitive, letters and numbers only.</small></p>
                    
                    
                    <LoaderButton
                        className="pull-right"
                        block
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                        text="Sign up"
                        loadingText="Signing up..."
                    />

                    {/* <strong> By signing up you agree to the terms and conditions of Musingo Bingo <br/> <a target="_blank" rel="noopener noreferrer" alt="terms and conditions" href="https://musingobingo.com/terms-conditions/"> Terms and conditions </a> </strong> */}

                
                </form>
            </div>
            

        );
    }

    return (
        <div className="content-wrap">
             <Link to="/" >
            <img alt="logo" src="/images/logo.png" className="App-logo" />
            </Link>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs md="6" lg="4">
                        {renderForm()}
                    
                    <Alert style={{marginTop:'8px'}} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                        {messageText} 
                        
                        </Alert>
                    {  showMessage && 
                        <Link style={{marginTop:'8px'}}  to="/" className="btn btn-link btn-block">Home</Link>
                    }
 
                    </Col>
                    
                </Row>
                
            </Container>
        </div>
    );
}