import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FaPlay, FaPause, FaStepForward, FaStepBackward, FaEllipsisH} from 'react-icons/fa';
import { Button, Alert, Container, Row, Col, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { API } from "aws-amplify";
import store from 'store';
import CONSTANTS from '../libs/constants';
import { useHistory } from 'react-router-dom';
import { getRandomColouredLogo, youtube_parser, convertArrayToObject, limitText } from "../libs/util";
import { useWindowDimensions, useLoaded} from "../libs/hooksLib";
import { isTablet, isMobile} from "react-device-detect";


import YouTube from 'react-youtube';


  export default function YoutubePlayer({onUpdate, onTrack, setTrackSelector,  ...props}) {

    const {width } = useWindowDimensions();

    let videoHeight = 200;
    let videoWidth = 320;

    if(width < 1200){

      videoHeight = 100;
      videoWidth = 164;

    } 


    const opts = {
      height: videoHeight, // '100'
      width: videoWidth,  // '164'  
      playerVars: {
        autoplay: 0,
        controls: 1,
        playsinline: 1
      }
    };

  const [player, setPlayer] = useState(null);
  const [playlist, setPlaylist] = useState([]);
  // const [playlist, setPlaylist] = useState([{name:'Wings', id: videoIdA}, {name:'Thats what I like', id:videoIdB}, {name:'Girls Like', id:videoIdC}, {name: 'Never had a dream', id: videoIdE},  {name: 'Too lost in you', id: videoIdF}, {name: 'Push the button', id: videoIdD}]);
  // const [trackLookup, setTrackLookup] = useState(convertArrayToObject(playlist, 'id'));
  const [trackLookup, setTrackLookup] = useState([]); 
  const [playlistIndex, setPlaylistIndex] = useState(store.get(CONSTANTS.STORE.PLAYLIST_INDEX) ? store.get(CONSTANTS.STORE.PLAYLIST_INDEX) : 0);


  const [currentTrack, setCurrentTrack] = useState(null);
  const [hasData, setHasData] = useState(false);
    
  const [isConnected, setIsConnected] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  
  const [isPaused, setIsPaused] = useState(true);
  const [nextTracks, setNextTracks] = useState(playlist.slice(playlistIndex + 1, playlistIndex + 3));
  const [isShuffled, setIsShuffled] = useState(false);
  const [previousTracks, setPreviousTracks] = useState(playlistIndex === 1 ? [playlist[0]] :   playlistIndex > 0 ? playlist.slice(playlistIndex - 2 , playlistIndex ) : [] );
  const [playedTracks, setPlayedTracks] = useState([]);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalMessageBig, setModalMessageBig] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showNext, setShowNext] = useState(true);

  const [showModaSoundConnectlMessage, setShowModaSoundConnectlMessage] = useState(false);
  const [showSoundConnect, setShowSoundConnect] = useState(store.get(CONSTANTS.STORE.SOUND_CONNECT) ? store.get(CONSTANTS.STORE.SOUND_CONNECT) : 0 );

  const history = useHistory();

  const games = store.get(CONSTANTS.STORE.GAMES) ? store.get(CONSTANTS.STORE.GAMES) : [];

  const justSwitchedGame = useRef(false);

  const game = useRef(store.get(CONSTANTS.STORE.CURRENT_GAME));


  let apiEndpoint = 'playlistOne';

  if(game.current.playlistowner){

    apiEndpoint = 'musingoplaylistOne';

  }
  
  let  loaded = useCallback(useLoaded(apiEndpoint, setIsLoading, game.current.playlist));

  useEffect(() => {

   

    if (loaded.error) {
      console.log(loaded.error);
    }
    else if (loaded.data) {


      setPlaylist(loaded.data.tracks);
      const trackLookupTemp  = convertArrayToObject(loaded.data.tracks, 'id')
      setTrackLookup(trackLookupTemp);
      
      if(playlistIndex >= loaded.data.tracks.length){
        setPlaylistIndex(0);
        setCurrentTrack(trackLookupTemp[loaded.data.tracks[0].id]);
        updateTrackDisplay(0, loaded.data.tracks);
      }
      else{
        setCurrentTrack(trackLookupTemp[loaded.data.tracks[playlistIndex].id]);
        updateTrackDisplay(playlistIndex, loaded.data.tracks);
      }

     
      setHasData(true);

    }

  }, [loaded.dataLoaded, game.current.playlist]);

  useEffect(() => {

    if(isConnected && showSoundConnect < 2 && !isMobile){

      setShowModaSoundConnectlMessage(true);
      setShowSoundConnect(showSoundConnect + 1);
      store.set(CONSTANTS.STORE.SOUND_CONNECT, showSoundConnect + 1);
      
    }

  }, [isConnected]);

  useEffect(() => {

    if (props.pausePlayer) {

      pausePlayback();
       
    } 


  }, [props.pausePlayer]);

  const user = useRef(store.get(CONSTANTS.STORE.MUSINGO_USER));

  const handleModalClose = ()=> {

    setShowModalMessage(false);
    setModalMessageBig('');
    setModalMessage('');
   

  }

  const onReady = (event) => {
   setPlayer(event.target);
   setIsConnected(true);
  };

  const onStateChange = (event) =>{
   
    if(event.data && event.data === 1){
      setIsPaused(false);

      const videoid = youtube_parser(event.target.getVideoUrl());



      if(videoid){
        const trackObject = trackLookup[videoid];
        setCurrentTrack(trackObject);
        addTrack(trackObject);
        store.set(CONSTANTS.STORE.PLAYLIST_INDEX, playlistIndex);
      }
     
    }
    else if(event.data && event.data === 2) {
   
      setIsPaused(true);
    }
    else if(event.data === 0){
      setIsPaused(true);
    }
    else if(event.data && event.data === 5) {
      const videoid = youtube_parser(event.target.getVideoUrl());
      if(videoid){
        const trackObject = trackLookup[videoid];
        setCurrentTrack(trackObject);

      }
      else{
        setCurrentTrack({});
      }
    }
     
  };


  const handleShowNext = () =>{

    setShowNext(!showNext);

  }


  const addTrack = async (track) => {

    const trackid = track.id;

    onTrack(track);

    if (playedTracks.includes(trackid)) {
      return null;
    }

    const tracks = [trackid]

    const requestBody = {
      body: {
        game: game.current.game,
        user: user.current,
        tracks

      }
    };

    try{

          await API.post('api', '/users/addanswers', requestBody);

          if (trackid) {
            playedTracks.push(trackid);
            setPlayedTracks(playedTracks);
          }
    }
    catch(e){
      console.log(e);
      API.post('api', '/users/addanswers', requestBody);
    }

  };

  const pausePlayback = () => {

    if(player){

    player.pauseVideo();
  
  }
  };

  const playPlayback = () => {

    if(player){

   player.playVideo();

   
  
  }
  };



  const handleToggle = () => {

    justSwitchedGame.current = false;

    if(isPaused){
      player.playVideo();
      setIsPaused(false);

    }
    else{
      player.pauseVideo();
      setIsPaused(true);
    }
    
  };

  const updateTrackDisplay = (index, list) =>{


    if(list){

      setNextTracks(list.slice(index + 1, index + 3));
        if(index === 1){   
            setPreviousTracks([list[0]]);
      
        }
        else{
         
          setPreviousTracks(list.slice(index -2 , index));
        }

    }
    else{

      setNextTracks(playlist.slice(index + 1, index + 3));
        if(index === 1){
          setPreviousTracks([playlist[0]]);
        }
        else{
         
          setPreviousTracks(playlist.slice(index -2 , index));
        }

    }
    
    

  }

  const handlePrevious = () => {

    setIsPaused(true);

    if(playlistIndex > 0 ){

  const previousIndex = playlistIndex - 1;
  setPlaylistIndex(previousIndex);
 
  if(!isPaused){
    setTimeout(playPlayback, 100);
  }
  updateTrackDisplay(previousIndex);
  
  }

  };

  const handleNext = () => {

    // setIsPaused(true);
    if(playlist && playlistIndex < playlist.length -1){

    
   
        const nextIndex = playlistIndex + 1;
        setPlaylistIndex(nextIndex);

        if(!isPaused){
          setTimeout(playPlayback, 100);
        }
        
        updateTrackDisplay(nextIndex);
        
       
        
    }

  };



  const handleShuffle = () => {

    API.put('spotify', `/me/player/shuffle?state=${!isShuffled}`)
      .then((response) => {
        // console.log('IsShuffled', isShuffled);
      })
      .catch((e) => {
        console.log('Error', e);
        setIsError(true);
        setErrorMessage(e.message);
      })
  }



  const renderNextTracks = () => {

    return nextTracks.map(
      (track, i) =>
        <div className="track next" key={i}>
          <img alt={track.name} src={track.id ? track.images[0] : '/images/vads.png'}  onError={(e)=>{e.target.onerror = null; e.target.src='/images/vads.png'}} />
      
          <span>{limitText(track.name)}</span>
        </div>
    )
  }


  const renderPreviousTracks = () => {

    

    return previousTracks.map(
      (track, i) =>
        <div className="track prev" key={i}>
          <img alt={track.name} src={track.id ? track.images[0] : '/images/vads.png'} onError={(e)=>{e.target.onerror = null; e.target.src='/images/vads.png'}} />
          <span>{limitText(track.name)}</span>
        </div>
    )
  }

  const handleReload = () => {
    history.push(CONSTANTS.PAGE.CONTROL);
  }

  const handleGameSwitch = (newGame) =>{

  

    game.current = JSON.parse(newGame);
    onUpdate(game.current);
    store.set(CONSTANTS.STORE.CURRENT_GAME,game.current );
    store.set(CONSTANTS.STORE.PLAYLIST_INDEX, 0);
    justSwitchedGame.current = true;
    history.go(0);
    // loadPlaylist(game.current.playlist);
    // swtichPlaylist(game);

  }

  const handleSoundConnecClose = () =>{

   
    pausePlayback();
    setShowModaSoundConnectlMessage(false);

   

  }


  return (
    <Container fluid id="yt-player">



      {!isConnected &&
        <>
          <p><strong>Please connect to the Musingo Player in Spotify like the example below.  </strong> <br/><br/><strong>Once connected this page will automatically load the Musingo Player.</strong> <br/>  <br/>If you don't see the Musingo Player in Spotify please try reloading this page.</p>
          <img alt="spotify connect" id="spotify-connect" src="/images/spotifyconnect.jpeg" />
        </>
      }

      {isConnected && hasData &&
        <div>
          
          {/* <p>Connected</p> */}
          {game.current.playlistname &&
                 <p>Playlist: {game.current.playlistname}</p>
            }

          <Row>
            <Col xs={3} md={4} className="prev-tracks">

              { previousTracks && renderPreviousTracks()}
            </Col>

            <Col xs={6} md={4}>
              <div className="track current">
                {/* <img alt={currentTrack.name} src={currentTrack.album ? currentTrack.album.images[0].url : '/images/vads.png'} /> */}
                <YouTube videoId={playlist[playlistIndex].id} onReady={onReady} opts={opts} onStateChange={onStateChange} />
                <span>{currentTrack.name}</span>
              </div>
            </Col>

            <Col xs={3} md={4} className="next-tracks">
              {showNext && renderNextTracks()}
            </Col>
          </Row>


          <Button onClick={handlePrevious}><FaStepBackward /></Button>{' '}
          {/* <Button onClick={() => handleSeek('forward')}><FaBackward/></Button> */}
          <Button onClick={handleToggle}>{isPaused ? <FaPlay /> : <FaPause />}</Button>{' '}
          {/* <Button onClick={() => handleSeek('backward')}><FaForward/></Button> */}
          <Button onClick={handleNext}><FaStepForward /></Button>{' '}
          { (!isMobile || isTablet) && <Button onClick={handleShowNext}><FaEllipsisH /></Button>}  {' '} 
          {/* <Button active={isShuffled} onClick={handleShuffle}><FaRandom /></Button> */}

           <DropdownButton id="dropdown-basic-button" title="Change Round" onSelect={handleGameSwitch} size="sm" variant="link">

           <Dropdown.Item key={'current'} eventKey={JSON.stringify(game.current)} >{'Reload current game'}</Dropdown.Item>
       {games.length > 0 &&

                
                games.filter(gameObj => gameObj.game !== game.current.game ).map(
              (newgame, i) =>
                <Dropdown.Item key={i} eventKey={JSON.stringify(newgame)} >{newgame.name}</Dropdown.Item>

        )
      }
       
      </DropdownButton>


        </div>
      }

      <Row className="justify-content-md-center">
        <Col lg={6}>
          {
            isError &&
            <Alert variant="danger">
              {/* {errorMessage} */}
              {errorMessage}{' '}
              <a href='gamecontrol' >
                <Button onClick={handleReload}>Reload</Button>{' '}

              </a>
            </Alert>

          }
        </Col>
      </Row>

      <Modal show={showModalMessage} onHide={handleModalClose}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h1>{modalMessageBig}</h1>
                    <p>{modalMessage}</p>
                    <Button variant="primary" onClick={setTrackSelector}>Track Selector</Button>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>

            <Modal show={showModaSoundConnectlMessage} onHide={handleSoundConnecClose}>
              <Modal.Header closeButton>
                Musingobingo.com
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h1>Have you connnected your computer sound?</h1>
                    <p>You need to connect your computer sound so your players can hear the music</p>
                    <a rel="noopener noreferrer"  target="_blank" href={`https://youtu.be/hEHJgPbGnrY`}>
                <Button variant="success" size="lg" onClick={handleSoundConnecClose} className="spotify">          
                  How to connect sound
                </Button>
              </a>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>

    </Container>
  );
}

