import React, {useState, useEffect } from "react";
import { Row, Col, Container, Form, Alert, Button, Modal } from "react-bootstrap";
import { useFormFields, useQuery } from "../../libs/hooksLib";
import LoaderButton from "../../components/LoaderButton";
import { getYoutubePlaylist, addplaylistManual } from "../../libs/apiLib";
import { youtube_parser_playlist, limitText, getRandomColouredLogo } from "../../libs/util";
import CONSTANTS from '../../libs/constants';
import { useHistory } from 'react-router-dom';
import store from 'store';
import YTImageSquare from "../../components/YTImageSquare";


// function geoFindMe() {
//   if (!navigator.geolocation){
//    console.log("Geolocation is not supported by your browser");
//     return;
//   }
//   function success(position) {
//     var latitude  = position.coords.latitude;
//     var longitude = position.coords.longitude;
//     console.log('latitude',latitude);
//     console.log('longitude', longitude);
//     // reverseGeocodingWithGoogle(longitude, latitude)
//   }
//   function error() {
//     console.log("Unable to retrieve your location");
//   }
//   navigator.geolocation.getCurrentPosition(success, error);
// }

const MIN_TRACKS_LENGTH = 15;
const STAGE_ADD_GAME = 'add_game';

export default function AddYoutubePlaylist() {

  const query = useQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [messageVariant, setMessageVariant] = useState('info');
  const [playlistName, setPlaylistName] = useState('');
  const [tracks, setTracks] = useState([]);
  const [playlistDetails, setPlaylistDetails] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [editTrack, setEditTrack] = useState({});
  const [editTrackIndex, setEditTrackIndex] = useState(-1);
  const [addAsOwner, setAddAsOwner] =  useState(false);

  const [showModaNamelEdit, setShowModaNamelEdit] = useState(false);

  const history = useHistory();


  useEffect(() => {

    if (query.get(CONSTANTS.QUERY.OWNER)) {
      
          setAddAsOwner(true);
    }
  
  }, []);
  


  


  const [fields, handleFieldChange] = useFormFields({
    playlisturl: "",
    editableName: "",
    editablePlaylistName: ""
   

  });

  const handleErroClose = () => {
    setShowMessage(false);
  }

  const validateForm = () => {
    return (

        
      fields.playlisturl && fields.playlisturl.length > 5
      

    );
  }

  async function handleEditPlaylistName(event){

    event.preventDefault();

    setPlaylistName( fields.editablePlaylistName);

    fields.editablePlaylistName = '';

    setShowModaNamelEdit(false);

  }

  async function handleAddPlaylist(){

    setIsLoadingAdd(true);

    console.log(playlistDetails);

    console.log(playlistDetails.tracks.length, MIN_TRACKS_LENGTH);

    if(playlistDetails){

      playlistDetails.tracks = tracks;

       if(addAsOwner){

        playlistDetails.owner = 'musingobingo';
      }

      if(playlistDetails.tracks.length < MIN_TRACKS_LENGTH){

        setIsLoading(false);
        setMessageVariant('danger');
        setMessageText(`You need at least ${MIN_TRACKS_LENGTH} tracks`);
        setShowMessage(true);

      }
      else{

          playlistDetails.name = playlistName;

          await addplaylistManual(playlistDetails);

          store.set(CONSTANTS.STORE.WIZARD_STAGE, STAGE_ADD_GAME);

          // history.push('/wizard');

          history.go(0);
      }

    }

    setIsLoadingAdd(false);


  }

  async function handleDeleteTrack(event){

    event.preventDefault();

     let updatedTracks = [...tracks];

     updatedTracks.splice(editTrackIndex, 1);

     setTracks(updatedTracks);
 
     setShowModalEdit(false);

  }

  async function handleEditName(event){

    event.preventDefault();

    let updatedTracks = [...tracks];

    updatedTracks[editTrackIndex].name = fields.editableName;

    setTracks(updatedTracks);

    fields.editableName = '';

    setShowModalEdit(false);

  }


  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    const playlistid = youtube_parser_playlist(fields.playlisturl);


    try{

     const playlistDetailsResp = await getYoutubePlaylist(playlistid);

     setPlaylistName(playlistDetailsResp.name);
     setTracks(playlistDetailsResp.tracks);
     setPlaylistDetails(playlistDetailsResp);
     fields.playlisturl = '';
  
    setIsLoading(false);

    
    }
    catch(e){
      setIsLoading(false);
      setMessageVariant('danger');
      setMessageText('Playlist not found');
      setShowMessage(true);

    }

    



  }

  function handleModalNameEditClose() {

    setShowModaNamelEdit(false);
   
  }

async function handleTrackEdit(track, index){

  setEditTrack(track);
  setEditTrackIndex(index);

  setShowModalEdit(true);



}



function handleModalEditClose() {

  setShowModalEdit(false);
  setEditTrack({});
  fields.editableName = '';
}

  



  return (
    <div id="bingo" className="content-wrap">

      <Container fluid>

        <Row className="justify-content-center">
          <Col xs md="6" className="justify-content-center text-center">
            <h1>Setup Wizard (3)</h1>

            <p>Add aYoutube Playlist URL</p>

            {
              isLoading && <p>Loading...</p>
            }


                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="playlisturl">
                        <Form.Control
                            autoFocus
                            type="text"
                            placeholder="Playlist URL"
                            aria-label="Playlist URL"
                            value={fields.playlisturl}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                 

              <LoaderButton
                className="pull-right"
                // block
                type="submit"
                isLoading={isLoading}
                text="Find"
                loadingText="Searching..."
                disabled={!validateForm()}
              />
              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                {messageText}
              </Alert>
            </Form>

            {/* <h2>{playlistName}</h2> */}
           {/* { playlistName && <h2><Button onClick={() => handleTrackEdit()} >{playlistName}{playlistName}</Button></h2> } */}

           {playlistName &&  <p><br/> Click to edit</p> }

          {playlistName &&  <Button variant="link" onClick={()=> {setShowModaNamelEdit(true)}}> <h2>{playlistName}</h2> </Button> }



         
      
        <Row className="card-wrap">


           {tracks.length > 0 &&

           

                 tracks.map(
                   (track, i) =>
                     <Col key={i} xs="6" md="4" lg="3" className="d-flex align-items-stretch">
                       <div className="d-flex align-items-stretch">
                       {/* <Button  > */}
                         <Button onClick={() => handleTrackEdit(track, i)} >

                           <img alt={track.name} src={track.id ? track.images[0] : `/images/${getRandomColouredLogo()}`}   />
                           <span>{ track.name ? limitText(track.name) : 'No Name' }</span> {' '}
                                                      
                         </Button>
                         
                       </div>
                     </Col>

               )
               }

      {tracks.length > 0 &&

              <LoaderButton
                className="pull-right"
                block
                type="submit"
                isLoading={isLoadingAdd}
                text="Next"
                loadingText="Adding..."
                disabled={false}
                onClick={handleAddPlaylist}
              />  
      }   

            

   </Row>

   </Col>
        </Row>

        <Modal show={showModaNamelEdit} onHide={handleModalNameEditClose}>
              <Modal.Header closeButton>
                Musingobingo.com
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xs={12}>
                  {/* <img alt={editTrack.name} src={editTrack.id ? editTrack.images[0] : `/images/${getRandomColouredLogo()}`}   /> */}
                  {playlistDetails && <div><YTImageSquare images={playlistDetails.images} /></div> }
                    <br/>
                    <br/>
                    {/* <p>{limitText(editTrack.name, false, false)}</p> */}
                    <p>{playlistName}</p>
                    <Form onSubmit={handleEditPlaylistName}>
                    <Form.Group controlId="editablePlaylistName">
                        <Form.Control
                            autoFocus
                            type="text"
                            placeholder="Update Playlist Name"
                            aria-label="Playlist Name"
                            value={fields.editablePlaylistName}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                 

                  <Row>
                    <Col xs={6} className="d-flex align-items-end">
                    <LoaderButton
                      className="pull-right"
                      block
                      type="submit"
                      isLoading={isLoading}
                      text="Update"
                      loadingText="Updating..."
                      
                    />
                    </Col>

                    {/* <Col xs={6} className="d-flex align-items-end">
                      <Button  aria-label="select" block onClick={handleDeleteTrack} >Delete</Button>
                    </Col> */}
                  </Row>
              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                {messageText}
              </Alert>
            </Form>
                    {/* <Button variant="primary" onClick={handleModalEditClose}>Close</Button> */}
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>

        <Modal show={showModalEdit} onHide={handleModalEditClose}>
              <Modal.Header closeButton>
                Musingobingo.com
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xs={12}>
                  <img alt={editTrack.name} src={editTrack.id ? editTrack.images[0] : `/images/${getRandomColouredLogo()}`}   />
                    <br/>
                    <br/>
                    <p>{limitText(editTrack.name, false, false)}</p>
                    <Form onSubmit={handleEditName}>
                    <Form.Group controlId="editableName">
                        <Form.Control
                            autoFocus
                            type="text"
                            placeholder="Update name"
                            aria-label="Playlist Name"
                            value={fields.editableName}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                 

                  <Row>
                    <Col xs={6} className="d-flex align-items-end">
                    <LoaderButton
                      className="pull-right"
                      block
                      type="submit"
                      isLoading={isLoading}
                      text="Update"
                      loadingText="Updating..."
                      
                    />
                    </Col>

                    <Col xs={6} className="d-flex align-items-end">
                      <Button  aria-label="select" block onClick={handleDeleteTrack} >Delete</Button>
                    </Col>
                  </Row>
              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                {messageText}
              </Alert>
            </Form>
                    {/* <Button variant="primary" onClick={handleModalEditClose}>Close</Button> */}
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>

      </Container>
    </div>
  );
}