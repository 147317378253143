import React, { useState} from "react";
import { Auth } from "aws-amplify";
// import { useHistory } from "react-router-dom";
import { InputGroup, FormControl, Row, Col, Container, Form, Alert, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from "../../libs/hooksLib";
import { identifyEvent } from "../../libs/events";
import store from 'store';
import CONSTANTS from '../../libs/constants';
import { getRandomColouredLogo } from "../../libs/util";
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from "react-share";

// const INFO_MODAL_MAX_COUNT = 3;





export default function Login() {
    // const history = useHistory();
    const { userHasAuthenticated} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [user, setUser] = useState(null);
    // const [infoModalCount, setInfoModalCount] = useState()
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        newPassword: "",
        newPasswordConfirmed: ""

    });
    const [messageVariant, setMessageVariant] = useState('info');
    const [showMessage, setShowMessage] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [showInitialModal, setshowInitialModal] = useState(false);

    // const infoModalCount = store.get(CONSTANTS.STORE.INFO_MODAL_COUNT) ? store.get(CONSTANTS.STORE.INFO_MODAL_COUNT) : 0;



    // useEffect(() => {

    //     if(infoModalCount < INFO_MODAL_MAX_COUNT){
    //         // console.log('Showing modal');
    //         setshowInitialModal(true);
    //         store.set(CONSTANTS.STORE.INFO_MODAL_COUNT, infoModalCount + 1 );
    //     }
        

    
    //   }, []);
    

    

    function validateForm() {
        return fields.email.length > 0 && fields.password.length > 0;
    }

    function validateNewPasswordForm() {
        return (
            fields.newPassword.length > 0 &&
            fields.newPassword === fields.newPasswordConfirmed
        );
    }

    function handleErroClose(){
        setShowMessage(false);
    }

    const handleInitialModalClose = () => {

        setshowInitialModal(false);
    
      }

    async function handleNewPasswordSubmit(event) {

        event.preventDefault();

        const { requiredAttributes } = user.challengeParam;

        setIsLoading(true);

       try{
        await Auth.completeNewPassword(user, fields.newPassword,  requiredAttributes);  

        setIsLoading(false);
        userHasAuthenticated(true);
       }
       catch(e){
        // console.log("Password update error");
        setShowMessage(true);
        setMessageText(e.message);
        setMessageVariant('danger');
           setIsLoading(false);

       }

          
    
        


    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            let returnedUser = await Auth.signIn(fields.email, fields.password);

            if (returnedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {

                setUser(returnedUser);
                setUpdatePassword(true);
                setIsLoading(false);
                // const { requiredAttributes } = user.challengeParam;

            }
            else{
                // console.log('returnedUser.attributes', returnedUser.attributes);
                identifyEvent(returnedUser.attributes.sub);
                store.set(CONSTANTS.STORE.MUSINGO_USER, returnedUser.attributes.sub) ;
                userHasAuthenticated(true);
                
            }

            
        } catch (e) {
            setShowMessage(true);
        setMessageText(e.message);
        setMessageVariant('danger');
            setIsLoading(false);
        }
    }

    function renderNewPasswordForm(){

        return (
            <div className="form-wrap">
                <h3>Update password</h3>
                <p> Minimum of 8 characters including a number</p>
            <form onSubmit={handleNewPasswordSubmit}>
                <Form.Group controlId="newPassword" bssize="large">
                    <FormControl
                        autoFocus
                        type="password"
                        placeholder="Password"
                        aria-label="Password"
                        value={fields.newPassword}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="newPasswordConfirmed" bssize="large">
                    <FormControl
                        type="password"
                        placeholder="Confirm Password"
                        aria-label="Confirm Password"
                        onChange={handleFieldChange}
                        value={fields.newPasswordConfirmed}
                    />
                </Form.Group>
                
                <LoaderButton
                    className="pull-right"
                    block
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateNewPasswordForm()}
                    text="Update password"
                    loadingText="Updating password..."
                />
                <Alert style={{marginTop:'8px'}} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                        {messageText} 
                        </Alert>
            </form>
            </div >
        );

    }

    function renderLoginForm() {
        return (
            <div className="form-wrap">
                <h1>Sign In</h1>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Control
                            autoFocus
                            type="text"
                            placeholder="Username or Email Address"
                            aria-label="Username or Email Address"
                            value={fields.email}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <FormControl
                                id="password"
                                placeholder="Password"
                                aria-label="Password"
                                value={fields.password}
                                onChange={handleFieldChange}
                                type="password"
                            />
                            {/* <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">
                                    <Link to="/reset-password">Forgot password?</Link>
                                </InputGroup.Text>
                            </InputGroup.Append> */}
                        </InputGroup>
                    </Form.Group>

                    <LoaderButton
                        className="pull-right"
                        block
                        type="submit"
                        isLoading={isLoading}
                        text="Sign in"
                        loadingText="Signing in..."
                        disabled={!validateForm()}
                    />
                     <Alert style={{marginTop:'8px'}} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                        {messageText} 
                        </Alert>
                </Form>
                <Link to="/reset-password">Forgotten Password</Link>

                <p style={{marginTop:'8px'}}>Don't have an account?</p>
                <Link to="/signup" className="btn btn-link btn-block">Sign up!</Link>
               
            </div >
        );
    }

    return (
        <div className="content-wrap">
             <Link to="/" >
            <img alt="logo" src="/images/logo.png" className="App-logo" />
            </Link>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs md="6" lg="4">
                        {updatePassword ? renderNewPasswordForm() : renderLoginForm() }
                    </Col>
                </Row>
            </Container>
        <Modal show={showInitialModal} onHide={handleInitialModalClose}>      
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col>

              <h1>Welcome to Musingo Bingo</h1>


              <p>We are your ultimate online music bingo game created to bring your virtual parties and events to life. Whether it’s 90s Brit Pop, Prince’s greatest hits, grime or Disney theme tunes, it’s all about playing your sounds, your way and bringing everyone together under a virtual roof.</p>

              <p>Love Music Bingo</p>
            
              <div className="text-center">
                <FacebookShareButton url={CONSTANTS.URLS.SHARE} ><FacebookIcon size={32} round /> </FacebookShareButton>{' '}
                <WhatsappShareButton url={CONSTANTS.URLS.SHARE}><WhatsappIcon size={32} round /> </WhatsappShareButton >{' '}
                <EmailShareButton url={CONSTANTS.URLS.SHARE}><EmailIcon size={32} round /> </EmailShareButton >{' '}
              </div>

            </Col>
           

          </Row>

          <Row>
            <Col></Col>
          <Col className="d-flex align-items-end">
                      <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                      {/* <img alt="modalImage" src={"/images/winner.gif"} /> */}
                    </Col>
          </Row>

        </Modal.Body>
      </Modal>
        </div>
    );
}
