import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Container, FormGroup, FormControl, Button} from 'react-bootstrap';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useLoaded, useFormFields } from '../libs/hooksLib';
import store from 'store';
import config from '../config';
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from "react-share";
import CONSTANTS from '../libs/constants';
import { BsPencilSquare } from 'react-icons/bs';

const IMAGE_URL_DEFAULT = '/images/invite.png';
const INVITE_COPY_DEFAULT = 'Love Music Bingo';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function () {

  const [isLoading, setIsLoading] = useState(false);
  //TODO: What to do if there is an error?
  // const [messageVariant, setMessageVariant] = useState('info');
  // const [showMessage, setShowMessage] = useState(false);
  // const [messageText, setMessageText] = useState('');
  const [inviteImageURL, setInviteImageURL] = useState(IMAGE_URL_DEFAULT);
  const [inviteCopy, setInviteCopy] = useState(INVITE_COPY_DEFAULT);
  const [backgroundImageURL, setBackgroundImageURL] = useState('');
  const [themeShade, setThemeShade] = useState('light');

  const [fields, handleFieldChange] = useFormFields({
    name: ''
  });

  const history = useHistory();

 

  const theme = useRef(null);

  const query = useQuery();
  if (query.get('user')) {

    store.set(CONSTANTS.STORE.PARTICIPANT_USER, query.get('user'));
  }

  if (query.get('game')) {
    store.set(CONSTANTS.STORE.PARTICIPANT_GAME, query.get('game'));
    store.set(CONSTANTS.STORE.PARTICIPANT_SONGS, []);
  }

  if (query.get('gamename')) {
    store.set(CONSTANTS.STORE.PARTICIPANT_GAME_NAME, query.get('gamename'));
  }

  let input;
  let bingoLink;

  if(!query.get('user') && !query.get('game') && !query.get('gamename') && store.get(CONSTANTS.STORE.MUSINGO_USER)){

    input =  useRef({ user: store.get(CONSTANTS.STORE.MUSINGO_USER) });
    bingoLink = useRef(`/${CONSTANTS.PAGE.BINGO_PREVIEW}`);
    
  }
  else{

    input = useRef({ user: store.get(CONSTANTS.STORE.PARTICIPANT_USER) });
    bingoLink = useRef(`/${CONSTANTS.PAGE.BINGO}`);
    
  }

  // let theme;

  const loaded = useLoaded('theme', setIsLoading, input.current);

  useEffect(() => {
    if (loaded.error) {
      //TODO: show error if not 404.

      console.log('Loaded Error', loaded.error);

      // setShowMessage(true);
      // setMessageText(loaded.error);
      // setMessageVariant('danger');

    }
    else if (loaded.data) {

      theme.current = loaded.data.theme;

    }
  }, [loaded.error, loaded.data]);

  // http://localhost:3000/invite?user=a3ab4628-d272-4214-82e7-2336666951bb&game=jaBwHbZbs61vbLVPDA7huF&gamename=InviteTest

  useEffect(() => {

    if (theme.current) {

      if(theme.current.inviteimage){

      setInviteImageURL(`${config.bingo.IMAGE_URL}/${theme.current.inviteimage}`);
      }

      setInviteCopy(theme.current.invitemessage);
      setThemeShade(theme.current.themeShade);

      if(theme.current.bingobackgroundimage){

        setBackgroundImageURL(`${config.bingo.IMAGE_URL}/${theme.current.bingobackgroundimage}`);
      }

      if (query.get('user') || store.get(CONSTANTS.STORE.MUSINGO_USER)) {

        let storedThemes = store.get(CONSTANTS.STORE.THEMES);

        if (!storedThemes) {
          storedThemes = {};
        }

        let user; 
        
        if(query.get('user')){
          user = query.get('user');
        }
        else{
          user = store.get(CONSTANTS.STORE.MUSINGO_USER);
        }

        

        storedThemes[user] = theme.current;

        store.set(CONSTANTS.STORE.THEMES, storedThemes);


      }
      




    }



  }, [query]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    store.set(CONSTANTS.STORE.PARTICIPANT_NAME, fields.name);

    history.push(CONSTANTS.PAGE.BINGO);


  }

  const validateForm = () => {
    return (
        fields.name.length > 0 
    );
}
  // const handleErroClose = () => {
  //   setShowMessage(false);
  // }


  return (
    <div className={`content-wrap un-auth ${themeShade === 'light' ? 'light-theme' : 'dark-theme'}`} id="invite" style={{ backgroundImage: `url(${backgroundImageURL})` }} >

      <Container fluid>
        <Row className=''>
          <Col xs md='6'>
            <Row>
              <div alt='inviteImage' style={{ backgroundImage: `url(${inviteImageURL})` }} className='vads-invite'></div>
              {/* <img alt='inviteImage' src={inviteImageURL} className='vads-invite' /> */}
            </Row>

          </Col>
          <Col md='6' className='d-flex '>
            <div id='invite-wrap' className='d-flex justify-content-center align-items-center flex-column'>
              {
                isLoading && <p>Loading Theme...</p>
              }

              {
                !isLoading &&
                <>
                  <h1>Musingo Bingo</h1>
                  <p>{inviteCopy}</p>
                  <h2>Welcome to Musingo Bingo</h2>

                <form onSubmit={handleSubmit}>
                <FormGroup controlId="name">
                  <FormControl
                    autoFocus
                    type="text"
                    placeholder="Your Name"
                    aria-label="Enter Name"
                    value={fields.name}
                    onChange={handleFieldChange}
                    className="h1"
                  />
                  <BsPencilSquare />

                </FormGroup>

                <Button type="submit" disabled={!validateForm()}  aria-label="select" size="lg" variant="link" block>
                  Play
                </Button>

                </form>

                
                  {/* <Link to={bingoLink.current} className='btn btn-primary'>Play</Link> */}
                
                </>
              }
              {/* <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible='true' variant={messageVariant} onClose={handleErroClose}>
                <p> {messageText} </p> </Alert> */}


              <br />
              <br />

              <strong>Share your experience</strong>
               
                <br />
                <div className="text-center">
                  <FacebookShareButton url={config.bingo.URL} ><FacebookIcon size={32} round /> </FacebookShareButton>{' '}
                  <WhatsappShareButton url={config.bingo.URL}><WhatsappIcon size={32} round /> </WhatsappShareButton >{' '}
                  <EmailShareButton url={config.bingo.URL}><EmailIcon size={32} round /> </EmailShareButton >{' '}
                </div>
              or
              <br />

                <Link to="/requestinvite" className="btn btn-link">Sign up!</Link>
              

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

