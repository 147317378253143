import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSpotifyWebPlaybackSdk } from "use-spotify-web-playback-sdk";
import { FaPlay, FaPause, FaStepForward, FaStepBackward, FaEllipsisH } from 'react-icons/fa';
import { Button, Alert, Container, Row, Col, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { API } from "aws-amplify";
import store from 'store';
import CONSTANTS from '../libs/constants';
import { useHistory } from 'react-router-dom';
import { getSpotifyToken, getSpotifyDevices } from "../libs/apiLib";
import { useLoaded } from "../libs/hooksLib";
import { getRandomColouredLogo } from "../libs/util";
import { setCurrentGame } from "../libs/apiLib";
import { isMobile } from 'react-device-detect';


const SPOTIFYCONNECT_RETURN_TO_PLAYER = `${CONSTANTS.PAGE.SPOTIFYCONNECT}?${CONSTANTS.QUERY.RETURN_PAGE}=${CONSTANTS.PAGE.CONTROL}`;
let popupTimer;
const TEN_MINUTES = 600000;


  export default function SpotifyPlayer({onUpdate, onTrack, setTrackSelector,  ...props}) {

  const [isConnected, setIsConnected] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentTrack, setCurrentTrack] = useState({});
  const [isPaused, setIsPaused] = useState(true);
  const [nextTracks, setNextTracks] = useState([]);
  const [isShuffled, setIsShuffled] = useState(false);
  const [previousTracks, setPreviousTracks] = useState([]);
  const [accessToken, setAccessToken] = useState(store.get(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN) ? store.get(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN) : '' );
  const [playedTracks, setPlayedTracks] = useState([]);
  const [expiry, setExpiry] = useState(store.get(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN_EXPIRY));
  const [showNext, setShowNext] = useState(true);

  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalMessageBig, setModalMessageBig] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [isCurrentGame, setIsCurrentGame] = useState(false);

  const [showModaSoundConnectlMessage, setShowModaSoundConnectlMessage] = useState(false);
  const [showSoundConnect, setShowSoundConnect] = useState(store.get(CONSTANTS.STORE.SOUND_CONNECT) ? store.get(CONSTANTS.STORE.SOUND_CONNECT) : 0 );

  const history = useHistory();


  const games = store.get(CONSTANTS.STORE.GAMES) ? store.get(CONSTANTS.STORE.GAMES) : [];

  const justSwitchedGame = useRef(false);

  const game = useRef(store.get(CONSTANTS.STORE.CURRENT_GAME));


  const loaded = useCallback(useLoaded('me'));


  useEffect(() => {

    if (loaded.data) {

        if(loaded.data.spotifyType !== CONSTANTS.PLAYER.PREMIUM){
            setShowModalMessage(true);
            setModalMessageBig('Spotify Premium Required')
            setModalMessage('Use the Track selector instead');

        }
       
    } 



  }, [loaded.dataLoaded]);

  useEffect(() => {

    if(isConnected && showSoundConnect < 2 && !isMobile){

      setShowModaSoundConnectlMessage(true);
      setShowSoundConnect(showSoundConnect + 1);
      store.set(CONSTANTS.STORE.SOUND_CONNECT, showSoundConnect + 1);
      
    }

    if(isConnected){

      const requestBody = {
        body: {
          game: game.current.game, 
          ingame: {
              isConnected: Date.now()
             
          }
          
        }
      };
  
      try{
  
            API.post('api', '/users/ingame', requestBody);
  
            
      }
      catch(e){
        console.log(e);
       
      }
      setVolume(0);
      setTimeout(() =>{ pausePlayback(); setVolume(100); }, 3000);
    }

    
    


  }, [isConnected]);

  useEffect(() => {

    if (props.pausePlayer) {

      pausePlayback();
       
    } 


  }, [props.pausePlayer]);

  if (!game.current) {
    //TODO: 
    // console.log('Need to do something if there is no game');
  }
  const user = useRef(store.get(CONSTANTS.STORE.MUSINGO_USER));

  const loadPlaylist = (playlist) => {

    const requestBody = {
      body: {
        context_uri: `spotify:playlist:${playlist}`

      }
    };

    pausePlayback();

    let spotifyPlayUrl = '/me/player/play';

    if(store.get('deviceid')){

      spotifyPlayUrl += `?device_id=${store.get(CONSTANTS.STORE.DEVICE_ID)}`;
    }

    API.put('spotify', spotifyPlayUrl, requestBody)
      .then((response) => {

        pausePlayback();
        
      })
      .catch((e) => {
        console.log('Error', e);
        setIsError(true);
        setErrorMessage(e.message);
      })

  }

  const handleModalClose = ()=> {

    setShowModalMessage(false);
    setModalMessageBig('');
    setModalMessage('');
   

  }

  const checkDevices = () =>{

           getSpotifyDevices()
          .then((response) => {
           
            if(response.devices){

              const musingoDevice = response.devices.find(device => device.name === CONSTANTS.PLAYER.NAME);

              if(musingoDevice && !musingoDevice.is_active)
              {
                  if(player){
                    player.disconnect();
                  }

                  setIsConnected(false);
              }

          }
          
          })
          .catch((e) => {
            console.log('Error', e);
            setIsError(true);
            setErrorMessage(e.message);
          });
        }
  

  const playerStateAction = playerState => {
    setIsConnected(true);

    
    if(!isCurrentGame){
      setCurrentGame(game.current.game);
      setIsCurrentGame(true);
    }


    if(store.get(CONSTANTS.STORE.PLAYER_ERROR_COUNT) && store.get(CONSTANTS.STORE.PLAYER_ERROR_COUNT) > CONSTANTS.PLAYER.MAX_ERRORS){
      store.remove(CONSTANTS.STORE.PLAYER_ERROR_COUNT);
      }

      
    if(!playerState){
      checkDevices();
      return;
    }

    if (playerState && playerState.context.uri !== `spotify:playlist:${game.current.playlist}`) {

      // console.log("Loading current Game", game.current );
      loadPlaylist(game.current.playlist);
    }
  

  

    if(justSwitchedGame.current){
     
      pausePlayback();
      

    }

    setCurrentTrack(playerState.track_window.current_track);

   
    setIsPaused(playerState.paused);

    if (!isPaused) addTrack(currentTrack);

  
    setNextTracks(playerState.track_window.next_tracks);

   
    setPreviousTracks(playerState.track_window.previous_tracks);


    setIsShuffled(playerState.shuffle);
  }

  const getAccessToken = (message) => {

    getSpotifyToken()
      .then((response) => {
       
        store.set(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN, response.access_token);
        store.set(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN_EXPIRY, response.expires);
       
        setAccessToken(response.access_token);
        setExpiry(response.expires);


        if(message && message.toLowerCase().includes('authentication')){

          history.go(0);
        }
       
        
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          history.push(SPOTIFYCONNECT_RETURN_TO_PLAYER);
        }
      });
  }


  const {
    deviceId,
    player,
    isReady,
  } = useSpotifyWebPlaybackSdk({
    name: CONSTANTS.PLAYER.NAME, // Device that shows up in the spotify devices list
    getOAuthToken: () => Promise.resolve(accessToken),
    onPlayerStateChanged: playerStateAction,
    accountError: (e) => {

      console.log(e);

      if(e && e.messsage && !e.message.toLowerCase().includes('authentication')){
        setIsError(true);
        setErrorMessage(e.message + ' Please reload page');
      }
    
      
      let errorCount = store.get(CONSTANTS.STORE.PLAYER_ERROR_COUNT) ? store.get(CONSTANTS.STORE.PLAYER_ERROR_COUNT) : 0;
      errorCount++;
      store.set(CONSTANTS.STORE.PLAYER_ERROR_COUNT, errorCount);

      if(errorCount >= CONSTANTS.PLAYER.MAX_ERRORS){
        history.push(SPOTIFYCONNECT_RETURN_TO_PLAYER);
      }
      else{
        getAccessToken(e.message);
      }
      //TODO: Check if it is an autentication error
      
    }

  }, []);

  

  useEffect(
    () => {

      if(deviceId){
        store.set(CONSTANTS.STORE.DEVICE_ID, deviceId);
      }

    },[deviceId]);

  

  useEffect(
      () => {

        //TODO: advanced not to user the page is going to refresh

        
        let timer;

        if(expiry){

            const time =  expiry - Date.now();

            timer = setTimeout( () =>{
              
              getAccessToken('authentication');
            

            }, time);

           
            
            popupTimer = setTimeout( () =>{

              
              
              setShowModalMessage(true);
              setModalMessageBig('Reconnect to spotify')
              setModalMessage('Your connection to spotify will expire in 10 minutes. Refresh your token but going back to the rounds page');
              // setIsError(true);
              // setErrorMessage("Please refresh your connction to Spotify");

            }, time - TEN_MINUTES);

        }
        else{
          getAccessToken();
        }


        return () => {

              if(timer){
                clearTimeout(timer);
              }

              if(popupTimer){
               
                clearTimeout(popupTimer);
              }
        }
  
      },[expiry]);

  useEffect(
    () => {
    
      return () => {


        if(store.get(CONSTANTS.STORE.PLAYER_ERROR_COUNT) && store.get(CONSTANTS.STORE.PLAYER_ERROR_COUNT) > CONSTANTS.PLAYER.MAX_ERRORS){
        store.remove(CONSTANTS.STORE.PLAYER_ERROR_COUNT);
        }
        if(player){
          
          player.pause();
          player.disconnect();
         

        }
      }

    },
    [isReady, player, accessToken, history],
  );

  const addTrack = async (track) => {

    const trackid = track.id;

    onTrack(track);

    if (playedTracks.includes(trackid)) {
      return null;
    }

    const tracks = [trackid]

    const requestBody = {
      body: {
        game: game.current.game,
        user: user.current,
        tracks

      }
    };

    try{

          await API.post('api', '/users/addanswers', requestBody);

          if (trackid) {
            playedTracks.push(trackid);
            setPlayedTracks(playedTracks);
          }
    }
    catch(e){
      console.log(e);
      API.post('api', '/users/addanswers', requestBody);
    }

  };





  

  const pausePlayback = () => {

    if(player){

    player.pause();
  
  }
  }

  const handleToggle = () => {

    justSwitchedGame.current = false;
    player.togglePlay();
  };

  const handlePrevious = () => {

    player.previousTrack().then(() => {
      // console.log('Set to previous track!');
    });

  };

  const handleNext = () => {

    player.nextTrack().then(() => {
      // console.log('Set to next track!');
    });

  };

  const handleShowNext = () =>{

    setShowNext(!showNext);

  }

  const setVolume = (percentage) =>{

    API.put('spotify', `/me/player/volume?volume_percent=${percentage}`)
    .then((response) => {
      // We dont need the result
    })
    .catch((e) => {
      console.log('Error', e);
    
    })


  } 

  // const handleShuffle = () => {

  //   API.put('spotify', `/me/player/shuffle?state=${!isShuffled}`)
  //     .then((response) => {
  //       // console.log('IsShuffled', isShuffled);
  //     })
  //     .catch((e) => {
  //       console.log('Error', e);
  //       setIsError(true);
  //       setErrorMessage(e.message);
  //     })
  // }



  const renderNextTracks = () => {

    return nextTracks.map(
      (track, i) =>
        <div className="track next" key={i} disable='true' >
          <img alt={track.name} src={track.album ? track.album.images[0].url : '/images/vads.png'} />
          <span>{track.name}</span>
        </div>
    )
  }


  const renderPreviousTracks = () => {

    return previousTracks.map(
      (track, i) =>
        <div className="track prev" key={i}>
          <img alt={track.name} src={track.album ? track.album.images[0].url : '/images/vads.png'} />
          <span>{track.name}</span>
        </div>
    )
  }

  const handleReload = () => {
    history.push(CONSTANTS.PAGE.CONTROL);
  }

  const handleGameSwitch = (newGame) =>{

    game.current = JSON.parse(newGame);
    onUpdate(game.current);
    justSwitchedGame.current = true;

    if(game.current.playlisttype === 'youtube'){
      history.go(0)
    }
    else{
      
      loadPlaylist(game.current.playlist);
    }

    // swtichPlaylist(game);

  }

  const handleSoundConnecClose = () =>{

    console.log('handleSoundConnecClose');
    pausePlayback();
    setShowModaSoundConnectlMessage(false);

   

  }

 


  return (
    <Container fluid id="spotify-player">



      {!isConnected &&
        <>
          <p><strong>Please connect to the Musingo Player in Spotify like the example below.  </strong> <br/><br/><strong>Once connected this page will automatically load the Musingo Player.</strong> <br/>  <br/>If you don't see the Musingo Player in Spotify please try reloading this page.</p>
          <img alt="spotify connect" id="spotify-connect" src="/images/spotifyconnect.jpeg" />
        </>
      }

      {isConnected &&
        <div>
          {/* <p>Connected</p> */}
          {game.current.playlistname &&
                 <p>Playlist: {game.current.playlistname}</p>
            }

          <Row>
            <Col className="prev-tracks">
              {renderPreviousTracks()}
            </Col>

            <Col xs={4}>
              <div className="track current">
                <img alt={currentTrack.name} src={currentTrack.album ? currentTrack.album.images[0].url : '/images/vads.png'} />
                <span>{currentTrack.name}</span>
              </div>
            </Col>

            <Col className="next-tracks">
              {showNext  && renderNextTracks()}
            </Col>
          </Row>


          <Button onClick={handlePrevious}><FaStepBackward /></Button>{' '}
          {/* <Button onClick={() => handleSeek('forward')}><FaBackward/></Button> */}
          <Button onClick={handleToggle}>{isPaused ? <FaPlay /> : <FaPause />}</Button>{' '}
          {/* <Button onClick={() => handleSeek('backward')}><FaForward/></Button> */}
          <Button onClick={handleNext}><FaStepForward /></Button>{' '}
          <Button onClick={handleShowNext}><FaEllipsisH /></Button>{' '}
          {/* <Button active={isShuffled} onClick={handleShuffle}><FaRandom /></Button> */}

           <DropdownButton id="dropdown-basic-button" title="Change Round" onSelect={handleGameSwitch} size="sm" variant="link">

           <Dropdown.Item key={'current'} eventKey={JSON.stringify(game.current)} >{'Reload current game'}</Dropdown.Item>
       {games.length > 0 &&

                
                games.filter(gameObj => gameObj.game !== game.current.game ).map(
              (newgame, i) =>
                <Dropdown.Item key={i} eventKey={JSON.stringify(newgame)} >{newgame.name}</Dropdown.Item>

        )
      }
       
      </DropdownButton>


        </div>
      }

      <Row className="justify-content-md-center">
        <Col lg={6}>
          {
            isError &&
            <Alert variant="danger">
              {/* {errorMessage} */}
              {errorMessage}{' '}
              <a href='gamecontrol' >
                <Button onClick={handleReload}>Reload</Button>{' '}

              </a>
            </Alert>

          }
        </Col>
      </Row>

      <Modal show={showModalMessage} onHide={handleModalClose}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h1>{modalMessageBig}</h1>
                    <p>{modalMessage}</p>
                    <Button variant="primary" onClick={setTrackSelector}>Track Selector</Button>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>

            <Modal show={showModaSoundConnectlMessage} onHide={handleSoundConnecClose}>
              <Modal.Header closeButton>
                Musingobingo.com
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h1>Have you connnected your computer sound?</h1>
                    <p>You need to connect your computer sound so your players can hear the music</p>
                    <a rel="noopener noreferrer"  target="_blank" href={`https://youtu.be/hEHJgPbGnrY`}>
                <Button variant="success" size="lg" onClick={handleSoundConnecClose} className="spotify">          
                  How to connect sound
                </Button>
              </a>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>

    </Container>
  );
}

