import React from "react";
import { Row, Col, Container} from 'react-bootstrap';
import { getRandomColouredLogo } from "../libs/util";

export default function NotFound() {
  return (
    <div id='bingo' className='content-wrap'>

      <Container fluid>

        <Row className='justify-content-md-center'>
          <Col xs md='6' className='justify-content-md-center text-center'>
          {/* <h3>Sorry, page not found</h3> */}
          <div>
          <img alt="modalImage" src={`images/${getRandomColouredLogo()}`} />

          </div>
          <h3>Sorry, page not found</h3>
          </Col>
        </Row>
        </Container>
        </div>
  );
}