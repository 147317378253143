import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Button, Modal } from 'react-bootstrap';
import store from 'store';
import CONSTANTS from '../libs/constants';
import { useLoaded } from '../libs/hooksLib';
import { FaPlus } from 'react-icons/fa';
import { limitText } from "../libs/util";
import { trackEvent } from "../libs/events";
import { getRandomColouredLogo } from "../libs/util";
import { getSpotifyToken } from "../libs/apiLib";
import YTImageSquare from "../components/YTImageSquare";
import { FaYoutube, FaSpotify  } from 'react-icons/fa';




const INFO_MODAL_MAX_COUNT = 2;


export default function Game() {

  const [isLoading, setIsLoading] = useState(false);
  const [showInitialModal, setshowInitialModal] = useState(false);
  const [showThemeModal, setshowThemeModal] = useState(false);
  const [modalHasBeenClosed, setModalHasBeenClosed] = useState(false);
  const [games, setGames] = useState(store.get(CONSTANTS.STORE.GAMES) ? store.get(CONSTANTS.STORE.GAMES) : []);
  const history = useHistory();

  const loaded = useLoaded('game', setIsLoading);

  // console.log(loaded);

  // const [showInitialModal, setshowInitialModal] = useState(false);

  const infoModalCount = store.get(CONSTANTS.STORE.INFO_MODAL_COUNT) ? store.get(CONSTANTS.STORE.INFO_MODAL_COUNT) : 0;



  useEffect(() => {

      if(infoModalCount < INFO_MODAL_MAX_COUNT){
          // console.log('Showing modal');
          setshowInitialModal(true);
          store.set(CONSTANTS.STORE.INFO_MODAL_COUNT, infoModalCount + 1 );
      }
      

  
    }, []);

  useEffect(() => {


    if (loaded.error) {
      console.log(loaded.error);
     
      if(loaded.error.status === 404 && !modalHasBeenClosed && !showInitialModal){
        setshowThemeModal(true);
        store.remove(CONSTANTS.STORE.GAMES);
        setGames([]);
      }
  
    }
    else if(loaded.data) {

      
      setGames(loaded.data);
      store.set(CONSTANTS.STORE.GAMES, loaded.data);
    }

  }, [loaded.dataLoaded, loaded.error]);

  useEffect(() => {

    getSpotifyToken()
      .then((response) => {
        store.set(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN, response.access_token);
        store.set(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN_EXPIRY, response.expires);
        


      })
      .catch(error => {
          console.log(error);
      });
    

  }, []);


  const handleClick = (game) => {
    store.set(CONSTANTS.STORE.CURRENT_GAME, game);
    history.push(CONSTANTS.PAGE.CONTROL);
  }

  const handleNewGameClick = (event) => {
    event.preventDefault();
    trackEvent('createNewGame');
    history.push(CONSTANTS.PAGE.ADDGAME);
  }

  // const handleShareModalClose = () => {

  //   setModalHasBeenClosed(true);
  //   setshowInitialModal(false);

  // }

  const handleThemeModalClose = () => {

    setshowThemeModal(false);
    setModalHasBeenClosed(true);

  }

  const handleInitialModalClose = () => {

    setshowInitialModal(false);

  }






  return (
    <div id='bingo' className='content-wrap'>

      <Container fluid>

        <Row className='justify-content-md-center'>
          <Col xs md='6' className='justify-content-md-center text-center'>
            <h1>Rounds</h1>

            {
              isLoading && <p>Loading...</p>
            }


            <Row className='card-wrap'>

              <Col xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                <div className='d-flex align-items-stretch'>

                  <Button onClick={handleNewGameClick} className='card' >
                    {/* <img alt='new game' src='/images/ydbb_elements-03.png' /> */}
                    <FaPlus className="plus" />
                    <span>CREATE NEW ROUND</span>
                  </Button>


                </div>
              </Col>

              {games &&

                games.map(
                  (game, i) =>
                    <Col key={i} xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                      <div className='d-flex align-items-stretch'>

                        <Button onClick={() => handleClick(game)} className='card noscroll' disabled={isLoading} >
                        {game.playlisttype !== 'youtube' &&  <img alt={game.name} src={game.image} /> }

                        {game.playlisttype === 'youtube' &&  <YTImageSquare images={game.images} defaultImage={game.image} /> }
                          {/* <img alt={game.name} src={game.image} /> */}
                          
                          {/*game.playlisttype === 'youtube' && <br /> */}
                          <span>{game.name ? limitText(game.name) : 'No Name'}</span>
                          {game.playlisttype === 'youtube' && <span> YouTube <FaYoutube /> </span> }
                          {game.playlisttype !== 'youtube' && <span> Spotify <FaSpotify />  </span>}
                           
                        </Button>


                      </div>
                    </Col>

                )
              }

              {/* <Alert style={{marginTop:'8px'}} key='message' show={showMessage} dismissible='true' variant={messageVariant} onClose={handleErroClose}>
                    <p> {messageText} </p> </Alert> */}


            </Row>

          </Col>
        </Row>
      </Container>

      <Modal show={showInitialModal} onHide={handleInitialModalClose}>      
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col>

              <h1>Hey, welcome to Musingo Bingo! </h1>

              <p>To get started on hosting your party:</p>

              <ol>
              <li>Choose your party level</li>
              <li>Follow the steps in the Wizard</li>
              <li>Have fun!</li>
              <li>Share your experience #musingobingo @musingobingo</li>
              </ol>


              <p>Love Music Bingo</p>
            
              {/* <div className="text-center">
                <FacebookShareButton url={CONSTANTS.URLS.SHARE} ><FacebookIcon size={32} round /> </FacebookShareButton>{' '}
                <WhatsappShareButton url={CONSTANTS.URLS.SHARE}><WhatsappIcon size={32} round /> </WhatsappShareButton >{' '}
                <EmailShareButton url={CONSTANTS.URLS.SHARE}><EmailIcon size={32} round /> </EmailShareButton >{' '}
              </div> */}
               <Button variant="primary" onClick={() => history.push(CONSTANTS.PAGE.WIZARD)}>Use Wizard</Button>
            </Col>
           

          </Row>

          <Row>
            <Col></Col>
          <Col className="d-flex align-items-end">
                      <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                      {/* <img alt="modalImage" src={"/images/winner.gif"} /> */}
                    </Col>
          </Row>

        </Modal.Body>
      </Modal>

      <Modal show={showThemeModal} onHide={handleThemeModalClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>

        <Row>
                  <Col>
                    <h1>It's all about you!</h1>
                    <p>Before you create a game why not personalise Musingo by creating a theme.</p>
                    <Button variant="primary" onClick={() => history.push(CONSTANTS.PAGE.THEME)}>Create a Theme</Button>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <img alt="modalImage" src={`images/${getRandomColouredLogo()}`} />
                  </Col>
                </Row>


          {/* <Row className="justify-content-md-center">
            <Col>

              <h1>It's all about you</h1>


              <p>Before you create a game why not personalise Musingo by uploading a theme.</p>

              <Button variant="primary" onClick={() => history.push(CONSTANTS.PAGE.THEME)}>Create a Theme</Button>

              <div className="text-center">
                <FacebookShareButton url='' ><FacebookIcon size={32} round /> </FacebookShareButton>{' '}
                <WhatsappShareButton url=''><WhatsappIcon size={32} round /> </WhatsappShareButton >{' '}
                <EmailShareButton url=''><EmailIcon size={32} round /> </EmailShareButton >{' '}
                <InstapaperShareButton url=''><InstapaperIcon size={32} round /> </InstapaperShareButton >
              </div>

            </Col>

          </Row> */}

        </Modal.Body>
      </Modal>

      
    </div>
  );
}

