import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();

  

  // if(pathname === '/bingo'){

  //   return redirectBingo(isAuthenticated,children, ...rest)
  // }

  
  return (
    <Route {...rest}>
      {isAuthenticated ? (
        children
      ) : (
        <Redirect to={
          `/login?redirect=${pathname}${search}`
        } />
      )}
    </Route>
  );
}

// function redirectBingo(isAuthenticated,children, ...rest){
//   return (
//     <Route {...rest}>
//       {isAuthenticated ? (
//         children
//       ) : (
//         <Redirect to={
//           `/bingoview`
//         } />
//       )}
//     </Route>
//   );
// }
