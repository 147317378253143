import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container, Modal } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { API } from "aws-amplify";
import { getUser } from "../libs/awsLib";
import store from 'store';
import { useQuery } from "../libs/hooksLib";
import { useHistory } from 'react-router-dom';
import CONSTANTS from '../libs/constants';
import { FaCheckCircle } from 'react-icons/fa';
import { limitText } from "../libs/util";
import { getRandomColouredLogo } from "../libs/util";



const SpotifyPlaylists = () => {

  const query = useQuery();

  if (query.get(CONSTANTS.QUERY.SPOTIFY_ACCESS_TOKEN)) {
    store.set(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN, query.get(CONSTANTS.QUERY.SPOTIFY_ACCESS_TOKEN));
  }

  const [isLoading, setIsLoading] = useState(true);
  // const [isError, setIsError] = useState(false);
  // const [hasPlaylists, setHasPlaylists] = useState(false);


  const { isAuthenticated } = useAppContext();
  const [hasData, setHasData] = useState(false);

  const [playlists, setPlaylists] = useState([]);
  const [savedPlaylists, setSavedPlaylists] = useState([]);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const history = useHistory();


  useEffect(() => {

    let isCurrent = true;

    async function onLoad() {

      try {

        setIsLoading(true);
        const result = await loadSpotifyPlaylists();


        const playlistsResponse = result[0];
        const savedPlaylistsResponse = result[1];
        const user = await getUser();
        store.set(CONSTANTS.STORE.MUSINGO_USER, user);

        if (isCurrent) {

          setPlaylists(playlistsResponse);

          if (savedPlaylistsResponse) {

            setSavedPlaylists(savedPlaylistsResponse.map(playlist => playlist.playlist));
          }


          // setHasPlaylists(true);
          setHasData(true)
        }
      } catch (e) {

        console.log('error', e);

        // || e.response.status === 404
        if (e.response && e.response.status === 401) {
          console.log('error', e.response.status);
          history.push(CONSTANTS.PAGE.SPOTIFYCONNECT);
        }
        // console.log("Error", e.response.status);
        if (isCurrent) {

          // setIsError(true);
          setHasData(false);
        }
      }
      if (isCurrent) {
        setIsLoading(false);
      }
    }


    if (query.get(CONSTANTS.QUERY.RETURN_PAGE)) {
      history.push(query.get(CONSTANTS.QUERY.RETURN_PAGE));
     
    }
    else{
      onLoad();
    }

   

    return () => {
      isCurrent = false;
    }

  }, [isAuthenticated]);


  function handleModalClose() {

   setShowModalMessage(false);

  }



  async function handleSelect(playlist) {

    if(savedPlaylists.includes(playlist.id)){


      const tempPlaylist = savedPlaylists.slice(0);

      const index = tempPlaylist.indexOf(playlist.id);
        if (index > -1) {
          tempPlaylist.splice(index, 1);
        }

        removePlaylist(playlist.id);

        setSavedPlaylists(tempPlaylist);

    }
    else{


    let image;

    if (playlist.images) {
      image = playlist.images[0].url
    }

    const tempPlaylist = savedPlaylists.slice(0);

      tempPlaylist.push(playlist.id);

      savePlaylist(playlist.name, playlist.id, image);

      setSavedPlaylists(tempPlaylist);
  }

  }

  async function removePlaylist(playlist) {

    let requestBody = {
      body: {
        playlist

      }
    };

    await API.del('api', '/users/playlist', requestBody);

  }


  

  async function savePlaylist(name, playlist, image) {

    let requestBody = {
      body: {
        name,
        playlist,
        image

      }
    };

    let playlistAdded = await API.post('api', '/users/playlist', requestBody);


   if(playlistAdded.hasDuplicate){

    setShowModalMessage(true);

   }
   else if (playlistAdded.playlist) {

      const tempPlaylist = savedPlaylists.slice(0);

      tempPlaylist.push(playlistAdded.playlist);

      setSavedPlaylists(tempPlaylist);


    }


  }

  function isSaved(playlistid, asString) {

    // console.log(savedPlaylists);
    // console.log(playlistid);


    if (savedPlaylists.includes(playlistid)) {

      if (asString) {
        return 'true';
      }

      return true;
    } else {
      if (asString) {
        return 'false';
      }
      return false;
    }
  }

  function handleBackClick() {
    history.push(CONSTANTS.PAGE.ADDGAME);
  }


  // async function loadSpotifyPlaylists(){

  //   let promises = [];


  //   promises.push(API.get('api', '/users/spotify/playlists'));
  //   promises.push(API.get('api', '/users/playlists'));

  //   let results = await Promise.all(promises);

  //   return results;


  // }

  async function loadSpotifyPlaylists() {

    let promises = [];



    promises.push(API.get('api', '/users/spotify/playlists?return=ok'));
    promises.push(API.get('api', '/users/playlists?return=ok'));


    let results = await Promise.all(promises);


    return results;


  }

  return (
    <div id="bingo" className="content-wrap">

      <Container fluid>
        <Button onClick={handleBackClick} variant="link" size="sm" className="back">Back</Button>

        <Row className="justify-content-md-center">
          <Col xs md="6" className="justify-content-md-center text-center">
            <h3>Spotify Playlists</h3>


           

            {
              isLoading && <p>Loading...</p>
            }
            {
              !isLoading && 
              <>
              <p><br/>Showing your <strong>PUBLIC</strong> playlists with <strong>15</strong> or more tracks.</p>

              {playlists.length > 0 &&

              <p> Click on a playlist to add or update it in Musingo Bingo</p>
              }

              

              </>
            }

          {(hasData && playlists.length === 0) && <p>Create a <strong>PUBLIC</strong> playlist in Spotify <strong>15</strong> or more tracks</p> }


            <Row className="card-wrap">


              {hasData &&

                playlists.map(
                  (playlist, i) =>
                    <Col key={i} xs="6" md="4" lg="3" className="d-flex align-items-stretch">
                      <div className="d-flex align-items-stretch">
                        <Button onClick={() => handleSelect(playlist)} className='card'>
                          <img alt={playlist.name} src={(playlist.images && playlist.images.length > 0) ? playlist.images[0].url : '/images/vads.png'} />
                          <span>{playlist.name ? limitText(playlist.name) : 'No Name'}</span>
                          <span>&nbsp;({playlist.trackCount})</span>
                          {isSaved(playlist.id) &&
                            <FaCheckCircle className="tick"/>
                          }  
                        </Button>

                      </div>
                    </Col>

                )
              }

            </Row>

          </Col>
        </Row>

        <Modal show={showModalMessage} onHide={handleModalClose}>
              <Modal.Header closeButton>
                Musingobingo.com
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h1>Duplicates</h1>
                    <p>This playlist has duplicate songs. Please remove the duplicates and then try to add the playlist again.</p>
                    <Button variant="primary" onClick={handleModalClose}>Close</Button>
                  </Col>
                  <Col className="d-flex align-items-end">
                  <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                    
                    
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>
        

      </Container>
    </div>
  );


}

export default SpotifyPlaylists;