import React from "react";





export default function YTImageSquare( {images, altName, defaultImage}) {

  if(!images || images.length < 4){

	return(
    
   
		<div className="ytImage-container" >

    <div className=" ytImage-bg-one" style={{ backgroundImage: `url("${defaultImage}")` }}></div>

  </div>
   
	)

  }

  return (

<div className="ytImage-container">
 
    
    <div className=" ytImage-bg" style={{ backgroundImage: `url("${images[0]}")` }}></div>
    <div className=" ytImage-bg" style={{ backgroundImage: `url("${images[1]}")` }}></div>
    <div className=" ytImage-bg" style={{ backgroundImage: `url("${images[2]}")` }}></div>
    <div className=" ytImage-bg" style={{ backgroundImage: `url("${images[3]}")` }}></div>
  
  </div>


  );
}


