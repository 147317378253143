import React, { useState } from "react";
// import { useHistory, Link } from "react-router-dom";
import { Link } from "react-router-dom";
import {FormGroup, FormControl, Row, Container, Col, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
// import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from "../../libs/hooksLib";
// import { onError } from "../../libs/errorLib";
import { API } from "aws-amplify";
import validator from 'validator'

export default function AdminSignup() {
    const [fields, handleFieldChange] = useFormFields({
        emailaddress: '',
        username: '',
        tier:'free'
    });
    // const history = useHistory();
    // const [isError, setIsError] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");
    // const [newUser, setNewUser] = useState(null);
    // const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [messageVariant, setMessageVariant] = useState('info');
    const [showMessage, setShowMessage] = useState(false);
    const [messageText, setMessageText] = useState('');

    function validateForm() {
        return (
            fields.emailaddress.length > 0 &&
            fields.username.length > 0 &&
            validator.isEmail(fields.emailaddress)
            
            
        );
    }

   

    async function registerUser(values){
       
            let requestBody = { body:values};
        
            return await API.post('api', '/admin/createuser', requestBody);
       
       
    }

    function handleErroClose(){
        setShowMessage(false);
        fields.emailaddress = '';
        fields.username = '';
        fields.tier = 'free';
    }
    
    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {

            let values = {
                emailaddress: fields.emailaddress,
                username: fields.username,
                tier: fields.tier
               
            }

            await registerUser(values);
        
            setIsLoading(false);

            setShowMessage(true);
            setMessageText(`A new password has been sent to the user's email account`);
            setMessageVariant('info');

            // history.push('/');
            
            
        }
        catch (e) {
            console.log(e.response);
            // onError(e);
            setShowMessage(true);



            setMessageText(e.response.data.message);
            setMessageVariant('danger');
            setIsLoading(false);
        }
    }

    function renderForm() {
        return (
            <div className="form-wrap">
                <h1>Create User</h1>
                <form onSubmit={handleSubmit}>
                    <FormGroup controlId="emailaddress" bssize="large">
                        <FormControl
                            autoFocus
                            type="email"
                            placeholder="Email"
                            aria-label="Email"
                            value={fields.emailaddress}
                            // value={"emmanuel+6@omegaalexis.com"}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="username" bssize="large">
                        <FormControl
                            type="text"
                            placeholder="Username"
                            aria-label="Username"
                            value={fields.username}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>

                    <FormGroup controlId="tier" bssize="large"> 
                    <FormControl as="select" defaultValue="free" onChange={handleFieldChange} >
                        <option value="">Choose tier</option>
                        <option value="free">Free</option>
                        <option value="bronze">Bronze</option>
                        <option value="silver">Silver</option>
                        <option value="gold">Gold</option>
                    </FormControl>
                    </FormGroup>

                    
                    
                    <LoaderButton
                        className="pull-right"
                        block
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                        text="Sign up"
                        loadingText="Signing up..."
                    />

                
                </form>
            </div>
            

        );
    }

    return (
        <div className="content-wrap">
           
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs md="6" lg="4">
                        {renderForm()}
                    
                    <Alert style={{marginTop:'8px'}} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                        {messageText} 
                        
                        </Alert>
                    {  showMessage && 
                        <Link style={{marginTop:'8px'}}  to="/" className="btn btn-link btn-block">Home</Link>
                    }
 
                    </Col>
                    
                </Row>
                
            </Container>
        </div>
    );
}