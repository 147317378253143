import React, { useMemo, useState, forwardRef, useImperativeHandle } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import LoaderButton from "./LoaderButton";
import useResponsiveFontSize from "../useResponsiveFontSize";

// https://codesandbox.io/s/react-stripe-official-q1loc?fontsize=14&hidenavigation=1&theme=dark&file=/src/index.js

const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }),
      [fontSize]
    );
  
    return options;
  };


const CardForm = forwardRef( ({isLoading, setIsLoading, onSubmit, clientSecret, ...props}, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [enableButton, setEnableButton] = useState(false);
  let  cardElement
 

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    cardElement = elements.getElement(CardElement);

    // const { token, error}  = await stripe.createToken(cardElement);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement
       
      }
    });



    onSubmit(result);

  };

  useImperativeHandle(ref, () => ({

    clearCard() {
      elements.getElement(CardElement).clear();
    }

  }));

  return (
    <form onSubmit={handleSubmit}>
      
        <CardElement
          options={options}
          onReady={(el) => {
          
          el.focus();
          //  setEnableButton(true);
          }}
          onChange={event => {
            
            if(event && event.error){
              
              setEnableButton(false);
            }
            else{
              setEnableButton(true);
              
            }
          }}
          onBlur={() => {
            // console.log("CardElement [blur]");
          }}
          onFocus={() => {
            // console.log("CardElement [focus]");
          }}
        />
      
      {/* <button type="submit" disabled={!stripe}>
        Pay
      </button> */}
      <LoaderButton
                    className="pull-right"
                    block
                    type="submit"
                    isLoading={isLoading}
                    disabled={!stripe || !enableButton}
                    // disabled={!enableButton || !stripe }
                    text="Pay"
                    loadingText="Paying..."
                    style={{marginTop:'8px', maxWidth:'320px',  margin: '0 auto'}}
                />
    </form>
  );
});

export default CardForm;
