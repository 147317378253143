import React, { useState, useEffect, useReducer, useMemo, useCallback, useRef } from "react";
import { Alert, Button, Row, Col, Container, Modal, Overlay, Tooltip } from "react-bootstrap";
import { API } from "aws-amplify";
import store from 'store';
import config from '../config';
import { useWebsocketSubscription, useWebsocketPublish } from "../libs/hooksLib";
import { FaCheckCircle, FaHeart, FaMusic, FaRedoAlt  } from 'react-icons/fa';
import { limitText, updateGameStatus } from "../libs/util";
import CONSTANTS from '../libs/constants';
import { getRandomColouredLogo } from "../libs/util";
import { trackEvent } from "../libs/events";
// import TwitterHeart from 'twitter-heart';
import Likes from "../components/Likes";

import TickerTape from "../components/TickerTape";


import { Link } from 'react-router-dom';
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from "react-share";



const CARD_SIZE = 12;
const BIG_ERROR_COPY = 'Uh oh!';
const BINGO_BIG_COPY = 'Musingo Bingo!';
const BINGO_SMALL_COPY = 'I\'M A WINNER BABY!';
const BIG_WRONG_COPY = 'Something went wrong!';
const SMALL_WRONG_COPY = 'Please try again';

const WHATS_PLAYING_COPY = 'What is playing?';

const IMAGE_SELECT_DEFAULT = '';
const IMAGE_WINNER_DEFAULT = '/images/winner.png';
const IMAGE_BACKGROUND_DEFAULT = '/images/BG_Shapes.png';
const IMAGE_MODAL_DEFAULT = `/images/${getRandomColouredLogo()}`;
let gameStatusMessages = [];

const HINT_DELAY = 5000;





const Bingo = () => {

  let user = store.get(CONSTANTS.STORE.PARTICIPANT_USER);
  let participantName = store.get(CONSTANTS.STORE.PARTICIPANT_NAME);
  let gameid = store.get(CONSTANTS.STORE.PARTICIPANT_GAME);
  let gamename = store.get(CONSTANTS.STORE.PARTICIPANT_GAME_NAME);
  let participant = store.get(CONSTANTS.STORE.PARTICIPANT_ID);
  // const lastPlayed = useRef('Not available yet');
  const lastPlayed = useRef(WHATS_PLAYING_COPY);
  const target = useRef(null);
  const currentGameTarget = useRef(null);

  const [selectImageURL, setSelectImageURL] = useState('');
  const [winnerImageURL, setWinnerImageURL] = useState(IMAGE_WINNER_DEFAULT);
  const [backgroundImageURL, setBackgroundImageURL] = useState('');
  const [bingoMessage, setBingoMessage] = useState('');
  const [themeShade, setThemeShade] = useState('light');
  const [showLastPlayed, setShowLastPlayed] = useState(false);
  const [showReloadRoad, setShowReloadRoad] = useState(false);
  const [fireVariant, setFireVariant] = useState('link');
  const [fireCount, setFireCount] = useState(0);
  const [disableHints, setDisableHints] = useState(false);
  const [moveTickerTape, setMoveTickerTape] = useState(false);
  const [tickerMessage, setTickerMessage] = useState('');
  const [isWinner, setIsWinner] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  // const [likeSpeed, setLikeSpeed] = useState(30);
  const [likeSpeed] = useState(30);

  let hintTimer = null;


  if(!gameid &&  !gamename && !user && store.get(CONSTANTS.STORE.CURRENT_GAME)){

     const currentGame = store.get(CONSTANTS.STORE.CURRENT_GAME);

     console.log('currentGame', currentGame);

     gameid = currentGame.game;
     gamename = currentGame.name;
     user = currentGame.user;

  }


  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'LOADING_CARD': {
          return {
            ...state,
            isLoading: true,
            modalMessage: '',
            modalMessageBig: '',
            showModalMessage: false,
            card: store.get(CONSTANTS.STORE.CARD) ? store.get(CONSTANTS.STORE.CARD) : []

          }
        }
        case 'CARD_LOADED': {
          return { ...state, card: action.card, isLoading: false }
        }
        case 'LOADING_CARD_ERROR': {
          return { ...state, isLoading: false, error: action.error, isError: true }
        }
        case 'RESET': {
          return { ...state, reset: false }
        }
        case 'UPGRADE_REQUIRED' :{
           return{...state,
            modalMessage: 'This game has reached the maximum number of players. Please contact the person who invited you.',
            modalMessageBig: CONSTANTS.MESSAGE.UPGRADE,
            showModalMessage: true,
            isLoading: false,
           }
            
        }
        case 'RESET_MESSAGE': {
          return {
            ...state,
            modalMessage: '',
            modalMessageBig: '',
            showModalMessage: false,
            modalImage: IMAGE_MODAL_DEFAULT
          }
        }
        case 'UPDATE_MESSAGE': {
          return {
            ...state,
            modalMessage: action.message.small,
            modalMessageBig: action.message.big,
            showModalMessage: action.message.show
          }
        }
        case 'NEW_GAME': {
          store.set(CONSTANTS.STORE.PARTICIPANT_SONGS, []);
          setTickerMessage('');
          setMoveTickerTape(false);
          setIsWinner(false);
          setFireCount(0);
          setFireVariant('link');
          store.remove(CONSTANTS.STORE.LIKES);
          setLikeCount(0);
          setShowLastPlayed(false);
          lastPlayed.current = WHATS_PLAYING_COPY;
          gameStatusMessages = [];
          return {
            ...state,
            game: action.game,
            gameName: action.gamename,
            songs: [],
            reset: true,
            modalMessage: action.message.small,
            modalMessageBig: action.message.big,
            showModalMessage: action.message.show

          }
        }
        case 'RESET_ALL': {
          store.set(CONSTANTS.STORE.PARTICIPANT_SONGS, []);
          store.remove(CONSTANTS.STORE.LIKES);
          setLikeCount(0);
          setTickerMessage('');
          setMoveTickerTape(false);
          gameStatusMessages = [];
          return {
            ...state,
            songs: [],
            reset: true
          }
        }
        case 'ADD_SONG': {
          return {
            ...state,
            songs: action.songs
          }
        }
        case 'BINGO': {
          return { ...state, hasBingo: true, modalImage: IMAGE_WINNER_DEFAULT }
        }


        default:
          return state;
      }
    },
    {
      isLoading: true,
      isError: false,
      game: gameid,
      gameName: gamename,
      reset: false,
      songs: [],
      card: [],
      error: null,
      modalMessage: '',
      modalMessageBig: '',
      showModalMessage: false,
      modalImage: IMAGE_MODAL_DEFAULT,
      // modalImage:'/images/logo.png',
      selectImageURL: IMAGE_SELECT_DEFAULT,
      winnerImageURL: IMAGE_WINNER_DEFAULT,
      backgroundImageURL: IMAGE_BACKGROUND_DEFAULT,
      hasBingo: false

    }
  )

  const { isLoading, isError, game, gameName, reset,
    // songs, card, modalMessage, modalMessageBig, showModalMessage, modalImage, hasBingo,
    card, modalMessage, modalMessageBig, showModalMessage, modalImage,
  } = state;

  const themeStyle = {
    backgroundImage: `url("${backgroundImageURL}")`
  };


  if (store.get(CONSTANTS.STORE.THEMES) && store.get(CONSTANTS.STORE.THEMES)[user]) {

    const theme = store.get(CONSTANTS.STORE.THEMES)[user];

    if (theme.bingoselectimage && selectImageURL !== `${config.bingo.IMAGE_URL}/${theme.bingoselectimage}`) {

      setSelectImageURL(`${config.bingo.IMAGE_URL}/${theme.bingoselectimage}`);
    }

    if (theme.bingowinnerimage && winnerImageURL !== `${config.bingo.IMAGE_URL}/${theme.bingowinnerimage}`) {

      setWinnerImageURL(`${config.bingo.IMAGE_URL}/${theme.bingowinnerimage}`);
    }

    if (theme.bingobackgroundimage && backgroundImageURL !== `${config.bingo.IMAGE_URL}/${theme.bingobackgroundimage}`) {


      setBackgroundImageURL(`${config.bingo.IMAGE_URL}/${theme.bingobackgroundimage}`);

      // themeStyle.backgroundImage = `url("${backgroundImageURL}")`;

    }

    if(theme.bingomessage && bingoMessage !== theme.bingomessage){

          setBingoMessage(theme.bingomessage);

    }
    
    if(theme.themeShade && themeShade !== theme.themeShade){
          
          setThemeShade(theme.themeShade);
    }

  }

  const addLikes = () =>{
    
    //TODO: Need to fix this hack
    let storeLikeCount = store.get(CONSTANTS.STORE.LIKES) ? store.get(CONSTANTS.STORE.LIKES) : 0;

    let newStoreLikeCount = storeLikeCount + 1;

    store.set(CONSTANTS.STORE.LIKES, newStoreLikeCount);

    if(newStoreLikeCount <= CONSTANTS.PLAYER.MAX_LIKES){
            setLikeCount(newStoreLikeCount + 2);
    }
  }


  const channels = useMemo(() => {
    return ['default', user, `${CONSTANTS.WEBSOCKET.CHANNEL.ADMIN}_${user}`];
  }, [user]);

  const messageAction = useCallback((messageEvent) => {

    if (!messageEvent) {
      return;
    }

    let event = messageEvent.message;

    if (event.action) {

      let action = event.action;

      if(action === 'lastplayed'){

          setFireCount(0);
          store.remove(CONSTANTS.STORE.LIKES);
          setLikeCount(0);
          setFireVariant('link');
          setShowLastPlayed(false);

          if(event.name){

            if(hintTimer){
              clearTimeout(hintTimer);
            }
            // lastPlayed.current = event.name;
            hintTimer = setTimeout(() =>{ lastPlayed.current = event.name; }, HINT_DELAY);
          }

      }
      else if (action === 'reset') {

        console.log("Resetting>>>>");

        store.remove(CONSTANTS.STORE.LIKES);
        setLikeCount(0);
        updateModalMessage('Resetting', 'Wheel and come again!', true);
        dispatch({ type: 'RESET_ALL' });


      }
      else if (action === 'newgame') {

        if (event.game) {

          const message = {
            big: 'New Game',
            small: 'Starting a new game...',
            show: true
          }
          

          dispatch({ type: 'NEW_GAME', message, game: event.game, gamename: event.gamename });

             
              store.set(CONSTANTS.STORE.PARTICIPANT_GAME, event.game);
              store.set(CONSTANTS.STORE.PARTICIPANT_GAME_NAME, event.gamename);
        }


      }
      else if (action === 'winner') {

        setIsWinner(true);

        setTickerMessage(`The winner is ${event.participantName} ++`);
        // setMoveTickerTape(false);

        if (event.game && event.participant !== participant) {
          updateModalMessage(BIG_ERROR_COPY, `${event.participantName} won this game, try again next time`, true);
        }

      }
      else if (action === 'hint'){

              setDisableHints(!event.enabled);
              lastPlayed.current = WHATS_PLAYING_COPY;
              setShowLastPlayed(false);       

      }
      else if(action === 'gamestatus' ){


        updateGameStatus(setTickerMessage, setMoveTickerTape, gameStatusMessages, event);

      }
      else if(action === 'fiya' ){

        addLikes();

      }

    }




  }, [participant]);




  useWebsocketSubscription(channels, messageAction);

  //TODO: need to use this to send messages
  const sendWebsocketMessage = useWebsocketPublish();




  useEffect(() => {

    async function loadCard() {

      let requestBody = {
        body: {
          game,
          user,
          participant

        }
      };

      let card = await API.post('openapi', '/participant/card', requestBody);

      if (!store.get(CONSTANTS.STORE.PARTICIPANT_ID)) {
        store.set(CONSTANTS.STORE.PARTICIPANT_ID, `${card.participant}`);
      }

      return card;


    }


    async function onLoad() {


      try {

        dispatch({ type: 'LOADING_CARD' });
        const cardResponse = await loadCard();

        if (cardResponse.card) {
          dispatch({ type: 'CARD_LOADED', card: cardResponse.card });
          

          if(cardResponse.hintenabled){
           
              setDisableHints(cardResponse.hintenabled === 'true' ? false : true);
          }
          else{
           
            setDisableHints(false);
          }
          
        }

      } catch (error) {

        if(error.response && error.response.status === 402){

          dispatch({ type: 'UPGRADE_REQUIRED', error });
        } 
        else if (error.response && error.response.status === 404){

             store.set(CONSTANTS.STORE.CARD, []);
             dispatch({ type: 'LOADING_CARD_ERROR', error });

        }
        else{
          console.log(error);

          try{

            console.log('Trying again to get card');

            const cardResponse = await loadCard();

              if (cardResponse.card) {
                dispatch({ type: 'CARD_LOADED', card: cardResponse.card });
                

                if(cardResponse.hintenabled){
                
                    setDisableHints(cardResponse.hintenabled === 'true' ? false : true);
                }
                else{
                
                  setDisableHints(false);
                }
                
              }
            }
            catch(e){
              dispatch({ type: 'LOADING_CARD_ERROR', e });
            }

          
        }
      }
    }

    onLoad();

    //TODO: this clean up is not working calling onload multiple times
    // return () => setReset(false);
    return () => {
      dispatch({ type: 'RESET' });
      store.remove(CONSTANTS.STORE.LIKES);
      setLikeCount(0);
    } 

  }, [reset, game, participant, user]);



  function handleModalClose() {

    dispatch({ type: 'RESET_MESSAGE' });

  }

  function updateModalMessage(big, small, show) {

    const message = {
      big,
      small,
      show
    };

    dispatch({ type: 'UPDATE_MESSAGE', message });

  }


  async function checkBingo(songs) {

    //TODO: send message if someone has nearly got Binogo
    // sendWebsocketMessage("blah1", channels[1]);

    let requestBody = {
      body: {
        game,
        user,
        participant,
        participantName,
        songs

      }
    };

    try{

          let bingo = await API.post('openapi', '/participant/bingo', requestBody);

          updateModalMessage('', '', false);

          if (bingo.bingo) {

            updateModalMessage(BINGO_BIG_COPY, BINGO_SMALL_COPY, true);
            dispatch({ type: 'BINGO' });
            trackEvent('Winner');
          }
          else {

            updateModalMessage(BIG_ERROR_COPY, bingo.message, true);

          }
    }
    catch(e){
   
      console.log("Bingo Error", e);

      try{

      
         let bingo = await API.post('openapi', '/participant/bingo', requestBody);

          updateModalMessage('', '', false);

          if (bingo.bingo) {

            updateModalMessage(BINGO_BIG_COPY, BINGO_SMALL_COPY, true);
            dispatch({ type: 'BINGO' });
          }
          else {

            updateModalMessage(BIG_ERROR_COPY, bingo.message, true);

          }
        }
        catch(error){
          updateModalMessage(BIG_WRONG_COPY, SMALL_WRONG_COPY, true);
        }
      
    }

  }



  async function handleSelect(song) {

    let songs = store.get(CONSTANTS.STORE.PARTICIPANT_SONGS);

    if (!songs) {
      songs = [];
    }

    if (songs.includes(song)) {
      songs = songs.filter(item => item !== song);
    }
    else {
      songs.push(song);


          if(songs.length !== CARD_SIZE && (songs.length % 2 === 0 || songs.length >= CARD_SIZE - 4)){

            sendWebsocketMessage({action:'gamestatus', 
            name: participantName,
            count: songs.length,
            id: participant
          
          }, channels[1]);
          }
    }

    store.set(CONSTANTS.STORE.PARTICIPANT_SONGS, songs);

    dispatch({ type: 'ADD_SONG', song });

    

    if (songs.length === CARD_SIZE) {

      updateModalMessage('WOAH!', 'We are checking if you have really won.', true);
      checkBingo(songs);
    }

  }

  function isSelected(songId, asString) {
    let songs = store.get(CONSTANTS.STORE.PARTICIPANT_SONGS);

    if (songs && songs.includes(songId)) {

      if (asString) {
        return 'true';
      }

      return true;
    } else {
      if (asString) {
        return 'false';
      }
      return false;
    }
  }

  function showSelected() {

    if (selectImageURL && selectImageURL !== '') {

      return (
        // <img alt='selected' src={selectImageURL} className="tick custom" />
        <div className="tick custom" style={{ backgroundImage: `url("${selectImageURL}")` }}></div>
      );
    }
    else {
      return (
        <FaCheckCircle className="tick" />
      );
    }

  }

  async function handleCurrentGameAction(){

    try{
            setShowLastPlayed(false);
            setShowReloadRoad(true);
            const game = await API.get('openapi', `/participant/currentgame/${user}`);


            setShowReloadRoad(false);

            if(game.game !== gameid){

              const message = {
                big: 'New Game',
                small: 'Loading the latest game...',
                show: true
              }

            

            dispatch({ type: 'NEW_GAME', message, game: game.game, gamename: game.name });

            store.set(CONSTANTS.STORE.PARTICIPANT_GAME, game.game);
            store.set(CONSTANTS.STORE.PARTICIPANT_GAME_NAME, game.name);         
            
            console.log('Game ', game);
            }
 
    }
    catch(e){
      console.log(e);
      setShowReloadRoad(false);
    }

   

  }

  function handleUserAction (){

        if(fireCount === 0){
          setFireVariant('success');
        }
        else if(fireCount === 1){
          setFireVariant('warning');
        }
        else if(fireCount === 2){
          setFireVariant('danger');
        }

        setFireCount(fireCount + 1);

        if(fireCount < 4){
  
          sendWebsocketMessage({action:'fiya'}, channels[2]);
          sendWebsocketMessage({action:'fiya'}, channels[1]);
          setLikeCount(fireCount + 1 * 2);
        }

        

        
        
  }

  return (
    <div id="bingo" className={`content-wrap un-auth ${themeShade === 'light' ? 'light-theme' : 'dark-theme'}`} style={themeStyle}>

      <Container fluid>
      <Likes count={likeCount} speed={likeSpeed} />

        <Row className="justify-content-md-center">
          <Col md="6" className="justify-content-md-center text-center">
            <h1>Musingo Bingo</h1>

            {
              isLoading && <p>Loading...</p>
            }

            {card.length > 0 &&
              <>
                <p>If you hear one of the songs on your card tap the square, if you make a mistake tap again to unselect. </p>
                {/* <p>Have a song, <strong>THUMBS UP</strong>. No song, <strong>THUMBS DOWN</strong>. Like the song, <strong>DANCE</strong>. Get Bingo, <strong>GO CRAZY!!</strong></p> */}
            
            <p>{bingoMessage}</p>

            <Button  onClick={handleUserAction} aria-label="select" size="lg" variant={fireVariant}  >
               <FaHeart/>     
               </Button>{' '}

               {/* <TwitterHeart onHeartClick={() => console.log("im clicked")} />{' '} */}


               

       <Button ref={target}  onClick={()=> {setShowLastPlayed(!showLastPlayed) }} aria-label="select" size="lg" variant="link" disabled={disableHints}>
           <FaMusic/>
       </Button>{' '}
       <Overlay target={target.current} show={showLastPlayed} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {lastPlayed.current}
          </Tooltip>
        )}
      </Overlay>
      <Button ref={currentGameTarget} onClick={handleCurrentGameAction} aria-label="select" size="lg"  variant='link'>
               <FaRedoAlt/>     
      </Button>{' '}
      <Overlay target={currentGameTarget.current} show={showReloadRoad} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Load Latest Round
          </Tooltip>
        )}
      </Overlay>
      

        {/* { moveTickerTape && !isWinner && <TickerTape moveTickerTape={moveTickerTape} tickerMessage={tickerMessage} /> } */}

        { moveTickerTape &&  <TickerTape moveTickerTape={moveTickerTape} tickerMessage={tickerMessage} /> }

       {/* <Button  aria-label="select" size="sm" variant="link">
           Invite player
       </Button>{' '} */}

                <h4>Round: {gameName}</h4>
              </>
            }

            <Row className="card-wrap">



              {card.length > 0 &&

                card.map(
                  (song, i) =>
                    <Col key={i} xs="6" md="4" lg="3" className="d-flex align-items-stretch">
                      <div className="d-flex align-items-stretch">
                        <Button className="btn-block d-flex flex-column justify-content-between" onClick={() => handleSelect(song.id)} aria-label="select" >
                          {/* <img alt={song.name} src={song.image ? song.image : `https://img.youtube.com/vi/${song.id}/maxresdefault.jpg`} className={isSelected(song.id, true) && "selected"} /> */}
                          {/* <img alt={song.name} src={song.images[1] ? song.images[1].url : `https://img.youtube.com/vi/${song.id}/0.jpg`} className={isSelected(song.id, true) && "selected"} /> */}
                        
                          {/* <img alt={song.name} src={song.images[1].url } className={isSelected(song.id, true) && "selected"} /> */}

                          {/* <span>{song.name}</span> */}
                          <div className={`ytImage-bg-lg ${isSelected(song.id, true) && "selected"}`} style={{ backgroundImage: `url("${song.image ? song.image : `https://img.youtube.com/vi/${song.id}/maxresdefault.jpg`}")` }}></div>


                          <span>{limitText(song.name)}</span>

                          {isSelected(song.id) &&
                            showSelected()
                          }
                        </Button>
                      </div>
                    </Col>

                )
              }
            </Row>


            {
              (isError || (!isLoading && card.length === 0)) &&
              <Alert key="no_sessions" variant="success">
                There are no active games. Please speak to the person who invited you.
               </Alert>

            }

            <Modal show={showModalMessage} onHide={handleModalClose}>
              <Modal.Header closeButton>
                Musingobingo.com
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h1>{modalMessageBig}</h1>
                    <p>{modalMessage}</p>
                    <Button variant="primary" onClick={handleModalClose}>Close</Button>
                  </Col>
                  <Col className="d-flex align-items-end">
                  <img alt="modalImage" src={modalImage} /> 
                    
                    
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>

            {/* test */}
            <div>Having fun? Find out more about Musingo.
                <br />
              <br />
              <div className="text-center">
                <FacebookShareButton url={config.bingo.URL} ><FacebookIcon size={32} round /> </FacebookShareButton>{' '}
                <WhatsappShareButton url={config.bingo.URL}><WhatsappIcon size={32} round /> </WhatsappShareButton >{' '}
                <EmailShareButton url={config.bingo.URL}><EmailIcon size={32} round /> </EmailShareButton >{' '}
              </div>
              or
              <br />

              <Link to="/signup" className="btn btn-link">Sign up!</Link>
            </div>

            {/* end test */}



          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Bingo;