import React from "react";
import { Row, Col, Container, Table, Card} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";


// import { imageCheck } from "../libs/util";




export default function About() {

const { isAuthenticated } = useAppContext();
  


  return (
    <div id="about" className="content-wrap">
      {!isAuthenticated &&
       <Link to="/" >
            <img alt="logo" src="/images/logo.png" className="App-logo" />
            </Link>
          }

      <Container fluid>

        <Row className="justify-content-center">
          <Col xs md="6" className="justify-content-center text-center">
            <h1>About</h1>


            {/* TODO: move this to a better place */}
            <h3>What is Musingo Bingo?</h3>
            <p>We are your ultimate online music bingo game created to bring your virtual parties and events to life. Whether it’s 90s Brit Pop, Prince’s greatest hits, grime or Disney theme tunes, it’s all about playing your sounds, your way and bringing everyone together under a virtual roof. All you need is a Spotify account, Zoom, your phone and a laptop.</p>
            
            <h3>How do I play Musingo Bingo?</h3>
            <p>Head to the Support page and watch our handy videos  <Link to="/help" >
            HERE
            </Link></p>

            <h3>Can I customise my Musingo Party experience?</h3>
            <p>100% yes, your Musingo Bingo experience is all about you. You can personalise your invitation, bingo game theme and header, as well as your bingo card tiles with anything from your company logo to pictures of you.</p>

            <h3>How do I add friends to my Musingo Bingo party?</h3>
            <p>Tap the Invite Friends button and copy the link to send to your friends. Once your games have been created, they will be allocated a bingo card. </p>

            <h3>I want to say “hi” or collaborate, how can I get in touch?</h3>
            <p>Well hello, we’d love to hear from you, drop us an email at <a href="mailto:hello@musicbingo.com">hello@musicbingo.com</a>  or you can connect with us on Instagram and Facebook.</p>


            <h3>How much does it cost?</h3>

            <Card>

<Table striped bordered hover>
              <thead>
                <tr>
                  <th>Tier</th>
                  <th>Games</th>
                  <th>Players</th>
                  {/* <th>Theme</th> */}
                  {/* <th>Support</th> */}
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>


              
                    <tr >
                      <td>
                       <strong>Free </strong>
                      </td>
                      <td>
                        1
                      </td>
                      <td>
                        5
                      </td>
                      {/* <td>
                        No
                      </td> */}
                      {/* <td>
                        No
                      </td> */}
                      <td>
                        Free
                      </td>
                    </tr>

                    <tr >
                      <td>
                      <strong>Bronze</strong>
                      </td>
                      <td>
                        2
                      </td>
                      <td>
                        15
                      </td>
                      {/* <td>
                        Yes
                      </td> */}
                      {/* <td>
                        No
                      </td> */}
                      <td>
                        £20
                      </td>
                    </tr>

                    <tr >
                      <td>
                      <strong>Silver</strong>
                      </td>
                      <td>
                        4
                      </td>
                      <td>
                        30
                      </td>
                      {/* <td>
                        Yes
                      </td> */}
                      {/* <td>
                        No
                      </td> */}
                      <td>
                        £40
                      </td>
                    </tr>

                    <tr >
                      <td>
                      <strong>Gold</strong>
                      </td>
                      <td>
                        6
                      </td>
                      <td>
                        45
                      </td>
                      {/* <td>
                        Yes
                      </td> */}
                      {/* <td>
                        No
                      </td> */}
                      <td>
                        £60
                      </td>
                    </tr>

                    <tr >
                      <td>
                      <strong>Enterprise</strong>
                      </td>
                      <td>
                        20
                      </td>
                      <td>
                        300
                      </td>
                      {/* <td>
                        Yes
                      </td> */}
                      {/* <td>
                        Yes
                      </td> */}
                      <td>
                        Contact Us
                      </td>
                    </tr>
                    </tbody>
            </Table>   

      </Card> 
          

          </Col>
        </Row>
      </Container>
    </div>
  );
}