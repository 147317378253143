import React, {useState, useEffect, useCallback } from "react";
import { Row, Col, Container, Form, Alert, Button, Modal } from "react-bootstrap";
import { useFormFields, useLoaded } from "../../libs/hooksLib";
import LoaderButton from "../../components/LoaderButton";
import { addplaylistManual } from "../../libs/apiLib";
import { limitText } from "../../libs/util";
import CONSTANTS from '../../libs/constants';
import store from 'store';
import { useHistory } from 'react-router-dom';



const STAGE_GET_MUSINGO_PLAYLISTS = 'get_musingo_playlists';
const STAGE_ADD_GAME = 'add_game';


export default function MusingoPlaylist() {


  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [messageVariant, setMessageVariant] = useState('info');
  const [playlistName, setPlaylistName] = useState('');
  const [playlistType, setPlaylistType] = useState('');
  const [tracks, setTracks] = useState([]);
  // const [playlistDetails, setPlaylistDetails] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [editTrack, setEditTrack] = useState({});
  const [editTrackIndex, setEditTrackIndex] = useState(-1);
  // const [addAsOwner, setAddAsOwner] =  useState(false);
  const [playlistid] = useState(store.get(CONSTANTS.STORE.PLAYLIST) ? store.get(CONSTANTS.STORE.PLAYLIST) : '');
  const [selectedFields, setSelectedFields] = useState(null);

 

  const history = useHistory();

  const loaded = useCallback(useLoaded('musingoplaylistOne', setIsLoading, playlistid));

  useEffect(() => {

    if (loaded.error) {

      if (loaded.error.status !== 404) {

        setShowMessage(true);
        setMessageText(loaded.error.message);
        setMessageVariant('danger');
      }


    }
    else if(loaded.data) {
   
      setTracks(loaded.data.tracks);
      setPlaylistName(loaded.data.name);
      setPlaylistType(loaded.data.type);
      setSelectedFields(loaded.data);
      setShowMessage(false);
    
    }


  }, [loaded]);
  


  const [fields, handleFieldChange] = useFormFields({
    playlisturl: '',
    editableName: ''
   

  });

  const handleErroClose = () => {
    setShowMessage(false);
  }


  async function handleAddPlaylist(){

    setIsLoadingAdd(true);

    if(tracks && tracks.length > 0){

      let playlistDetails = {

        image: selectedFields.image,
        images: selectedFields.images,
        name: playlistName,
        playlist: playlistid,
        tracks,
        type: 'youtube'

      };

      try{


      await addplaylistManual(playlistDetails);

      setShowMessage(true);
      setMessageText('Playlist Updated');
      setMessageVariant('success');

      store.set(CONSTANTS.STORE.WIZARD_STAGE, STAGE_ADD_GAME);

      history.go(0);


      }
      catch(e){

        console.log('error', e);

        setShowMessage(true);
        setMessageText('Something went wrong');
        setMessageVariant('danger');

      }

    }

    setIsLoadingAdd(false);

  }


  async function handleEditName(event){

    event.preventDefault();

    let updatedTracks = [...tracks];

    updatedTracks[editTrackIndex].name = fields.editableName;

    setTracks(updatedTracks);

    fields.editableName = '';

    setShowModalEdit(false);

  }



async function handleTrackEdit(track, index){

  setEditTrack(track);
  setEditTrackIndex(index);

  setShowModalEdit(true);

}

async function handleDeleteTrack(event){

  event.preventDefault();

   let updatedTracks = [...tracks];

   updatedTracks.splice(editTrackIndex, 1);

   setTracks(updatedTracks);

   setShowModalEdit(false);

}

function handleModalEditClose() {

  setShowModalEdit(false);
  setEditTrack({});
  fields.editableName = '';
}

function handleBack(event){

  event.preventDefault();

  store.remove(CONSTANTS.STORE.PLAYLIST);

    store.set(CONSTANTS.STORE.WIZARD_STAGE, STAGE_GET_MUSINGO_PLAYLISTS);

    history.go(0);


}

  return (
    <div id="bingo" className="content-wrap">

      <Container fluid>

        <Row className="justify-content-center">
          <Col xs md="6" className="justify-content-center text-center">
            <h1>Setup Wizard (3b)</h1>

            <br/>

            {
              isLoading && <p>Loading...</p>
            }

            <h2>{playlistName}</h2>
            {/* <br/>
            <h2>{playlistType}</h2> */}

          
      
          <Row className="card-wrap">

            {tracks.length > 0 &&

                  tracks.map(
                    (track, i) =>
                      <Col key={i} xs="6" md="4" lg="3" className="d-flex align-items-stretch">
                        <div className="d-flex align-items-stretch">
                        {/* <Button  > */}
                          {/* <Button onClick={() => handleTrackEdit(track, i)} disabled={playlistType !== 'youtube'}  > */}
                          <Button onClick={() => handleTrackEdit(track, i)} disabled="true"  >


                            <img alt={track.name} src={track.images[0].url ? track.images[0].url : track.images[0]}   />
                            <span>{ track.name ? limitText(track.name) : 'No Name' }</span> {' '}
                                                        
                          </Button>
                          
                        </div>
                      </Col>

                )
                }

          </Row>

         </Col>
        </Row>


   <div className='justify-content-md-center text-center'>
           
           { tracks.length > 0 &&  
           
           <>
           <Button variant="success" onClick={handleBack} size="lg" className="spotify">
                 
                 Back
                </Button>{' '}

                <Button variant="success" onClick={handleAddPlaylist} size="lg" className="spotify">
                 
                 Next
                </Button>{' '}

            </>
               
               
               }
            
           </div>

        

 


 

   <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                {messageText}
              </Alert>

  { editTrack.images && <Modal show={showModalEdit} onHide={handleModalEditClose}>
  <Modal.Header closeButton>
                Musingobingo.com
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xs={12}>
                  <img alt={editTrack.name} src={editTrack.id ? editTrack.images[0] : editTrack.images[0]}   />
                    <br/>
                    <br/>
                    <p>{limitText(editTrack.name, false, false)}</p>
                    <Form onSubmit={handleEditName}>
                    <Form.Group controlId="editableName">
                        <Form.Control
                            autoFocus
                            type="text"
                            placeholder="Update name"
                            aria-label="Playlist Name"
                            value={fields.editableName}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                 

                  <Row>
                    <Col xs={6} className="d-flex align-items-end">
                    <LoaderButton
                      className="pull-right"
                      block
                      type="submit"
                      isLoading={isLoading}
                      text="Update"
                      loadingText="Updating..."
                      
                    />
                    </Col>

                    <Col xs={6} className="d-flex align-items-end">
                      <Button  aria-label="select" block onClick={handleDeleteTrack} >Delete</Button>
                    </Col>
                  </Row>
              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                {messageText}
              </Alert>
            </Form>
                    {/* <Button variant="primary" onClick={handleModalEditClose}>Close</Button> */}
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>
            
            }

      </Container>
    </div>
  );
}