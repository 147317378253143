import CONSTANTS from '../libs/constants';

const logos = ['blue', 'orange', 'red', 'purple'];


export function getRandomColouredLogo() {

  const colour = logos[Math.floor(Math.random() * logos.length)];

  return `logo-${colour}.png`;


}

function replaceBetween(origin, startIndex, endIndex, insertion) {
  return origin.substring(0, startIndex) + insertion + origin.substring(endIndex);
}

export function limitText(str, yt, limitLenght = true) {

  if (!str) {
    return '';
  }

  const ovIndex = str.toLowerCase().indexOf('official video');

  if (ovIndex >= 0) {

    str = replaceBetween(str, ovIndex, ovIndex + 14, '');

  }

  const oaIndex = str.toLowerCase().indexOf('official audio');

  if (oaIndex >= 0) {

    str = replaceBetween(str, oaIndex, oaIndex + 14, '');

  }

  const omvIndex = str.toLowerCase().indexOf('official music video');

  if (omvIndex >= 0) {

    str = replaceBetween(str, omvIndex, omvIndex + 20, '');

  }



  // change to regex
  str = str.replace('(', '').replace(')', '').replace('[', '').replace(']', '');

  if (yt) {

    let index = str.indexOf('-');

    if (index >= 0) {

      str = str.substring(index + 1);

    }

    let index2 = str.indexOf('(');
    let index3 = str.indexOf(')')

    if (index2 >= 0 && index3 >= 0) {

      let subStr = str.substring(index2, index3 + 1);

      if (subStr && subStr.toLowerCase().includes('official')) {

        str = str.replace(subStr, '');
      }


    }
  }



  if (str.length > CONSTANTS.LABELS.LIMIT && limitLenght) {

    return `${str.substring(0, CONSTANTS.LABELS.LIMIT)}...`;
  }

  return str;

}


export function gameStatusArrayToString(gameStatusArray) {

  let statuString = '';

  if (!gameStatusArray || gameStatusArray.length === 0) {
    return statuString;
  }

  for (let status of gameStatusArray) {

    if (status.name) {

      statuString += ` ${status.name} has ${status.count} ++`;
    }
  }

  return statuString;



}

export function updateGameStatus(setTickerMessage, setMoveTickerTape, gameStatusMessages, event) {

  let existsIndex = gameStatusMessages.findIndex((status) => status.id === event.id);

  if (existsIndex >= 0) {
    gameStatusMessages.splice(existsIndex, 1);
  }

  gameStatusMessages.push(event);

  if (gameStatusMessages.length > CONSTANTS.MESSAGE.GAMESTATUS_LENGHT) {
    gameStatusMessages.shift();
  }

  setTickerMessage(gameStatusArrayToString(gameStatusMessages));
  setMoveTickerTape(true);
}


export function youtube_parser(url) {
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
  // const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = url.match(regExp);
  const foundMatch = (match && match[1].length === 11) ? match[1] : false;

  if (foundMatch) {
    return foundMatch;
  } else {

    let video_id = url.split('v=')[1];

    if (video_id) {

      let ampersandPosition = video_id.indexOf('&');
      if (ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }

      return video_id;


    }
    else{
      return url;
    }


  }
}

export function youtube_parser_playlist(url) {
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|playlist\?list=)([^#\&\?]*).*/;
  // const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = url.match(regExp);
  const foundMatch = (match && match[1].length === 34) ? match[1] : false;

  if (foundMatch) {
    return foundMatch;
  } else {

    let playlist_id = url.split('list=')[1];

    if (playlist_id) {
      let ampersandPosition = playlist_id.indexOf('&');
      if (ampersandPosition !== -1) {
        playlist_id = playlist_id.substring(0, ampersandPosition);
      }

    } else {
      return url;
    }



    return playlist_id;

  }
}

export function getYouTubeImage(id, imgArr) {

  if (imgArr.includes(id)) {
    return `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
  } else {
    return `https://img.youtube.com/vi/${id}/0.jpg`
  }

}

export function convertArrayToObject(array, key) {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

//https://stackoverflow.com/questions/8903854/check-image-width-and-height-before-upload-with-javascript
export async function imageCheck(file, options) {

  if (!file) {
    return {
      error: 'invalid'
    }
  }

  if (file.type && CONSTANTS.IMAGES && !CONSTANTS.IMAGES.TYPES.includes(file.type)) {

    return {
      error: 'invalid image type'
    }
  }

  let img = new Image()
  img.src = window.URL.createObjectURL(file);
  img.onload = () => {
    alert(img.width + " " + img.height);
    return {
      valid: true,
      blah: "blahblah"
    }
  }

}