import React, { useState, useEffect, useRef } from "react";


import { loadStripe, useStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "../components/CardForm";
import config from "../config";
import { submitPaymentIntent } from "../libs/apiLib";
import { useLoaded } from '../libs/hooksLib';
import { Row, Col, Container, Button, Alert, Modal, Card } from 'react-bootstrap';
import { getRandomColouredLogo } from "../libs/util";
import { useQuery } from "../libs/hooksLib";
import CONSTANTS from '../libs/constants';


const BRONZE = 'Bronze';
const SILVER = 'Silver';
const GOLD = 'Gold';

const tierLogos = {

  Bronze: '/images/logo-orange.png',
  Silver: '/images/logo-blue.png',
  Gold: '/images/logo-yellow.png',
  Enterprise: '/images/logo-red.png'

}


// const priceMap = {};

// priceMap[BRONZE] = 2055;
// priceMap[SILVER] = 4000;
// priceMap[GOLD] = 6000;
// priceMap[ENTERPRISE] = 30000;



const stripePromise = loadStripe(config.stripe.STRIPE_KEY);




export default function Payment() {

  const query = useQuery();

  



  const [isLoading, setIsLoading] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);
  const [selectedTierLogo, setSelectedTierLogo] = useState(null);
  const [tiers, setTiers] = useState([]);
  const [price, setPrice] = useState(null);
  const [currency, setCurrency] = useState('gbp');
  const [clientSecret, setClientSecret] = useState(null);

  const [messageVariant, setMessageVariant] = useState('info');
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState("");

  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalMessageBig, setModalMessageBig] = useState('');
  const [modalMessage, setModalMessage] = useState('');

  // const elements = useElements();

  const loaded = useLoaded('tier', setIsLoading);

  const cardRef = useRef();


  useEffect(() => {


    if (loaded.error) {
      console.log(loaded.error);

      // if(loaded.error.status === 404 && !modalHasBeenClosed){
      //   setshowInitialModal(true);
      // }

      // history.push('theme');
      // setShowMessage(true);
      // setMessageText(loaded.error);
      // setMessageVariant('danger');
    }
    else if (loaded.data) {

      delete loaded.data.free;

      setTiers(Object.values(loaded.data));

      if (query.get(CONSTANTS.QUERY.BUY_TIER)) {

        const urlTier = query.get(CONSTANTS.QUERY.BUY_TIER);
        
        if(urlTier === BRONZE || urlTier === SILVER || urlTier === GOLD ){

          const selectedUrlTier = Object.values(loaded.data).find(e => e.name === urlTier);

          if(selectedUrlTier){

            setSelectedTier(selectedUrlTier.name);
            setPrice(selectedUrlTier.price);
            
          }

          
        }

      }

    }

   

  }, [loaded.dataLoaded]);



  const handleFormSubmit = async (result) => {

  
    setIsLoading(true);

    if (result.error) {
      //TODO: show the error to the user
      console.log(result.error);
      setIsLoading(false);
      setMessageVariant('danger');
      setMessageText(result.error.message);
      setShowMessage(true);
    }
    else {

        cardRef.current.clearCard();
        setModalMessageBig('Thank you')
        setModalMessage(`You have just purchased the ${selectedTier} tier`);
        setShowModalMessage(true);
        setIsLoading(false);
        setSelectedTier(null);

    }


  }

  const handleErroClose = () => {
    setShowMessage(false);
  }

  const handleModalClose = () => {
    setShowModalMessage(false);
  }



  const handleTierClick = async (tier) => {


    if(isNaN(tier.price)){


      setModalMessageBig('Contact us at')
      setModalMessage('hello@musingobingo.com');
      setShowModalMessage(true);
      setSelectedTier(null);

    }
    else{
      setSelectedTier(tier.name);
      setSelectedTierLogo(tier.name);
  
      setPrice(tier.price);
  
    

    const charge = {
      amount: price,
      currency: currency,
      description: `Musingo - ${tier.name} tier`,
      metadata: {
        tier: tier.name
      },
    }

    try {

      setShowMessage(false);
      const response = await submitPaymentIntent(charge);
     
      setClientSecret(response.client_secret);

    }
    catch (e) {
      console.log(e.response);
      setIsLoading(false);
      setMessageVariant('danger');
      setMessageText('There was an error processing your card');
      setShowMessage(true);
    }
  }

   

  }

  const creditCardForm = () => {

    return (
      <Elements stripe={stripePromise}>

        <CardForm ref={cardRef} isLoading={isLoading} setIsLoading={setIsLoading} onSubmit={handleFormSubmit} clientSecret={clientSecret} />

      </Elements>
    )
  }

  const capitalize = (str) => {

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const cost = () => {

    return (



      <h1><strong>{capitalize(selectedTier)}:</strong> £{price / 100} </h1>

    );
  }



  return (

    <div id='bingo' className='content-wrap'>

      <Container fluid>

        <Row className='justify-content-md-center'>
          <Col xs md='6' className='justify-content-md-center text-center'>
            <h1>Buy Now</h1>

            {
              isLoading && <p>Loading...</p>
            }

            <Row className='card-wrap'>
              {tiers &&

                tiers.map(
                  (tier, i) =>

                    <Col key={i} xs='6' md='4' lg='6' className='d-flex align-items-stretch'>
                      <div className='d-flex align-items-stretch'>

                        <Card >

                          <h2>{tier.name}</h2>

                          <br />

                          <img alt='new game' src={tierLogos[tier.name]} />
                          <br />

                          <span>Rounds: <b>{tier.games}</b></span>
                          <span>Players: <b>{tier.participants}</b></span>
                          <span>Theme: <b>{tier.theme ? 'yes' : 'no'}</b></span>
                          <span>Support: <b>{tier.support}</b></span>
                          <br />
                          <span>Price: <b>{isNaN(tier.price) ? tier.price : `£${tier.price / 100}`}</b></span>
                          <br />
                          {selectedTier === tier.name ?
                            <Button onClick={() => { handleTierClick(tier) } } className="active">Selected</Button>
                            :
                            <Button onClick={() => { handleTierClick(tier) }}>{isNaN(tier.price) ?  `Contact Us` : 'Buy'}</Button>
                          }
                        </Card>
                      </div>
                    </Col>

                )
              }


            </Row>

          </Col>
        </Row>
        <Row className='justify-content-md-center'>


          {selectedTier && cost()}

        </Row>

        <Row className='justify-content-md-center'>
          <Col xs md='6' className='justify-content-md-center text-center'>

            {selectedTier && creditCardForm()}

            <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible='true' variant={messageVariant} onClose={handleErroClose}>
              <p> {messageText} </p> </Alert>

          </Col>

        </Row>

        {/* <Row>
          <Col xs md='6' className='justify-content-md-center text-center'>

            <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible='true' variant={messageVariant} onClose={handleErroClose}>
              <p> {messageText} </p> </Alert>


          </Col>

        </Row> */}

      </Container>

      <Modal show={showModalMessage} onHide={handleModalClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h1>{modalMessageBig}</h1>
              <p>{modalMessage}</p>
              <Button variant="primary" onClick={handleModalClose}>Close</Button>
            </Col>
            <Col className="d-flex align-items-end">
              <img alt="modalImage" src={tierLogos[selectedTierLogo] ?  tierLogos[selectedTierLogo] : `/images/${getRandomColouredLogo()}`} />
            </Col>
          </Row>

        </Modal.Body>
      </Modal>





    </div>
  )



}