const values = Object.freeze({
    STORE: {
        SPOTIFY_ACCESS_TOKEN: 'spotify_access_token',
        SPOTIFY_ACCESS_TOKEN_EXPIRY: 'spotify_access_token_expiry',
        CURRENT_GAME: 'current_game',
        MUSINGO_USER: 'auth_user',
        THEMES: 'themes',
        PARTICIPANT_USER:'participant_user',
        PARTICIPANT_NAME:'participant_name',
        PARTICIPANT_GAME:'participant_game',
        PARTICIPANT_SONGS:'participant_songs',
        PARTICIPANT_GAME_NAME:'participant_game_name', 
        PARTICIPANT_ID:'participant_id',
        INFO_MODAL_COUNT: 'info_modal_count',
        PLAYER_ERROR_COUNT:'player_error_count',
        GAMES: 'games',
        NEW_GAME_NAME:'new_game_name',
        PLAYLISTS: 'playlists',
        PLAYLIST: 'playlist',
        CARD: 'card',
        FIRE: 'fire',
        FIRE_COUNT: 'fireCount',
        PLAYED: 'played',
        DEVICE_ID:'deviceid',
        TRACKS_PLAYED: 'lasttrackplayed',
        SOUND_CONNECT : 'soundConnect',
        WIZARD_STAGE: 'wizard_stage',
        LIKES:'likes',
        PLAYLIST_INDEX: 'playlist_index'
        
        

    },
    PLAYER: {
        NAME: 'Musingo Player',
        SEEK_WINDOW: 10,
        MAX_ERRORS: 5,
        PREMIUM: 'premium',
        MAX_LIKES: 150,
        MIN_TRACKS_LENGTH: 15
    },
    PAGE:{
        CONTROL: 'gamecontrol',
        ADDGAME: 'addgame',
        GAMES: 'games',
        SPOTIFYPLAYLISTS: 'spotifyplaylists',
        SPOTIFYCONNECT:'spotifyconnect',
        ADD_YOUTUBE_PLAYLIST: 'addyoutubeplaylist', 
        TRACK_SELECTOR: 'trackselect',
        BINGO: 'bingo',
        THEME: 'theme',
        BINGO_PREVIEW: 'bingopreview',
        INVITE_PREVIEW: 'invitepreview',
        BUY: 'buy',
        WIZARD: 'wizard'
    },
    IMAGES: {
        TYPES:['image/gif', 'image/jpeg', 'image/png']
    },
    THEME:{
        SHADE:{
            LIGHT: 'light',
            DARK: 'dark'
        }
    },
    TIER: {
        FREE: 'free',
        BRONZE: 'bronze',
        SILVER: 'silver',
        GOLD: 'gold',
        ENTERPRISE: 'enterprise'
    },
    MESSAGE:{
        UPGRADE: 'Upgrade Required',
        GAMESTATUS_LENGHT: 3
    },
    LABELS:{
        LIMIT:25
    },
    URLS:{
        SHARE:'https://musingobingo.com'
    },
    QUERY:{
        RETURN_PAGE: 'returnpage',
        SPOTIFY_ACCESS_TOKEN: 'spotify_access_token',
        BUY_TIER: 'tier',
        OWNER: 'owner'
    },
    WEBSOCKET:{
        CHANNEL:{
            ADMIN:'admin'
        }
    },
    USER:{
        MUSINGO: 'musingobingo'
    }
});

module.exports = values;