import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Container, Col, Alert, Button } from "react-bootstrap";

import { useLoaded } from "../libs/hooksLib";

import CONSTANTS from '../libs/constants';

import { FaCheckCircle, FaPlus, FaYoutube, FaSpotify } from 'react-icons/fa';
import store from 'store';
import { limitText } from "../libs/util";




import YTImageSquare from "../components/YTImageSquare";



export default function Playlists() {
  const [isLoading, setIsLoading] = useState(false);
 
  const [selectedPlaylist] = useState(null);
  const [playlists, setPlaylists] = useState(store.get(CONSTANTS.STORE.PLAYLISTS) ? store.get(CONSTANTS.STORE.PLAYLISTS) : []);


  const [messageVariant, setMessageVariant] = useState('info');
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const history = useHistory();


  const loaded = useLoaded('playlist', setIsLoading);


  useEffect(() => {

    let isCurrent = true;

    if (loaded.error) {

      if (loaded.error.status !== 404) {

        if (isCurrent) {
        setShowMessage(true);
        setMessageText(loaded.error.message);
        setMessageVariant('danger');
        }
       
      }
      else{

        if (isCurrent) {
        setPlaylists(null);
        store.set(CONSTANTS.STORE.PLAYLISTS, []);
        }
      }


    }
    else if(loaded.data) {

      if (isCurrent) {
      setPlaylists(loaded.data);
      store.set(CONSTANTS.STORE.PLAYLISTS, loaded.data);
      // playlists = loaded.data;
      }

    }

    return () => {
      isCurrent = false;
    }


  }, [loaded]);


  const handleErroClose = () => {
    setShowMessage(false);
  }

  const handleAddPlaylist = () => {

    history.push('/spotifyplaylists');

  }

  const handleAddYouTubePlaylist = () => {

    history.push('/addyoutubeplaylist');

  } 
  

  const handleSelectlaylist = (playlist) => {

    store.set(CONSTANTS.STORE.PLAYLIST, playlist.playlist);

    history.push('/playlist');
    
  }

   

  const renderPlaylists = () => {
    return (
      <div id='bingo' className='content-wrap'>

        <Container fluid>

          <Row className='justify-content-md-center'>
            <Col xs md='6' className='justify-content-md-center text-center'>
            <h1>Playlists</h1>

                {
                          isLoading && <p>Loading...</p>
                    }


                <Row className='card-wrap'>

                <Col xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                    <div className='d-flex align-items-stretch'>

                      <Button onClick={handleAddPlaylist} className="card">
                        {/* <img alt='new game' src='/images/ydbb_elements-03.png' /> */}
                        <FaPlus className="plus"/>
                        <span>ADD SPOTIFY PLAYLIST  <FaSpotify /></span>
                      </Button>


                    </div>
                  </Col>

                  <Col xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                    <div className='d-flex align-items-stretch'>

                      <Button onClick={handleAddYouTubePlaylist} className="card">
                        {/* <img alt='new game' src='/images/ydbb_elements-03.png' /> */}
                        <FaPlus className="plus"/>
                        
                        <span>ADD YOUTUBE PLAYLIST <FaYoutube /> </span>
                      </Button>


                    </div>
                  </Col>

                  
                  {playlists &&
                    playlists.map(
                      (playlist, i) =>
                        <Col key={i} xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                          <div className='d-flex align-items-stretch'>

                            <Button onClick={() => handleSelectlaylist(playlist)} className="card noscroll" disabled={isLoading}>
                            {playlist.type === 'youtube' &&  <YTImageSquare images={playlist.images} defaultImage={playlist.image} /> }
                            {playlist.type !== 'youtube' &&  <img alt={playlist.name} src={playlist.image} /> }
                              <span>{playlist.name ? limitText(playlist.name) : 'No Name'}</span>
                              {playlist.type === 'youtube' && <span>YouTube <FaYoutube /> </span>  }
                              {playlist.type !== 'youtube' && <span>Spotify <FaSpotify />  </span>}
                              <span>&nbsp;({playlist.tracks.length})</span>
                              {selectedPlaylist && playlist.playlist === selectedPlaylist.playlist &&

                                <FaCheckCircle className="tick" />
                              }
                            </Button>


                          </div>
                        </Col>

                    )
                  }

                  




                </Row>

                

              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible='true' variant={messageVariant} onClose={handleErroClose}>
                <p> {messageText} </p> </Alert>


            </Col>
          </Row>
        </Container>
      </div>
    );
  }




  return (
    <>
      {renderPlaylists()}
    </>
  )
}