import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { FormGroup, FormControl, Row, Container, Col, Alert, Button, Modal } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields, useLoaded } from "../libs/hooksLib";
import { addGame } from "../libs/apiLib";
import CONSTANTS from '../libs/constants';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsPencilSquare } from 'react-icons/bs';
import { FaCheckCircle, FaPlus, FaMinus, FaYoutube, FaSpotify } from 'react-icons/fa';
import { trackEvent } from "../libs/events";
import store from 'store';
import { getRandomColouredLogo } from "../libs/util";

import config from "../config";
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon,  WhatsappShareButton, WhatsappIcon } from "react-share";
import YTImageSquare from "../components/YTImageSquare";
import { isSafari, isMobile} from "react-device-detect";

const NEW_GAME_LABEL = '';
const clipboardCopy = 'Copy link to clipboard';
const SPOTIFY = 'spotify';
const YOUTUBE = 'youtube';

export default function AddGame() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPlaylist, setSelectePlaylist] = useState(null);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [modalMessageBig, setModalMessageBig] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [playlists, setPlaylists] = useState(store.get(CONSTANTS.STORE.PLAYLISTS) ? store.get(CONSTANTS.STORE.PLAYLISTS) : []);
  const [musingoPlaylists, setMusingoPlaylists] = useState([]);
  const [showMusingoPlaylists, setShowMusingoPlaylists] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    playlist: '',
    name: store.get(CONSTANTS.STORE.NEW_GAME_NAME) ? store.get(CONSTANTS.STORE.NEW_GAME_NAME) : NEW_GAME_LABEL
  });

  const [messageVariant, setMessageVariant] = useState('info');
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [copyLinkCopy, setCopyLinkCopy] = useState(clipboardCopy);
  const history = useHistory();


  const loaded = useLoaded('playlist', setIsLoading);

  const loadedMusingo = useLoaded('musingoplaylists', setIsLoading);


  useEffect(() => {

    if (loaded.error) {

      if (loaded.error.status !== 404) {

        setShowMessage(true);
        setMessageText(loaded.error.message);
        setMessageVariant('danger');
      }


    }
    else if(loaded.data) {

      setPlaylists(loaded.data);
      store.set(CONSTANTS.STORE.PLAYLISTS, loaded.data);
      // playlists = loaded.data;

    }

    // return () => {
    //   if(store.get(CONSTANTS.STORE.NEW_GAME_NAME) ){
    //     store.remove(CONSTANTS.STORE.NEW_GAME_NAME) 
    //   }
    // }

  }, [loaded]);

  useEffect(() => {

    if (loadedMusingo.error) {

      if (loadedMusingo.error.status !== 404) {

        setShowMessage(true);
        setMessageText(loadedMusingo.error.message);
        setMessageVariant('danger');
      }


    }
    else if(loadedMusingo.data) {

      setMusingoPlaylists(loadedMusingo.data);
      // store.set(CONSTANTS.STORE.PLAYLISTS, loaded.data);
      // playlists = loaded.data;

    }

   

  }, [loadedMusingo]);



  const [gameLink, setGameLink] = useState('');

  const validateForm = () => {
    return (
      selectedPlaylist && fields.name && fields.name !== NEW_GAME_LABEL

    );
  }

  const handleErroClose = () => {
    setShowMessage(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

      if(store.get(CONSTANTS.STORE.NEW_GAME_NAME) ){
        store.remove(CONSTANTS.STORE.NEW_GAME_NAME) 
      }

    let image, images;
   

    if (selectedPlaylist && selectedPlaylist.image) {
      image = selectedPlaylist.image;
    }

    if (selectedPlaylist && selectedPlaylist.images) {
      images = selectedPlaylist.image;
    }

    // setIsLoading(true);
    setIsSubmitting(true);

    try {

      const values = {
        playlist: selectedPlaylist.playlist,
        playlistname: selectedPlaylist.name,
        name: fields.name,
        image,
        images

      };

      if(selectedPlaylist.user === CONSTANTS.USER.MUSINGO){

        values.playlistowner = selectedPlaylist.user;
      }

      if(selectedPlaylist.type){

        values.playlisttype = selectedPlaylist.type;
      }

      

      const gameData = await addGame(values);

      const gameLink = encodeURI(`${config.bingo.URL}/invite?user=${gameData.user}&game=${gameData.game}&gamename=${gameData.name}`);
      setGameLink(gameLink);
      // setIsLoading(false);
      setIsSubmitting(false);

      setShowShareModal(true);
      trackEvent('gameCreated', {selectedPlaylist});
      // setMessageText('A game has been created. You can invite your firends using the buttons below');
      // setMessageVariant('info');


    }
    catch (e) {

      if (e && e.response && e.response.status === 402) {
        trackEvent('upgradeRequired', {selectedPlaylist});
        setModalMessageBig(CONSTANTS.MESSAGE.UPGRADE);
        setModalMessage("You have reached the maximum number of games on your current plan. Upgrade now!");
        // setIsLoading(false);
        setIsSubmitting(false);
        setShowModalMessage(true);
      }
      else {
        setShowMessage(true);
        // const message = 
        // setMessageText(e.response.data.message);
        setMessageText(e.response.data ? e.response.data.message : 'Something went wrong' );
        setMessageVariant('danger');
        // setIsLoading(false);
        setIsSubmitting(false);

      }


    }
  }



  const handleAddPlaylist = (playlist) => {

    
    setSelectePlaylist(playlist);

  

  }


  const handleNewPlaylistClick = (playlistType) => {

    if(fields.name){
        store.set(CONSTANTS.STORE.NEW_GAME_NAME, fields.name);
    }
    
    if(playlistType === SPOTIFY){

      history.push(CONSTANTS.PAGE.SPOTIFYPLAYLISTS);
      
    }
    else{
      
      history.push(CONSTANTS.PAGE.ADD_YOUTUBE_PLAYLIST);
    }

  }

  const handleModalClose = () => {

    setShowModalMessage(false);

  }

  const handleShareModalClose = () => {

    setShowShareModal(false);
    setSelectePlaylist(null);
    fields.name = '';
    setCopyLinkCopy(clipboardCopy);
    history.push(CONSTANTS.PAGE.GAMES);

  }

  const handleShowPlaylists = ()=>{

    setShowMusingoPlaylists(!showMusingoPlaylists);
  }



  const onCopy = () => {

    setCopyLinkCopy('Link Copied');
    trackEvent('copiedLink', gameLink);
  };

  const renderPlaylists = () => {
    return (
      <div id='bingo' className='content-wrap'>

        <Container fluid>

          <Row className='justify-content-md-center'>
            <Col xs md='6' className='justify-content-md-center text-center'>

             

              <form onSubmit={handleSubmit}>
                {/* <Row className="justify-content-center"> */}
                {/* <Row className=""> */}
                {/* <Col sm="6" xl="4"> */}
                <FormGroup controlId="name">
                  <FormControl
                    autoFocus
                    type="text"
                    placeholder="Round Name"
                    aria-label="Enter Game Name"
                    value={fields.name}
                    onChange={handleFieldChange}
                    className="h1"
                  />
                  <BsPencilSquare />

                </FormGroup>
                {/* </Col> */}
                {/* </Row> */}

                {
                          isLoading && <p>Loading...</p>
                    }


                <h2 className="lines">Choose your Playlist! {isSafari && isMobile && "*"} </h2>

                <Row className='card-wrap'>

                { !isSafari && !isMobile &&  <Col xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                    <div className='d-flex align-items-stretch'>

                      <Button onClick={() => handleNewPlaylistClick(SPOTIFY)} className="card">
                        {/* <img alt='new game' src='/images/ydbb_elements-03.png' /> */}
                        <FaPlus className="plus"/>
                        <span>ADD SPOTIFY PLAYLIST</span>
                      </Button>


                    </div>
                  </Col> }

                  <Col xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                    <div className='d-flex align-items-stretch'>

                      <Button onClick={() => handleNewPlaylistClick(YOUTUBE) } className="card">
                        {/* <img alt='new game' src='/images/ydbb_elements-03.png' /> */}
                        <FaPlus className="plus"/>
                        <span>ADD YOUTUBE PLAYLIST</span>
                      </Button>


                    </div>
                  </Col>

                  <Col xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                    <div className='d-flex align-items-stretch'>

                      <Button onClick={handleShowPlaylists} className="card">
                        {/* <img alt='new game' src='/images/ydbb_elements-03.png' /> */}

                       {!showMusingoPlaylists && <FaPlus className="plus"/> }
                       { showMusingoPlaylists && <FaMinus className="plus"/> }

                        <span>{ showMusingoPlaylists ? 'HIDE' : 'SHOW'} MUSINGO PLAYLISTS</span>
                      </Button>


                    </div>
                  </Col>

                  {playlists &&
                    playlists.map(
                      (playlist, i) =>
                        <Col key={i} xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                          <div className='d-flex align-items-stretch'>

                            <Button onClick={() => handleAddPlaylist(playlist)} className="card noscroll">
                            {playlist.type === 'youtube' &&  <YTImageSquare images={playlist.images} /> }
                            {playlist.type !== 'youtube' &&  <img alt={playlist.name} src={playlist.image} /> }
                              <span>{playlist.name ? playlist.name : 'No Name'}</span>
                              {playlist.type === 'youtube' && <span>YouTube <FaYoutube /> </span>  }
                              {playlist.type !== 'youtube' && <span>Spotify <FaSpotify />  </span>}
                              {selectedPlaylist && playlist.playlist === selectedPlaylist.playlist &&

                                <FaCheckCircle className="tick" />
                              }
                            </Button>


                          </div>
                        </Col>

                    )
                  }

                  {(musingoPlaylists && showMusingoPlaylists) &&
                    musingoPlaylists.map(
                      (playlist, i) =>
                        <Col key={i} xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                          <div className='d-flex align-items-stretch'>

                            <Button onClick={() => handleAddPlaylist(playlist)} className="card noscroll">
                            {playlist.type === 'youtube' &&  <YTImageSquare images={playlist.images} /> }
                            {playlist.type !== 'youtube' &&  <img alt={playlist.name} src={playlist.image} /> }
                              <span>{playlist.name ? playlist.name : 'No Name'}</span>
                              {playlist.type === 'youtube' && <span>YouTube <FaYoutube /> (Musingo) </span>  }
                              {playlist.type !== 'youtube' && <span>Spotify <FaSpotify />  </span>}
                              {selectedPlaylist && playlist.playlist === selectedPlaylist.playlist &&

                                <FaCheckCircle className="tick" />
                              }
                            </Button>


                          </div>
                        </Col>

                    )
                  }




                </Row>

                <LoaderButton
                  className="pull-right"
                  block
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={!validateForm()}
                  text="Add Round"
                  loadingText="Adding Round"
                />

              </form>


              { isSafari && isMobile && <p>*The Spotify Player is not available on Mobile or Safari</p> }

              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible='true' variant={messageVariant} onClose={handleErroClose}>
                <p> {messageText} </p> </Alert>

              <Modal show={showShareModal} onHide={handleShareModalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  <Row className="justify-content-md-center">
                    <Col>

                      <h1> Invite Players</h1>


                      {/* <p>A game has been created. Invite your friends using the buttons below.</p> */}

                       {<p>Your round has been created. Invite your friends to play using the buttons below. Have fun! #musingobingo</p>}


                      <div className="text-center">
                      <CopyToClipboard onCopy={onCopy} text={gameLink}>
                       <Button variant="secondary">{copyLinkCopy}</Button>
                      </CopyToClipboard>
                      </div>

                      <div className="text-center">
                        <FacebookShareButton url={gameLink} ><FacebookIcon size={32}  /> </FacebookShareButton>{' '}
                        <WhatsappShareButton url={gameLink}><WhatsappIcon size={32}  /> </WhatsappShareButton >{' '}
                        <EmailShareButton url={gameLink}><EmailIcon size={32}  /> </EmailShareButton >{' '}
                      </div>

                    </Col>

                  </Row>

                </Modal.Body>
              </Modal>

              <Modal show={showModalMessage} onHide={handleModalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col>
                      <h1>{modalMessageBig}</h1>
                      <p>{modalMessage}</p>
                      {/* <Button variant="primary" onClick={handleModalClose}>Close</Button> */}
                      <Button variant="primary" onClick={() => history.push(CONSTANTS.PAGE.BUY)}>Upgrade</Button>
                    </Col>
                    <Col className="d-flex align-items-end">
                      <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                    </Col>
                  </Row>

                </Modal.Body>
              </Modal>


            </Col>
          </Row>
        </Container>
      </div>
    );
  }




  return (
    <>
      {renderPlaylists()}
    </>
  )
}