import { API } from "aws-amplify";
import CONSTANTS from '../libs/constants';

const selector = {

  playlist: getPlaylists,
  musingoplaylists: getMusingoPlaylists,
  card: getCard,
  game: getGames,
  theme: getTheme,
  me: getMe,
  tier: getTiers,
  system: getSystem,
  playlistOne : getPlaylist,
  musingoplaylistOne : getMusingoPlaylist

};

export function getPlaylists(){

  return API.get('api', '/users/playlists');
}

export function getMusingoPlaylists(){

  return API.get('api', `/users/playlists?owner=${CONSTANTS.USER.MUSINGO}`);
}

export function getMusingoPlaylist(playlistid){

  return API.get('api', `/users/playlist/${playlistid}?owner=${CONSTANTS.USER.MUSINGO}`);
}

export function getPlaylist(playlistid){

  return API.get('api', `/users/playlist/${playlistid}`);
}

export function getCard({user, game, participant}){

  let requestBody = {
    body: {
      game,
      user,
      participant

    }
  };

  return API.post('openapi', '/participant/card', requestBody);

}

export function getMe(){

  return API.get('api', '/users/me');
}

export function getBillingHistory(){

  return API.get('api', '/payment/history');
}

export function getWinner(game){

  return API.get('api', `/users/game/${game}/winner`);

}

export function getSystem(){

  return API.get('api', '/admin/system');
}

export function getYoutubePlaylist(playlistid){

  return API.get('api', `/users/youtube/playlist/${playlistid}`);
}

export function getTiers(){

  return API.get('api', '/payment/tiers');
}

export function addplaylistManual({name, playlist, image, tracks, type, images, owner}){

  let requestBody = { body:{name, playlist, image, tracks, type, images, owner} };
      
  return API.post('api', '/users/playlist/manual', requestBody);
}

export function updateplaylistManual({name, playlist, image, tracks, type, images}){

  let requestBody = { body:{name, playlist, image, tracks, type, images} };
      
  return API.put('api', '/users/playlist/manual', requestBody);
}

export function removePlaylist(playlist) {

  let requestBody = {
    body: {
      playlist

    }
  };

  return API.del('api', '/users/playlist', requestBody);

}

export function resetWinner({game, user}){

  let requestBody = { body:{game, user} };
      
  return API.post('api', '/users/resetwinner', requestBody);
}

export function togglehint({game, hintenabled}){

  let requestBody = { body:{game, hintenabled: hintenabled.toString()} };
      
  return API.post('api', '/users/hints', requestBody);
}

export function setCurrentGame(game){

  let requestBody = { body:{game} };
      
  return API.post('api', '/users/setcurrentgame', requestBody);
}

export function addGame({playlist, name, image, playlistname, playlisttype, playlistowner}){

  let requestBody = { body:{playlist, name, image, playlistname, playlisttype, playlistowner} };
      
  return API.post('api', '/users/game', requestBody);
}

export function updateTheme({inviteimage, bingobackgroundimage, bingoselectimage, invitemessage, bingomessage, themeShade}){

  let requestBody = { body:{inviteimage, bingobackgroundimage, bingoselectimage, invitemessage, bingomessage, themeShade}};
      
  return API.post('api', '/users/theme', requestBody);
}

export function submitPayment(charge){

  let requestBody = { body:charge};
      
  return API.post('api', '/payment/pay', requestBody);
}

export function submitPaymentIntent(charge){

  let requestBody = { body:charge};
      
  return API.post('api', '/payment/paymentintent', requestBody);
}

export function getTheme({user}){

  return API.get('openapi', `/participant/theme/${user}`);

}

export function getGames(){

  return API.get('api', '/users/games');

}

export function deleteGame(gameid){

  return API.del('api', `/users/game/${gameid}`);

}


export function getSpotifyToken(){

 
  return API.get('api', '/users/spotify/accesstoken');
  
};

export function getSpotifyDevices(){

  return API.get('spotify', '/me/player/devices');
}



export function load(type){

  return selector[type];
}


  
 

  