import React, { useState } from "react";
import { Button, Row, Col, Container, Modal } from "react-bootstrap";
import CONSTANTS from '../libs/constants';
import store from 'store';
import SpotifyPlaylists from '../containers/Wizard/SpotifyPlaylists';
import SpotifyConnect from '../containers/Wizard/SpotifyConnect';
import AddYoutubePlaylist from '../containers/Wizard/AddYoutubePlaylist';
import MusingoPlaylists from '../containers/Wizard/MusingoPlaylists';
import MusingoPlaylist from '../containers/Wizard/MusingoPlaylist';
import AddGame from "../containers/Wizard/AddGame";
import GameControl from "./Wizard/GameControl";
import { getRandomColouredLogo } from "../libs/util";
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import config from "../config";
import { FaHeart, FaMusic, FaRedoAlt, FaEllipsisH  } from 'react-icons/fa';


const STAGE_PLAYLIST = 'playlist';
const STAGE_SPOTIFY_CONNECT = 'spotify';
const STAGE_GET_PLAYLISTS = 'get_playlists';
const STAGE_GET_YOUTUBE_PLAYLISTS = 'get_youtube_playlists';
const STAGE_ADD_GAME = 'add_game';
const STAGE_CONFIRMATION = 'confirmation';
const STAGE_GAMECONTROL = 'gamecontrol';
const STAGE_BINGOBUTTONS = 'bingobuttons';
const STAGE_GET_MUSINGO_PLAYLISTS = 'get_musingo_playlists';
const STAGE_SHOW_MUSINGO_PLAYLIST = 'show_musingo_playlist'

const clipboardCopy = 'Copy invite to clipboard';


export default function Wizard() {

  const [wizardStage, setWizardStage] = useState(store.get(CONSTANTS.STORE.WIZARD_STAGE) !== undefined ? store.get(CONSTANTS.STORE.WIZARD_STAGE) : STAGE_PLAYLIST);
  // const [game, setGame] = useState(store.get(CONSTANTS.STORE.CURRENT_GAME) !== undefined ? store.get(CONSTANTS.STORE.CURRENT_GAME) : {});
  const [showNextButton, setShowNextButton] = useState(true);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [copyLinkCopy, setCopyLinkCopy] = useState(clipboardCopy);
  const history = useHistory();
  



  async function handleNextClick(event) {
    event.preventDefault();

    if(wizardStage === STAGE_PLAYLIST){

        setWizardStage(STAGE_SPOTIFY_CONNECT);
        store.set(CONSTANTS.STORE.WIZARD_STAGE, STAGE_SPOTIFY_CONNECT);
        setShowNextButton(false);
        
    }
    else if(wizardStage === STAGE_GET_PLAYLISTS){

      setWizardStage(STAGE_ADD_GAME);
      store.set(CONSTANTS.STORE.WIZARD_STAGE, STAGE_ADD_GAME);
      setShowNextButton(true);
      
    }
    else if(wizardStage === STAGE_CONFIRMATION){
     
      setWizardStage(STAGE_BINGOBUTTONS);
      store.set(CONSTANTS.STORE.WIZARD_STAGE, STAGE_BINGOBUTTONS);
      setShowNextButton(true);
      
    }
    else if(wizardStage === STAGE_GAMECONTROL){

      setWizardStage(STAGE_GAMECONTROL);
      store.remove(CONSTANTS.STORE.WIZARD_STAGE);

    }
    else if(wizardStage === STAGE_BINGOBUTTONS){

      setWizardStage(STAGE_GAMECONTROL);
      store.remove(CONSTANTS.STORE.WIZARD_STAGE);
      
      
    }

    
  }

  function addPlaylists(){
    

    return(

      <>
          <div className='justify-content-md-center text-center'>
          <h1> Setup Wizard (3) </h1> <br/>
          <h2 > Choose the playlists you would like to add Musingo Bingo </h2>
          </div>

          <div className='justify-content-md-center text-center'>
           
           { showNextButton &&  
           
           <>
           <Button variant="success" onClick={handleNextClick} size="lg" className="spotify">
                 
                 Next
                </Button>

            </>
               
               
               }
            
           </div>

          <SpotifyPlaylists/>

          <div className='justify-content-md-center text-center'>
           
           { showNextButton &&  
           
           <>
           <Button variant="success" onClick={handleNextClick} size="lg" className="spotify">
                 
                 Next
                </Button>

            </>
               
               
               }
            
           </div>

          </>
      
    );
  }

  function connectToSpotify(){

    return(

      <>

        <SpotifyConnect/>
      </>
    );

  }

  function addYoutubePlaylist(){

    return(

      <>

        <AddYoutubePlaylist/>
      </>
    );

  }


    function musingoPlaylists(){

      return(
  
        <>
  
          <MusingoPlaylists/>
        </>
      );
  
    }

    function showMusingoPlaylist(){

      return(
  
        <>
  
          <MusingoPlaylist/>
        </>
      );
  
    }
  



function addGame(){
  return(

    <>

        <div className='justify-content-md-center text-center'>
        <h1> Setup Wizard (4) </h1> <br/>
        <h2 > Create your round by naming your game and selecting your playlist</h2>
        </div>

      <AddGame/>

      </>
  );
}

function gameControl(){

  return(
    <GameControl/>
  )
}

const onCopy = () => {
  
  setCopyLinkCopy('Link Copied');
  
};

function handleBackClick() {
  store.remove(CONSTANTS.STORE.WIZARD_STAGE);
  history.go(0);
}


function confirmation(){
  return(

    <>

        <div className='justify-content-md-center text-center'>
        <h1> Setup Wizard (5) </h1> <br/>
        <h2> Confirmation </h2>
         </div>

        <div className='justify-content-md-center text-center'>
        <p> <strong>Congratulations</strong> you have created a Musingo Bingo round. <br/>When you are ready to play copy and share the link below with your friends <br/>and then we will show you how to connect Spotify to the Musingo Player. </p>
       
        <p>Invite your friends using the buttons below</p>


        <div className="text-center">
        <CopyToClipboard onCopy={onCopy} text={encodeURI(`${config.bingo.URL}/invite?user=${store.get(CONSTANTS.STORE.CURRENT_GAME).user}&game=${store.get(CONSTANTS.STORE.CURRENT_GAME).game}&gamename=${store.get(CONSTANTS.STORE.CURRENT_GAME).name}`)}>
          <Button variant="secondary">{copyLinkCopy}</Button>
        </CopyToClipboard>
        </div>
           
           { showNextButton &&   <Button variant="success" onClick={handleNextClick} size="lg" className="spotify">
                 
                Next
               </Button> }
            
           </div>

      </>
  );
}

function bingoButtons(){

  return(

    <Container>
    <Row className="justify-content-md-center">
      <Col xs md='6' className='justify-content-md-center text-center'>

      <h1>Setup Wizard (6)</h1>


      <p><strong>Please tell you guests what the buttons on the bingo card do.</strong></p>

        <div>
         
        <Button   aria-label="select" size="lg" variant="link"  >
               <FaHeart/>     
               </Button>{' '}    

       <Button  aria-label="select" size="lg" variant="link" >
           <FaMusic/>
       </Button>{' '}
      
      <Button aria-label="select" size="lg"  variant='link'>
               <FaRedoAlt/>     
      </Button>{' '}

      <Button aria-label="select" size="lg"  variant='link'>
               <FaEllipsisH/>     
      </Button>{' '}
      
         
        </div>

        <p> <FaHeart/> If you love the song click the heart button </p>
        <p> <FaMusic/> If you don't know the song click the music button </p>
        <p> <FaRedoAlt/> Load the current game that is being played </p>
        <p> <FaEllipsisH/> Show or hide the next songs </p>

        <div>
           
           { showNextButton &&   <Button variant="success" onClick={handleNextClick} size="lg" className="spotify">
                 
                Next
               </Button> }
            
           </div>


      </Col>

     
 
    </Row>
  </Container>
    
  );

}


  function createPlaylists(){

    return(

      <Container>
      <Row className="justify-content-md-center">
        <Col xs md='6' className='justify-content-md-center text-center'>

        <h1>Setup Wizard (1)</h1>


        <p><strong>First, create your playlists in Spotify PREMIUM.</strong></p>

        <p><strong>Or</strong> pick or create your playlist on <strong>YouTube</strong></p>

        <p>Your playlist must be  <strong> PUBLIC</strong> and have <strong> 15 OR MORE SONGS. </strong></p>
        
        <p>We recommend <strong> 15 to 20 songs </strong> for a round that will last between <strong>20 to 30 minutes.</strong></p>

        <p>Make sure you are happy with your <strong> final playlist </strong> before you upload into Musingo Bingo. <strong> You will not be able to change it once it is uploaded.</strong> </p>
          

          <div>
           
          { showNextButton &&   <Button variant="success" onClick={handleNextClick} size="lg" className="spotify">
                
               Next
              </Button> }
           
          </div>

        </Col>
      </Row>
    </Container>
      
    );
  }

  
  const handleModalClose = ()=> {

    setShowModalMessage(false);
    // setClosedWindow(true);
   

  }



 
  

  

  return (
    <div id="bingo" className="content-wrap">
       <Container fluid>
      <Button onClick={handleBackClick} variant="link" size="lg" className="back">Reset Wizard</Button>

{ wizardStage === STAGE_PLAYLIST && createPlaylists() }

{ wizardStage === STAGE_SPOTIFY_CONNECT && connectToSpotify() }

{ wizardStage === STAGE_GET_PLAYLISTS && addPlaylists() }

{ wizardStage === STAGE_ADD_GAME && addGame() }

{ wizardStage === STAGE_CONFIRMATION && confirmation() }

{ wizardStage === STAGE_GAMECONTROL && gameControl() }

{ wizardStage === STAGE_BINGOBUTTONS && bingoButtons() }

{ wizardStage === STAGE_GET_YOUTUBE_PLAYLISTS && addYoutubePlaylist() }

{ wizardStage === STAGE_GET_MUSINGO_PLAYLISTS && musingoPlaylists() }

{ wizardStage === STAGE_SHOW_MUSINGO_PLAYLIST && showMusingoPlaylist() }










<Modal show={showModalMessage} onHide={handleModalClose}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h1>The Music Player for Spotify is not available in Safari or on Mobile</h1>
                    <p>Please try using a different browser like Chrome or Edge or Firefox or use the YouTube Player</p>
                    <Button variant="primary" onClick={handleModalClose}>Close</Button>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>






            </Container>
  </div>
  );

}
