import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import Routes from "./Routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import config from './config';
import store from 'store';
import CONSTANTS from './libs/constants';
import { FaInstagram, FaFacebookF, FaYoutube } from 'react-icons/fa';

window.heap.load(config.heap.TOKEN);

function App() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  // const [abouLink, setAboutLink] = useState ('/about');

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      // setAboutLink('/aboutus');
    }
    catch (e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }

    setIsAuthenticating(false);
    // setAboutLink('/about');
  }

  async function signOut() {
    store.remove(CONSTANTS.STORE.CURRENT_GAME);
    store.remove(CONSTANTS.STORE.MUSINGO_USER);
    store.remove(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN);
    store.remove(CONSTANTS.STORE.SPOTIFY_ACCESS_TOKEN_EXPIRY);
    store.remove(CONSTANTS.STORE.PARTICIPANT_USER);
    store.remove(CONSTANTS.STORE.PARTICIPANT_GAME);
    store.remove(CONSTANTS.STORE.PARTICIPANT_GAME_NAME);
    store.remove(CONSTANTS.STORE.PARTICIPANT_ID);
    store.remove(CONSTANTS.STORE.PARTICIPANT_SONGS);
    store.remove(CONSTANTS.STORE.THEMES);
    store.remove(CONSTANTS.PLAYER.MAX_ERRORS);
    store.remove(CONSTANTS.STORE.GAMES);
    store.remove(CONSTANTS.STORE.PLAYLISTS);
    store.remove(CONSTANTS.STORE.CARD);
    store.remove(CONSTANTS.STORE.PLAYER_ERROR_COUNT);
    store.remove(CONSTANTS.STORE.FIRE);
    store.remove(CONSTANTS.STORE.PLAYED);
    store.remove(CONSTANTS.STORE.FIRE_COUNT);
    store.remove(CONSTANTS.STORE.PARTICIPANT_NAME);
    store.remove(CONSTANTS.STORE.DEVICE_ID);
    store.remove(CONSTANTS.STORE.NEW_GAME_NAME);
    store.remove(CONSTANTS.STORE.WIZARD_STAGE);
    store.remove(CONSTANTS.STORE.LIKES);
    store.remove(CONSTANTS.STORE.PLAYLIST_INDEX);
    store.remove(CONSTANTS.STORE.PLAYLIST);
    
  }

  async function handleLogout() {
    await Auth.signOut();
    await signOut();

    userHasAuthenticated(false);

    history.push("/login");
  }

  return (
    !isAuthenticating && (
      <div className="App">


        {isAuthenticated && (
          <>
            <Navbar bg="dark" variant="dark" expand="lg">
              <Navbar.Brand href="/">
                <img src="/images/logo.png" alt="logo" className="header-logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="">
                  <LinkContainer to="/rounds">
                    <Nav.Link>Rounds</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/theme">
                    <Nav.Link>Theme</Nav.Link>
                  </LinkContainer>
                  {/* <LinkContainer to="/help">
                    <Nav.Link>Help</Nav.Link>
                  </LinkContainer> */}
                  <LinkContainer to="/playlists">
                    <Nav.Link>Playlists</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/spotifyconnect">
                    <Nav.Link>Spotify</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/wizard">
                    <Nav.Link>Wizard</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/buy">
                    <Nav.Link>Buy</Nav.Link>
                  </LinkContainer>
                  <Nav.Link href="https://musingobingo.com/how-to/" target="_blank" >Help</Nav.Link>
                </Nav>
                <Nav className="ml-auto">
                <LinkContainer to="/account">
                    <Nav.Link>My Account</Nav.Link>
                  </LinkContainer>
                  <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  
                </Nav>
              </Navbar.Collapse></Navbar>

          </>
        )}

        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, heap: window.heap }}>
          <Routes />
        </AppContext.Provider>

        <footer>

          <Nav className="justify-content-center justify-content-sm-start">
           
              {/* <LinkContainer to="https://musingobingo.com/how-to/"> */}
                <Nav.Link href="https://musingobingo.com/how-to/" target="_blank" >Help</Nav.Link>
              {/* </LinkContainer> */}
           

          

           
              {/* <LinkContainer to='/about'> */}
              <Nav.Link href="https://musingobingo.com/about-us/" target="_blank">About</Nav.Link>
              {/* </LinkContainer> */}
           
            
              {/* <LinkContainer to='/privacy'>
                <Nav.Link>Privacy</Nav.Link>
              </LinkContainer> */}
           
          
          </Nav>


          <Nav className="justify-content-center">
            <Nav.Item>
              <Nav.Link href="https://www.instagram.com/musingobingo/" target="_blank">#musingobingo</Nav.Link>
            </Nav.Item>
          </Nav>


          <Nav className="justify-content-center justify-content-sm-end">
            <Nav.Item>
              <Nav.Link href="https://www.instagram.com/musingobingo/" target="_blank"><FaInstagram /></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.facebook.com/Musingo-Bingo-126470192148419" target="_blank"><FaFacebookF /></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.youtube.com/channel/UCv1_3ADHpC9CxcouImpKwMA" target="_blank"><FaYoutube /></Nav.Link>
            </Nav.Item>



          </Nav>
        </footer>
      </div>
    )
  );
}

export default App;