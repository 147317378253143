import Ticker from 'react-ticker';
import React from "react";



export default function TickerTape({moveTickerTape, tickerMessage,  ...props}) {

    function tickerTapeOutput(){

        return tickerMessage ? (
          <h2 style={{ whiteSpace: "nowrap" }}>{tickerMessage}</h2>
        ) : (
          <h2 style={{ visibility: "hidden" }}>Placeholder</h2>
        );
      
      }

    return (
        <Ticker
          offset="run-in"
          speed={8}
          move={moveTickerTape}
        >
        {({ index }) => (
            <>
                {tickerTapeOutput()}
                
            </>
        )}
    </Ticker>
    )
    
}