import React from "react";
import Particles from 'react-particles-js';



export default function Likes( {count, speed}) {
  return (


        <Particles id="particles-js"
    params={{
	    "particles": {
	        "number": {
	            "value": count,
	            "density": {
	                "enable": true,
	                "value_area": 800
	            }
	        },
	        "line_linked": {
	            "enable": false
	        },
	        "move": {
	            "speed":speed,
	            "out_mode": "out"
	        },
	        "shape": {
	            "type": [
	                "image"
	            ],
	            "image": [
					{
	                    "src": "/images/black-heart.png",
	                    "height": 10,
	                    "width": 10
	                },
					{
	                    "src": "/images/heart.png",
	                    "height": 20,
	                    "width": 20
	                }
	            ]
	        },
	        "size": {
	            "value": 30,
	            "random": true,
	            "anim": {
	                "enable": false,
	                "speed": 4,
	                "size_min": 10,
	                "sync": false
	            }
	        }
	    },
	    "retina_detect": true
	}} />



  );
}