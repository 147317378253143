import { Storage, Auth } from "aws-amplify";

export const S3_ACCESS = {
  PRIVATE:'private',
  PROTECTED:'protected',
  PUBLIC:'public'
}

async function getKeyFullPath(stored, level){

  const user  = await Auth.currentCredentials();
  const folder = user.identityId;
  const key =  `${level}/${folder}/${stored.key}`;

  return key;
}



export async function s3Upload(file, level = S3_ACCESS.PRIVATE, filename) {

  if(!filename){
    filename = `${Date.now()}-${file.name}`;
  }
  

  const stored = await Storage.put(filename, file, {
    level,
    contentType: file.type
  });

  if(level === S3_ACCESS.PRIVATE || level ===  S3_ACCESS.PROTECTED){

    return await getKeyFullPath(stored, level)
  }
  else{

    return `${level}/${stored.key}`;
  }

  }

  export async function s3GetURL(name, options){

    return await Storage.get(name, options)

  }


  export async function getUser(){
    let info = await Auth.currentUserInfo();

    if(!info || !info.attributes){
      return null;
    }
  
    return info.attributes.sub;
  
    
  }

  
 

  