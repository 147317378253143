import React, { useRef, useState, useEffect, useCallback } from "react";
import { Row, Col, Container, Form, Alert, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { s3Upload, S3_ACCESS, s3GetURL } from "../libs/awsLib";
import { useFormFields } from "../libs/hooksLib";
import LoaderButton from "../components/LoaderButton";
import { updateTheme } from "../libs/apiLib";
import { getRandomColouredLogo } from "../libs/util";
import { trackEvent } from "../libs/events";
import CONSTANTS from '../libs/constants';
import { useLoaded } from "../libs/hooksLib";
import { FaRegQuestionCircle, FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import store from 'store';
import fileExtension from 'file-extension';
import config from '../config';

// import { imageCheck } from "../libs/util";




const MAX_MESSAGE_LENGTH = 100;
const card = Array.from({ length: 12 }, (v, i) => i);

const DEFAULT_BACKGROUND_IMAGE = '/images/BG_Shapes.png';
const DEFAULT_INVITE_IMAGE = '/images/invite.png';



export default function Theme() {
  const inviteimage = useRef('');
  const bingobackgroundimage = useRef('');
  const bingoselectimage = useRef('');
  const themeShade = useRef(CONSTANTS.THEME.SHADE.LIGHT);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [messageVariant, setMessageVariant] = useState('info');
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [resetTheme, setResetTheme] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    invitemessage: "",
    bingomessage: "",

  });
  const [inviteImageLabel, setInviteImageLabel] = useState('Invite Image');
  const [backgroundImageLabel, setbackgroundImageLabel] = useState('Background Image');
  const [selectImageLabel, setSelectImageLabel] = useState('Bingo Select Image');
  const [showInvitePreview, setShowInvitePreview] = useState(false);
  const [showBingoCardPreview, setShowBingoCardPreview] = useState(false);
  const [backgroundImagePreivew, setBackgroundImagePreivew] = useState(DEFAULT_BACKGROUND_IMAGE);
  const [previewInviteImage, setPreviewInviteImage] = useState(DEFAULT_INVITE_IMAGE);
  const [previewBingoSelectImage, setPreviewBingoSelectImage] = useState('');

  const  [showCurrentInvite, setShowCurrentInvite] = useState(false);
  const  [currentBackgroundImagePreivew, setCurrentBackgroundImagePreivew] = useState(DEFAULT_BACKGROUND_IMAGE);
  const  [currentPreviewInviteImage, setCurrentPreviewInviteImage] = useState(DEFAULT_INVITE_IMAGE);
  const  [currentInvitemessage, setCurrentInvitemessage] = useState('');
  const  [currentBingoSelectImage, setCurrentBingoSelectImage] = useState('');
  const  currentThemeShade = useRef(CONSTANTS.THEME.SHADE.LIGHT);

  const [showCurrentBingoCard, setShowCurrentBingoCard] = useState(false);
  const [currentBingomessage, setCurrentBingomessage] = useState('');

  const [showCurrentThemeButton, setShowCurrentThemeButton] = useState(false);

  const [justUpdated, setJustUpdated] = useState(false);


  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalMessageBig, setModalMessageBig] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [showExample, setShowExample] = useState(false);
  const history = useHistory();

  const loaded = useCallback(useLoaded('me', setIsLoading));



  useEffect(() => {

    if (loaded.error) {

      if (loaded.error.status !== 404) {

        setShowMessage(true);
        setMessageText(loaded.error.message);
        setMessageVariant('danger');
      }


    }
    else if (loaded.data) {

      if (loaded.data.tier === CONSTANTS.TIER.FREE) {

        setModalMessageBig(CONSTANTS.MESSAGE.UPGRADE);
        setModalMessage("Custom themes are not available on the Free tier.");
        setShowModalMessage(true);

      }
      else if (loaded.data.theme && !justUpdated){


       const backgroundImage = loaded.data.theme.bingobackgroundimage ? `${config.bingo.IMAGE_URL}/${loaded.data.theme.bingobackgroundimage}` : DEFAULT_BACKGROUND_IMAGE;
       const inviteImage = loaded.data.theme.inviteimage ? `${config.bingo.IMAGE_URL}/${loaded.data.theme.inviteimage}` : DEFAULT_INVITE_IMAGE;
       const bingoMessage = loaded.data.theme.bingomessage ? loaded.data.theme.bingomessage : '';
       const inviteMessage = loaded.data.theme.invitemessage ? loaded.data.theme.invitemessage : '';
       const selectImage = loaded.data.theme.bingoselectimage ? `${config.bingo.IMAGE_URL}/${loaded.data.theme.bingoselectimage}` : '';

        setCurrentBackgroundImagePreivew(backgroundImage);
        setCurrentPreviewInviteImage(inviteImage);
        setCurrentInvitemessage(inviteMessage);
        setCurrentBingoSelectImage(selectImage);
        setCurrentBingomessage(bingoMessage);

        if(loaded.data.theme.themeShade){
          currentThemeShade.current = loaded.data.theme.themeShade;
        }

        setShowCurrentThemeButton(true);


      }

    }

  }, [loaded.dataLoaded]);

  const handleCurrentBingoCardModalClose = () => {
    setShowCurrentBingoCard(false);
  }

  const handleCurrentInviteModalClose = () => {
    setShowCurrentInvite(false);
  }



  const handleErroClose = () => {
    setShowMessage(false);
  }

  const validateForm = () => {
    return (


      inviteimage.current || bingobackgroundimage.current || bingoselectimage.current || fields.invitemessage || fields.bingomessage || resetTheme

    );
  }

  const handleUpload = async (event, file) => {

    setIsUploading(true);


    const fileName = event.target.files[0].name;

    const label = event.target.id;

    let ext = fileExtension(fileName);

    if(ext === 'jpeg'){
      ext = 'jpg';
    }

    let name = `${store.get(CONSTANTS.STORE.MUSINGO_USER)}-${label}.${ext}`;


    // let res = await imageCheck(event.target.files[0]);


    file.current = await s3Upload(event.target.files[0], S3_ACCESS.PUBLIC, name);


    if (fileName) {

      let tempFileName = file.current.replace('public/', '');
      const tempURL = await s3GetURL(tempFileName, { expires: 600 });

      if (label === 'inviteImage') {
        setInviteImageLabel(fileName);
        setPreviewInviteImage(tempURL);

      }
      else if (label === 'bingoBackground') {
        setbackgroundImageLabel(fileName);
        setBackgroundImagePreivew(tempURL);
      }
      else if (label === 'bingoSelectImage') {
        setSelectImageLabel(fileName);
        setPreviewBingoSelectImage(tempURL);
      }

    }

    setIsUploading(false);


  }

  const handleModalClose = () => {

    setShowModalMessage(false);
    history.push(CONSTANTS.PAGE.GAMES);

  }

  const handleShowExampleClose = () => {

    setShowExample(false);
   

  }

  const handleInvitePreviewModalClose = () => {

    setShowInvitePreview(false);
  }

  const handleBingoCardPreviewModalClose = () => {
    setShowBingoCardPreview(false);
  }

  const handleShowExample = () => {
    setShowExample(true);
  }





  const handleResetTheme = () => {
    
    bingobackgroundimage.current = '';
    bingoselectimage.current = '';
    inviteimage.current = '';
    themeShade.current = CONSTANTS.THEME.SHADE.LIGHT;
    setBackgroundImagePreivew('/images/BG_Shapes.png');
    setPreviewInviteImage('/images/invite.png');
    setPreviewBingoSelectImage('');
    fields.invitemessage = '';
    fields.bingomessage = '';
    setInviteImageLabel('Invite Image');
    setbackgroundImageLabel('Bingo Background Image');
    setSelectImageLabel('Bingo Select Image');
    setResetTheme(true);

    setMessageVariant('info');
    setShowMessage(true);
    setMessageText('To confirm your reset click the update button')



  }

  async function handleSubmit(event) {
    event.preventDefault();


    setIsLoading(true);
    const newTheme = {
      inviteimage: inviteimage.current,
      bingobackgroundimage: bingobackgroundimage.current,
      bingoselectimage: bingoselectimage.current,
      invitemessage: fields.invitemessage,
      bingomessage: fields.bingomessage,
      themeShade: themeShade.current
    }

    trackEvent('newTheme', newTheme);

    updateTheme(newTheme).then((response) => {

      setIsLoading(false);
      setShowMessage(true);
      setMessageText('Your theme has been updated');
      setMessageVariant('info');

      if(response.theme){

        setJustUpdated(true);
        setCurrentBackgroundImagePreivew(response.theme.bingobackgroundimage ? `${config.bingo.IMAGE_URL}/${response.theme.bingobackgroundimage}` : DEFAULT_BACKGROUND_IMAGE);
        setCurrentPreviewInviteImage(response.theme.inviteimage ? `${config.bingo.IMAGE_URL}/${response.theme.inviteimage}` : DEFAULT_INVITE_IMAGE);
        setCurrentBingoSelectImage(response.theme.bingoselectimage ? `${config.bingo.IMAGE_URL}/${response.theme.bingoselectimage}` : '');
        setCurrentBingomessage(response.theme.bingomessage ? response.theme.bingomessage : '');
        setCurrentInvitemessage(response.theme.invitemessage ? response.theme.invitemessage : '');


      }


    })
      .catch((e) => {

        setIsLoading(false);
        setShowMessage(true);
        setMessageText(e.message);
        setMessageVariant('danger');

      });

  }

  function showSelected() {

    if (previewBingoSelectImage && previewBingoSelectImage !== '') {

      return (
        <img alt='selected' src={previewBingoSelectImage} className="tick custom" />
      );
    }
    else {
      return (
        <FaCheckCircle className="tick" />
      );
    }

  }

  
  function currentShowSelected(){

    if (currentBingoSelectImage && currentBingoSelectImage !== '') {

      return (
        <img alt='selected' src={currentBingoSelectImage} className="tick custom" />
      );
    }
    else {
      return (
        <FaCheckCircle className="tick" />
      );
    }

  }


  return (
    <div id="bingo" className="content-wrap">

      <Container fluid>

        <Row className="justify-content-center">
          <Col xs md="6" className="justify-content-center text-center">
            <h1>Theme</h1>

            {
              isLoading && <p>Loading...</p>
            }

            {/* TODO: move this to a better place */}
            <p> Uploaded images will be publicly available. </p>

            {
              isUploading && <p>Uploading Image...</p>
            }

         {showCurrentThemeButton &&

              <>

              <Button onClick={() => setShowCurrentInvite(true)} aria-label="select" size="sm" variant="link" disabled={!showCurrentThemeButton} >
                                Show Current Invite
                              </Button> {' '} 

              <Button onClick={() => setShowCurrentBingoCard(true)} aria-label="select" size="sm" variant="link" disabled={!showCurrentThemeButton} >
                Show Current Bingo Card
              </Button> 



                      </>        
                  }


            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="invitemessage">
                <Form.Label>Invite Message</Form.Label>
                <Form.Control autoFocus as="textarea" rows="3" value={fields.invitemessage.substring(0, MAX_MESSAGE_LENGTH - 1)} onChange={handleFieldChange} />
                <div className="char-count">
                  {fields.invitemessage.length}/{MAX_MESSAGE_LENGTH}
                  {'  '}
                  <OverlayTrigger overlay={
                    <Tooltip id="tooltip-disabled">
                       A custom message on your invite
                    </Tooltip>
                  }>
                    <span className="d-inline-block">
                      <FaRegQuestionCircle />
                    </span>
                  </OverlayTrigger>
                </div>
              </Form.Group>

              <Form.Group controlId="bingomessage">
                <Form.Label>Bingo Card Message</Form.Label>
                <Form.Control as="textarea" rows="3" value={fields.bingomessage.substring(0, MAX_MESSAGE_LENGTH - 1)} onChange={handleFieldChange} />
                <div className="char-count">
                  {fields.bingomessage.length}/{MAX_MESSAGE_LENGTH}
                  {'  '}
                  <OverlayTrigger overlay={
                    <Tooltip id="tooltip-disabled">
                     A custom message on the Musingo card
                    </Tooltip>
                  }>
                    <span className="d-inline-block">
                      <FaRegQuestionCircle />
                    </span>
                  </OverlayTrigger>
                </div>
              </Form.Group>


              <Form.Group >
                <Form.File id="inviteImage" custom onChange={(event) => handleUpload(event, inviteimage)} label={inviteImageLabel} />
                <div className="char-count">

                  <OverlayTrigger overlay={
                    <Tooltip id="tooltip-disabled">
                      Select an image approx. 1400 x 700 pixels
                    </Tooltip>
                  }>
                    <span className="d-inline-block">
                      <FaRegQuestionCircle />
                    </span>
                  </OverlayTrigger>

                </div>
              </Form.Group>


              <Form.Group>
                <Form.File id="bingoBackground" custom onChange={(event) => handleUpload(event, bingobackgroundimage)} label={backgroundImageLabel} />
                <div className="char-count">

                <OverlayTrigger overlay={
                    <Tooltip id="tooltip-disabled">
                      Select an image approx. 700 x 700 pixels, with the focusal point centred
                    </Tooltip>
                  }>
                    <span className="d-inline-block">
                      <FaRegQuestionCircle />
                    </span>
                  </OverlayTrigger>
                </div>
              </Form.Group>

              <Form.Group>
                <Form.File id="bingoSelectImage" custom onChange={(event) => handleUpload(event, bingoselectimage)} label={selectImageLabel} />
                <div className="char-count">
                  
                <OverlayTrigger overlay={
                    <Tooltip id="tooltip-disabled">
                      Select an image approx. 120 x 120 pixels
                    </Tooltip>
                  }>
                    <span className="d-inline-block">
                      <FaRegQuestionCircle />
                    </span>
                  </OverlayTrigger>

                </div>
              </Form.Group>

              <Form.Row>
                {/* <Form.Group controlId="shade"> */}
                <Form.Check
                  custom
                  type="radio"
                  label="Light theme"
                  id="theme-light"
                  name="theme-light-dark"
                  onChange={() => { themeShade.current = CONSTANTS.THEME.SHADE.LIGHT }}
                  defaultChecked={themeShade.current === 'light' ? "checked" : ""}
                />
                <Form.Check
                  custom
                  type="radio"
                  label="Dark theme"
                  id="theme-dark"
                  name="theme-light-dark"
                  onChange={() => { themeShade.current = CONSTANTS.THEME.SHADE.DARK }}
                  defaultChecked={themeShade.current === 'dark' ? "checked" : ""}
                />
                {/* </Form.Group> */}

              </Form.Row>

              <Button onClick={handleResetTheme} aria-label="select" size="sm" variant="link">
                Reset Theme
                  </Button>{' '}
              
              <Button onClick={handleShowExample} aria-label="select" size="sm" variant="link">
                Show Example
                  </Button>{' '}

              {
                validateForm() && <>
                  <Button onClick={() => setShowInvitePreview(true)} aria-label="select" size="sm" variant="link">
                    Preview Invite
                  </Button>{' '}

                  <Button onClick={() => setShowBingoCardPreview(true)} aria-label="select" size="sm" variant="link">
                    Preview Bingo Card
                  </Button>
                </>

              }



              <LoaderButton
                className="pull-right"
                block
                type="submit"
                isLoading={isLoading}
                text="Update"
                loadingText="Updating..."
                disabled={!validateForm()}
              />
              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                {messageText}
              </Alert>
            </Form>

            <Modal size="lg" show={showInvitePreview} onHide={handleInvitePreviewModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Invite Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <div className={`content-wrap un-auth ${themeShade.current === 'light' ? 'light-theme' : 'dark-theme'}`} id="invite" style={{ backgroundImage: `url(${backgroundImagePreivew}), linear-gradient(180deg, #4364F7 0%, #6FB1FC 100%)` }} >

                  <Container fluid>
                    <Row className=''>
                      <Col xs md='6'>
                        <Row>
                          <div alt='inviteImage' style={{ backgroundImage: `url(${previewInviteImage})` }} className='vads-invite'></div>
                        </Row>

                      </Col>
                      <Col md='6' className='d-flex '>
                        <div id='invite-wrap' className='d-flex justify-content-center align-items-center flex-column'>


                          <h1>Musingo</h1>
                          <p>{fields.invitemessage}</p>
                          <h2>Welcome to Musingo</h2>


                          <Link to="/theme" className='btn btn-primary'>Play</Link>


                          <br />

                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>

              </Modal.Body>
            </Modal>

            <Modal size="lg" show={showBingoCardPreview} onHide={handleBingoCardPreviewModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Bingo Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <div id="bingo" className={`content-wrap un-auth ${themeShade.current === 'light' ? 'light-theme' : 'dark-theme'}`} style={{ backgroundImage: `url(${backgroundImagePreivew}), linear-gradient(180deg, #4364F7 0%, #6FB1FC 100%)` }}>

                  <Container fluid>

                    <Row className="justify-content-md-center">
                      <Col md="6" className="justify-content-md-center text-center">
                        <h1>Musingo</h1>



                        <p>If you hear one of the songs on your card tap the square, if you make a mistake tap again to unselect. </p>
                        {/* <p>Have a song, <strong>THUMBS UP</strong>. No song, <strong>THUMBS DOWN</strong>. Like the song, <strong>DANCE</strong>. Get Bingo, <strong>GO CRAZY!!</strong></p> */}

                        <p>{fields.bingomessage}</p>

                        <h4>Game: Game Name</h4>



                        <Row className="card-wrap">

                          {

                            card.map(
                              (id, i) =>
                                <Col key={i} xs="6" md="4" lg="3" className="d-flex align-items-stretch">
                                  <div className="d-flex align-items-stretch">
                                    <Button className="btn-block d-flex flex-column justify-content-between" onClick={() => ""} aria-label="select" >
                                      <img alt={id} src={`images/${getRandomColouredLogo()}`} className="selected" />
                                      {/* <Button className="btn-block d-flex flex-column justify-content-between" aria-label="select" >
                    <img alt="musingoLogo" src={'images/bingo-modal.png'} /> */}


                                      {/* <span>{song.name}</span> */}


                                      <span>Title</span>

                                      {id % 3 === 0 &&
                                        showSelected()
                                      }
                                    </Button>
                                  </div>
                                </Col>

                            )
                          }
                        </Row>

                      </Col>
                    </Row>
                  </Container>
                </div>

              </Modal.Body>
            </Modal>

        {/* test current theme */}

        <Modal size="lg" show={showCurrentInvite} onHide={handleCurrentInviteModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Current Invite</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <div className={`content-wrap un-auth ${currentThemeShade.current === 'light' ? 'light-theme' : 'dark-theme'}`} id="invite" style={{ backgroundImage: `url(${currentBackgroundImagePreivew}), linear-gradient(180deg, #4364F7 0%, #6FB1FC 100%)` }} >

                  <Container fluid>
                    <Row className=''>
                      <Col xs md='6'>
                        <Row>
                          <div alt='inviteImage' style={{ backgroundImage: `url(${currentPreviewInviteImage})` }} className='vads-invite'></div>
                        </Row>

                      </Col>
                      <Col md='6' className='d-flex '>
                        <div id='invite-wrap' className='d-flex justify-content-center align-items-center flex-column'>


                          <h1>Musingo</h1>
                          <p>{currentInvitemessage}</p>
                          <h2>Welcome to Musingo</h2>


                          <Link to="/theme" className='btn btn-primary'>Play</Link>


                          <br />

                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>

              </Modal.Body>
            </Modal>

            <Modal size="lg" show={showCurrentBingoCard} onHide={handleCurrentBingoCardModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Current Bingo Card</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <div id="bingo" className={`content-wrap un-auth ${currentThemeShade.current === 'light' ? 'light-theme' : 'dark-theme'}`} style={{ backgroundImage: `url(${currentBackgroundImagePreivew}), linear-gradient(180deg, #4364F7 0%, #6FB1FC 100%)` }}>

                  <Container fluid>

                    <Row className="justify-content-md-center">
                      <Col md="6" className="justify-content-md-center text-center">
                        <h1>Musingo</h1>



                        <p>If you hear one of the songs on your card tap the square, if you make a mistake tap again to unselect. </p>
                        {/* <p>Have a song, <strong>THUMBS UP</strong>. No song, <strong>THUMBS DOWN</strong>. Like the song, <strong>DANCE</strong>. Get Bingo, <strong>GO CRAZY!!</strong></p> */}

                        <p>{currentBingomessage}</p>

                        <h4>Game: Game Name</h4>



                        <Row className="card-wrap">

                          {

                            card.map(
                              (id, i) =>
                                <Col key={i} xs="6" md="4" lg="3" className="d-flex align-items-stretch">
                                  <div className="d-flex align-items-stretch">
                                    <Button className="btn-block d-flex flex-column justify-content-between" onClick={() => ""} aria-label="select" >
                                      <img alt={id} src={`images/${getRandomColouredLogo()}`} className="selected" />
                                      {/* <Button className="btn-block d-flex flex-column justify-content-between" aria-label="select" >
                    <img alt="musingoLogo" src={'images/bingo-modal.png'} /> */}


                                      {/* <span>{song.name}</span> */}


                                      <span>Title</span>

                                      {id % 3 === 0 &&
                                        currentShowSelected()
                                      }
                                    </Button>
                                  </div>
                                </Col>

                            )
                          }
                        </Row>

                      </Col>
                    </Row>
                  </Container>
                </div>

              </Modal.Body>
            </Modal>




        {/* end current theme */}

            <Modal show={showModalMessage} onHide={handleModalClose}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h1>{modalMessageBig}</h1>
                    <p>{modalMessage}</p>
                    <Button variant="primary" onClick={() => history.push(CONSTANTS.PAGE.BUY)}>Upgrade</Button>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>

            <Modal size="lg" show={showExample} onHide={handleShowExampleClose}>
              <Modal.Header closeButton>
                Example
              </Modal.Header>
              <Modal.Body>
              <Container fluid>

                    <Row className="justify-content-md-center">
                  <Col>
                  <span>Invite</span>
                  <img alt="modalImage" src="/images/inviteHelp.png" />
                  </Col>
                  <Col>
                    <span>Bingo Card</span>
                    <img alt="modalImage" src="/images/bingoHelp.png" />
                  </Col>
                </Row>

                </Container>

              </Modal.Body>
            </Modal>




          </Col>
        </Row>
      </Container>
    </div>
  );
}