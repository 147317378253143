import React from "react";
import { Row, Col, Container, Table, Card} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";







export default function Home() {
  
  const { isAuthenticated } = useAppContext();


  return (
    <div id="help" className="content-wrap">
             {!isAuthenticated &&
        <Link to="/" >
          <img alt="logo" src="/images/logo.png" className="App-logo" />
        </Link>
      }

      <Container fluid>

        <Row className="justify-content-center">
          <Col xs md="6" className="justify-content-center text-center">
            <h1>Musingo Bingo</h1>

            <p>We are your ultimate online music bingo game created to bring your virtual parties and events to life. Whether it’s 90s Brit Pop, Prince’s greatest hits, grime or Disney theme tunes, it’s all about playing your sounds, your way and bringing everyone together under a virtual roof.</p>

            <p>Love Music Bingo</p>

            <div>
           

            <iframe title="musingo" width="504" height="283" src="https://www.youtube.com/embed/0juQ3345KH8" frameBorder="0" allow="accelerometer; encrypted-media" allowFullScreen></iframe>
            {/* <iframe title="musingo" width="604" height="340" src="https://www.youtube.com/embed/0juQ3345KH8" frameBorder="0" allow="accelerometer; encrypted-media" allowFullScreen></iframe> */}
           
            </div>

           
            <Link to="/signup" className="btn btn-link ">Sign up</Link> {'  '}

            <Link to="/login" className="btn btn-link ">Sign in</Link>

            <h4 id="support">Pricing</h4>

            <Card>

            <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Tier</th>
                              <th>Price</th>
                              <th>Games</th>
                              <th>Players</th>
                              <th>Theme</th>
                              <th>Support</th>    
                            </tr>
                          </thead>
                          <tbody>


                          
                                <tr >
                                  <td>
                                   <strong>Free </strong>
                                  </td>
                                  <td>
                                    £0
                                  </td>
                                  <td>
                                    1
                                  </td>
                                  <td>
                                    5
                                  </td>
                                  <td>
                                    No
                                  </td>
                                  <td>
                                    No
                                  </td>
                                 
                                </tr>

                                <tr >
                                  <td>
                                  <strong>Bronze</strong>
                                  </td>
                                  <td>
                                    £20
                                  </td>
                                  <td>
                                    2
                                  </td>
                                  <td>
                                    15
                                  </td>
                                  <td>
                                    Yes
                                  </td>
                                  <td>
                                    No
                                  </td>
                                 
                                </tr>

                                <tr >
                                  <td>
                                  <strong>Silver</strong>
                                  </td>
                                  <td>
                                    £40
                                  </td>
                                  <td>
                                    4
                                  </td>
                                  <td>
                                    30
                                  </td>
                                  <td>
                                    Yes
                                  </td>
                                  <td>
                                    No
                                  </td>
                                 
                                </tr>

                                <tr >
                                  <td>
                                  <strong>Gold</strong>
                                  </td>
                                  <td>
                                    £60
                                  </td>
                                  <td>
                                    6
                                  </td>
                                  <td>
                                    45
                                  </td>
                                  <td>
                                    Yes
                                  </td>
                                  <td>
                                    No
                                  </td>
                                 
                                </tr>

                                <tr >
                                  <td>
                                  <strong>Enterprise</strong>
                                  </td>
                                  <td>
                                    Contact Us
                                  </td>
                                  <td>
                                    20
                                  </td>
                                  <td>
                                    300
                                  </td>
                                  <td>
                                    Yes
                                  </td>
                                  <td>
                                    Yes
                                  </td>
                                  
                                </tr>
                                </tbody>
                        </Table>   

                  </Card> 
           
           
          </Col>
        </Row>
      </Container>
    </div>
  );
}