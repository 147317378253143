const local = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "kt-bingo-dev-store"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://i2fwfpe4f0.execute-api.us-west-2.amazonaws.com/dev/api"
  },
  openApiGateway: {
    REGION: "us-west-2",
    URL: "https://i2fwfpe4f0.execute-api.us-west-2.amazonaws.com/dev/openapi"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_4dPsZcssz",
    APP_CLIENT_ID: "6pbb8jj4061em2e18jpmnjtes0",
    IDENTITY_POOL_ID: "us-west-2:3cf98b85-4a88-4bea-99c5-dcbd7ad40fba"
  },
  bingo:{
    URL: "http://localhost:3000",
    IMAGE_URL: "https://kt-bingo-dev-store.s3-us-west-2.amazonaws.com"
  },
  stripe:{
    STRIPE_KEY: 'pk_test_51GrJjwARyUnzM7Zhsmdqpd3z2lpGc3c0bgh9T8GHskcACH2ZUZrf9MRcHD4UVtgzUJksNJX5XEXc9qpKur0zdmUF00AoNw1z14'
  },
  heap: {
    TOKEN: '3838433978'
  },
};

const dev = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "kt-bingo-dev-store"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://i2fwfpe4f0.execute-api.us-west-2.amazonaws.com/dev/api"
  },
  openApiGateway: {
    REGION: "us-west-2",
    URL: "https://i2fwfpe4f0.execute-api.us-west-2.amazonaws.com/dev/openapi"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_4dPsZcssz",
    APP_CLIENT_ID: "6pbb8jj4061em2e18jpmnjtes0",
    IDENTITY_POOL_ID: "us-west-2:3cf98b85-4a88-4bea-99c5-dcbd7ad40fba"
  },
  bingo:{
    URL: "https://d3s6z8doqsfm39.cloudfront.net/",
    IMAGE_URL: "https://d2ac3c2ghqwse8.cloudfront.net"
  },
  stripe:{
    STRIPE_KEY: 'pk_test_51GrJjwARyUnzM7Zhsmdqpd3z2lpGc3c0bgh9T8GHskcACH2ZUZrf9MRcHD4UVtgzUJksNJX5XEXc9qpKur0zdmUF00AoNw1z14'
  },
  heap: {
    TOKEN: '3838433978'
  },
 
};

const stag = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "kt-bingo-stag-store"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://az4t4te3vg.execute-api.us-west-2.amazonaws.com/stag/api"
  },
  openApiGateway: {
    REGION: "eu-west-1",
    URL: "https://az4t4te3vg.execute-api.us-west-2.amazonaws.com/stag/openapi"
   
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_pAoMV3Ove",
    APP_CLIENT_ID: "66rfmid07r3sf5eg7ub246tuuu",
    IDENTITY_POOL_ID: "us-west-2:9b0d3680-92f8-470f-9ed4-f0b382ef0933"
  },
  bingo:{
    URL: "https://dev.bingo.kingtrivia.com",
    IMAGE_URL: "https://kt-bingo-stag-store.s3-us-west-2.amazonaws.com"
  },
  stripe:{
    STRIPE_KEY: 'pk_test_51GrJjwARyUnzM7Zhsmdqpd3z2lpGc3c0bgh9T8GHskcACH2ZUZrf9MRcHD4UVtgzUJksNJX5XEXc9qpKur0zdmUF00AoNw1z14'
  },
  heap: {
    TOKEN: '3838433978'
  },
 
};

const prod = {
  s3: {
    REGION: "eu-west-1",
    BUCKET: "oa-musingo-prod-store"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://20coqjh5m5.execute-api.eu-west-1.amazonaws.com/prod/api"
  },
  openApiGateway: {
    REGION: "eu-west-1",
    URL: "https://20coqjh5m5.execute-api.eu-west-1.amazonaws.com/prod/openapi"
   
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_LbNIqOQxv",
    APP_CLIENT_ID: "4sto8qqrmnvg2tr9bpl8a8ffik",
    IDENTITY_POOL_ID: "eu-west-1:5f3d153e-e3f7-42f6-9143-cb3dd7debf74"
  },
  bingo:{
    URL: "https://play.musingobingo.com",
    IMAGE_URL: "https://oa-musingo-prod-store.s3-eu-west-1.amazonaws.com"
  },
  stripe:{
    STRIPE_KEY: 'pk_live_90w8nDdWF9hXlf3ZzrWHdi9e007qAFRio9'
  },
  heap: {
    TOKEN: '998466744'
  },
 
};


let config = dev;

if(process.env.REACT_APP_ENV === 'local'){
   config = local;
}
else if(process.env.REACT_APP_ENV === 'stag'){
  config = stag;
}
else if(process.env.REACT_APP_ENV === 'prod'){
  config = prod;
}



export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  SPOTIFY_API: 'https://api.spotify.com/v1',
  pubnub: {
    SUBSCRIBE_KEY: 'sub-c-e8d2d8ae-1616-46de-8b8f-dd3379644fea',
    PUBLISH_KEY: 'pub-c-f56a8c8b-6b8b-4830-99d9-b6b0f2261398'
  },
  PLAYER:{
    SPOTIFY: 'spotify',
    YOUTUBE: 'youtube'
  },
  ...config
   
  };