import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Container, Col, Alert, Button } from "react-bootstrap";

import { useLoaded } from "../../libs/hooksLib";

import CONSTANTS from '../../libs/constants';

import { FaCheckCircle, FaYoutube, FaSpotify } from 'react-icons/fa';
import store from 'store';
import YTImageSquare from "../../components/YTImageSquare";


const STAGE_SHOW_MUSINGO_PLAYLIST = 'show_musingo_playlist'



export default function MusingoPlaylists() {
  const [isLoading, setIsLoading] = useState(false);
 
  const [selectedPlaylist] = useState(null);
  const [playlists, setPlaylists] = useState(store.get(CONSTANTS.STORE.PLAYLISTS) ? store.get(CONSTANTS.STORE.PLAYLISTS) : []);


  const [messageVariant, setMessageVariant] = useState('info');
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const history = useHistory();


  const loaded = useLoaded('musingoplaylists', setIsLoading);


  useEffect(() => {

    if (loaded.error) {

      if (loaded.error.status !== 404) {

        setShowMessage(true);
        setMessageText(loaded.error.message);
        setMessageVariant('danger');
      }


    }
    else if(loaded.data) {

      setPlaylists(loaded.data);
      store.set(CONSTANTS.STORE.PLAYLISTS, loaded.data);
      // playlists = loaded.data;

    }


  }, [loaded]);


  const handleErroClose = () => {
    setShowMessage(false);
  }


  const handleSelectlaylist = (playlist) => {

    store.set(CONSTANTS.STORE.PLAYLIST, playlist.playlist);

    store.set(CONSTANTS.STORE.WIZARD_STAGE, STAGE_SHOW_MUSINGO_PLAYLIST);

    history.go(0);
    
  }

   

  const renderPlaylists = () => {
    return (
      <div id='bingo' className='content-wrap'>

        <Container fluid>

          <Row className='justify-content-md-center'>
            <Col xs md='6' className='justify-content-md-center text-center'>
            <h1>Setup Wizard (3a)</h1>

            <p>Select a Musingo Bingo Playlist to use </p>

                {
                          isLoading && <p>Loading...</p>
                    }


                <Row className='card-wrap'>
  
                  {playlists &&
                    playlists.map(
                      (playlist, i) =>
                        <Col key={i} xs='6' md='4' lg='3' className='d-flex align-items-stretch'>
                          <div className='d-flex align-items-stretch'>

                            <Button onClick={() => handleSelectlaylist(playlist)} className="card noscroll">
                            {playlist.type === 'youtube' &&  <YTImageSquare images={playlist.images} /> }
                            {playlist.type !== 'youtube' &&  <img alt={playlist.name} src={playlist.image} /> }
                              <span>{playlist.name ? playlist.name : 'No Name'}</span>
                              {playlist.type === 'youtube' && <span>YouTube <FaYoutube /> </span>  }
                              {playlist.type !== 'youtube' && <span>Spotify <FaSpotify />  </span>}
                              <span>&nbsp;({playlist.tracks.length})</span>
                              {selectedPlaylist && playlist.playlist === selectedPlaylist.playlist &&

                                <FaCheckCircle className="tick" />
                              }
                            </Button>


                          </div>
                        </Col>

                    )
                  }

                  




                </Row>

                

              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible='true' variant={messageVariant} onClose={handleErroClose}>
                <p> {messageText} </p> </Alert>


            </Col>
          </Row>
        </Container>
      </div>
    );
  }




  return (
    <>
      {renderPlaylists()}
    </>
  )
}