import React, { useState,useCallback,useEffect } from "react";
import { API } from "aws-amplify";
import { setCurrentGame } from "../libs/apiLib";
import { getUser } from "../libs/awsLib";
import { Button, Row, Col, Container, Modal, Alert } from "react-bootstrap";
import { resetWinner, togglehint, deleteGame, getWinner } from "../libs/apiLib";
import store from 'store';
import SpotifyPlayer from "../components/SpotifyPlayer";
import YoutubePlayer from "../components/YoutubePlayer";
import { useWebsocketSubscription, useWebsocketPublish } from "../libs/hooksLib";
import CONSTANTS from '../libs/constants';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import config from "../config";
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from "react-share";
import { BrowserView, MobileView, isTablet, isSafari, isMobile} from "react-device-detect";
import TrackSelect from "./TrackSelect";
import { trackEvent } from "../libs/events";
import { FaFireAlt } from 'react-icons/fa';
import { getRandomColouredLogo, limitText, updateGameStatus } from "../libs/util";
import Likes from "../components/Likes";

import TickerTape from "../components/TickerTape";



const clipboardCopy = 'Copy link to clipboard';
const trackSelectorCopy = 'Track Selector';
const musicPlayerSelectorCopy = 'Music Player';
const hintOffCopy = 'Turn off hints';
const hintOnCopy = 'Turn on hints';

let gameStatusMessages = [];


const jingle = new Audio('/audio/tada.mp3');




function Fire({fireCount}){
  return <span children={fireCount} />
}


export default function GameControl()  {

  // const user = store.get(CONSTANTS.STORE.MUSINGO_USER);
  // const channels = useMemo(()=>{
  //   return [user, `${CONSTANTS.WEBSOCKET.CHANNEL.ADMIN}_${user}`];
  // },[user]);
  
  const [user, setUser] = useState(store.get(CONSTANTS.STORE.MUSINGO_USER));

  // let channels = useMemo(()=>{
  //   return [user, `${CONSTANTS.WEBSOCKET.CHANNEL.ADMIN}_${user}`];
  // },[user]);


  const [channels, setChannels] = useState([user, `${CONSTANTS.WEBSOCKET.CHANNEL.ADMIN}_${user}`]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalMessageBig, setModalMessageBig] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalImage, setModalImage] = useState('');
  const [showTrackSelector, setShowTrackSelector] = useState(false);
  const [selectorCopy, setSelectorCopy] = useState(trackSelectorCopy);
  const [gameLink, setGameLink] = useState('');
  const [copyLinkCopy, setCopyLinkCopy] = useState(clipboardCopy);
  const [game, setGame] = useState(store.get(CONSTANTS.STORE.CURRENT_GAME) !== undefined ? store.get(CONSTANTS.STORE.CURRENT_GAME) : {});
  const [playerType] = useState(game.playlisttype ? game.playlisttype : config.PLAYER.SPOTIFY);
  const [showButtonMessage, setShowButtonMessage] = useState(false);
  const [buttonMessage, setButtonMessage] = useState('');
  const [sendHints, setSendHints] = useState(true);
  const [hintButtonCopy, setHintButtonCopy] = useState(hintOffCopy);
  const [disableHintButton, setDisableHintButton] = useState(true);
  const [disableDeleteButton] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [moveTickerTape, setMoveTickerTape] = useState(false);
  const [tickerMessage, setTickerMessage] = useState('');
  const [isWinner, setIsWinner] = useState(false);
  const [fireCount, setFireCount] = useState(0);
  const  history = useHistory();
  const [pausePlayer, setPausePlayer] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [likeSpeed] = useState(30);

 
  useEffect(()=>{

    async function checkUser() {


      let currentUser = await getUser();

      if(currentUser){

          store.set(CONSTANTS.STORE.MUSINGO_USER, currentUser);
          setUser(currentUser);

          setChannels([currentUser, `${CONSTANTS.WEBSOCKET.CHANNEL.ADMIN}_${user}`]);
          
      }


    }

    if(playerType === config.PLAYER.SPOTIFY && ( isSafari || isMobile)){
    
    setShowModalMessage(true);
    setModalMessageBig('The Music Player is not available in Safari or on Mobile');
    setModalMessage('Please try using a different browser like Chrome or IE');
    setModalImage(`/images/${getRandomColouredLogo()}`);
    }

    if(!user){

      checkUser();

    }
   

  },[])

  useEffect(()=>{


    if(!game.game){

      history.push(CONSTANTS.PAGE.GAMES);

    }

  },[game.game])

  useEffect(() => {

    if(game.game){

      
      API.get('api', `/users/game/${game.game}`)
      .then((gameResponse) =>{
        
        
        if(gameResponse[0] && gameResponse[0].hintenabled){
          
          let gameHint = gameResponse[0].hintenabled === 'true' ? true : false ;
          
          setSendHints(gameHint);
          
          if(gameHint){
            setHintButtonCopy(hintOffCopy);
          }
          else{
            setHintButtonCopy(hintOnCopy);
          }
          
          setDisableHintButton(false);
          
        }
        else{
          
          setSendHints(true);
          setHintButtonCopy(hintOffCopy);
          setDisableHintButton(false);
          
        }
          
        
      })
      .catch((error) =>{
        console.log(error.response);
      })
      
    }
  
  
  
  }, [game.game]);
  
  const messageAction = useCallback((messageEvent) =>{
  
    if (!messageEvent) {
      return;
    }

    let event = messageEvent.message;

    if (event.action) {

      let action = event.action;

      if (action === 'winnerdetails') {

          setIsWinner(true);
          setPausePlayer(true);
          showWinner(event.participantName); 

          setTickerMessage(`The winner is ${event.participantName} ++`);
          // setMoveTickerTape(false);

      }
      else if(action === 'fiya'){      
        addFire();
        addLikes();
        
      }
      else if(action === 'gamestatus'){

        updateGameStatus(setTickerMessage, setMoveTickerTape, gameStatusMessages, event)

      }
    }



  }, []);
  
  useWebsocketSubscription(channels, messageAction);

  const addFire = () =>{
    
    //TODO: Need to fix this hack
    let storeFireCount = store.get(CONSTANTS.STORE.FIRE) ? store.get(CONSTANTS.STORE.FIRE) : 0;

    let newFireCount = storeFireCount + 1;

    store.set(CONSTANTS.STORE.FIRE, newFireCount);

    setFireCount(newFireCount);
  }

  const showWinner = (winnerName, playJingle=true) =>{

    setShowModalMessage(true);
    setModalMessageBig('Winner!');
    setModalMessage(`The winner is ${winnerName}`);
    setModalImage('/images/logo-yellow.png');
    if(jingle && playJingle){
      
      jingle.play();

    }
    setPausePlayer(false);

  }

  const updateGameController = (newGame) =>{

    

    setIsWinner(false);
    setTickerMessage('');
    setMoveTickerTape(false);

    store.set(CONSTANTS.STORE.CURRENT_GAME, newGame);
    setGame(newGame);
    handleNewGame(newGame);

    setCurrentGame(newGame.game);
  }

  const lastTrackPlayed = (track) => {

    store.set(CONSTANTS.STORE.FIRE, 0);
    setFireCount(0);
    store.remove(CONSTANTS.STORE.LIKES);
    setLikeCount(0);
   
    if(sendHints){

      sendWebsocketMessage({
        action:'lastplayed',
        name: limitText(track.name),
        id: track.id,
     }, channels[0]);


    }

   

  }

  const addLikes = () =>{
    
    //TODO: Need to fix this hack
    let storeLikeCount = store.get(CONSTANTS.STORE.LIKES) ? store.get(CONSTANTS.STORE.LIKES) : 0;

    let newStoreLikeCount = storeLikeCount + 1;

    store.set(CONSTANTS.STORE.LIKES, newStoreLikeCount);

    if(newStoreLikeCount <= CONSTANTS.PLAYER.MAX_LIKES){
            setLikeCount(newStoreLikeCount + 2);
    }
  }

//TODO: need to use this to send messages
const sendWebsocketMessage = useWebsocketPublish();

  
  const handleNewGame = (gameObj)=>{



    sendWebsocketMessage({
       action:'newgame',
       game: gameObj.game,
       gamename: gameObj.name
    }, channels[0]);
  
  }

  const handleShareModalClose = () => {

    setCopyLinkCopy(clipboardCopy);
    setShowShareModal(false);
    

  }

  const onCopy = () => {
  
    setCopyLinkCopy('Link Copied');
    trackEvent('copiedLink-gamecontrol', gameLink);
  };
  
 const handleReset = (event) =>{
    event.preventDefault();  

    sendWebsocketMessage({
       action:'reset'
    }, channels[0]);

    setButtonMessage("Clicks reset");
    setShowButtonMessage(true);
    setMoveTickerTape(false);
  
  }

  
  const  handleResetWinner = async () =>{


    try{
      
      await resetWinner({game: game.game, user});
      setIsWinner(false);
      setMoveTickerTape(false);
      setTickerMessage('');
      setButtonMessage("Winner reset");
      setShowButtonMessage(true);
    }
    catch(e){
      setButtonMessage("Error, we could not reset winner");
      setShowButtonMessage(true);
    }
    
  }

  const handleGetWinner = async () => {

      try{


        const winner = await getWinner(game.game);

        setIsWinner(true);
        showWinner(winner.winnername, false);





      }
      catch(e){

        setButtonMessage("We could not find a winner");
        setShowButtonMessage(true);

      }

  }

  const handlesModeldeleteConfirmationClose = () =>{

    setShowDeleteConfirmation(false);

  }

  const handleModalClose = ()=> {

    setShowModalMessage(false);
    setModalMessageBig('');
    setModalMessage('');
    setModalImage('');

    if(playerType === config.PLAYER.SPOTIFY && ( isSafari || isMobile)){
    
    history.push('/')
      }

  }

  // const  handleChangeGame = async () =>{

  //   history.push(CONSTANTS.PAGE.GAMES);
    
  // }


  const  handleTrackSelector = async () =>{

 

    if(showTrackSelector){

      setShowTrackSelector(false);
      setSelectorCopy(trackSelectorCopy);
      
    }
    else{
     
      setShowTrackSelector(true);
      setSelectorCopy(musicPlayerSelectorCopy);
    }

    
    
  }

  const handleHintToggle = () => {

    if(sendHints){
      setHintButtonCopy(hintOnCopy);
      setButtonMessage("Hints turned off");
    }
    else{
      setHintButtonCopy(hintOffCopy);
      setButtonMessage("Hints turned on");
    }

    setSendHints(!sendHints);

    sendWebsocketMessage({
      action:'hint',
      enabled: !sendHints
   }, channels[0]);

   togglehint({game: game.game, hintenabled: !sendHints});

   
   setShowButtonMessage(true);
    



  }

  const handleDeleteGame = () =>{

    deleteGame(game.game)
    .then((response)=>{

      setButtonMessage("Game Deleted");
      setShowButtonMessage(true);

      const storedGames = store.get(CONSTANTS.STORE.GAMES);

      if(storedGames && storedGames.length === 1){
        store.remove(CONSTANTS.STORE.GAMES);
      }


      history.push(CONSTANTS.PAGE.GAMES);


    })
    .catch((error)=>{

      setShowDeleteConfirmation(false);

      if(error.response && error.response.data.message){

        setButtonMessage(error.response.data.message);
      }
      else{
        setButtonMessage('There was an error');

      }

      setShowButtonMessage(true);      
    })
    

  }
 

  const  handleInvitePlayer = async () =>{


    const gameLink = encodeURI(`${config.bingo.URL}/invite?user=${game.user}&game=${game.game}&gamename=${game.name}`);
      setGameLink(gameLink);
    setShowShareModal(true);
    
  }


  function renderControlButtons(){
    return (
      <Row>
      <Col>

      <p><FaFireAlt/> <Fire fireCount={fireCount} /> <FaFireAlt/> </p>


      {/* { moveTickerTape && !isWinner && <TickerTape moveTickerTape={moveTickerTape} tickerMessage={tickerMessage} /> } */}

      { moveTickerTape && <TickerTape moveTickerTape={moveTickerTape} tickerMessage={tickerMessage} /> }


     
           
    

      <Button  onClick={handleReset} aria-label="select" size="sm" variant="link">
               Reset Clicks
               </Button>{' '}

       <Button  onClick={handleResetWinner} aria-label="select" size="sm" variant="link">
           Reset Winner
       </Button>{' '}

       <Button  onClick={handleGetWinner} aria-label="select" size="sm" variant="link">
           Get Winner
       </Button>{' '}

       <Button  onClick={ () => handleInvitePlayer(game)} aria-label="select" size="sm" variant="link">
           Invite player
       </Button>{' '}

       <Button  onClick={handleHintToggle} aria-label="select" size="sm" variant="link" disabled={disableHintButton}>
           {hintButtonCopy}
       </Button>{' '}

       {/* <Button  onClick={handleDeleteGame} aria-label="select" size="sm" variant="link" disabled={disableDeleteButton} >
           Delete Game
       </Button>{' '} */}

        <Button  onClick={()=>{setShowDeleteConfirmation(true)}} aria-label="select" size="sm" variant="link" disabled={disableDeleteButton} >
           Delete Round
       </Button>{' '}

       

     
       <BrowserView>
       {(!isTablet && !isSafari) &&
       <>
       <Button  onClick={handleTrackSelector} aria-label="select" size="sm" variant="link">
           {selectorCopy}
       </Button>
       </>
  }
       </BrowserView>

        {/* Hints {' '}
       <ToggleButtonGroup size='sm' type="radio" name="hints" value={sendHints} onChange={() =>{setSendHints(!sendHints)}}>
      <ToggleButton value={false}>off</ToggleButton>
      <ToggleButton value={true}>on</ToggleButton>
    </ToggleButtonGroup> */}

      
       <Row className="justify-content-md-center">
        <Col lg={3}>
        { showButtonMessage &&
          <Alert variant="success" onClose={() => setShowButtonMessage(false)} dismissible>{buttonMessage}</Alert>
        }
       </Col>
      </Row>
      


       
       </Col>
      </Row>

    );
  }


  return (
    <div id="bingo" className="content-wrap">
     
      <Container>
      <Likes count={likeCount} speed={likeSpeed} />

 

        <Row className="justify-content-md-center">
          <Col   className="justify-content-md-center text-center">
           
           


            {/* <Row className="card-wrap"> */}

            <h1>{game.name}</h1>   

            {/* {renderToast()}       */}

            <BrowserView>
  
            {(!isTablet && !isSafari) &&
          
            <>

            {!showTrackSelector && playerType === config.PLAYER.SPOTIFY &&

            <SpotifyPlayer onUpdate={updateGameController} onTrack={lastTrackPlayed} setTrackSelector={handleTrackSelector} pausePlayer={pausePlayer} /> 

            }

            {!showTrackSelector && playerType === config.PLAYER.YOUTUBE &&

            <YoutubePlayer onUpdate={updateGameController} onTrack={lastTrackPlayed} setTrackSelector={handleTrackSelector} pausePlayer={pausePlayer} /> 

            }

            {showTrackSelector &&

              <>
               {renderControlButtons()}
                    <TrackSelect onUpdate={updateGameController} onTrack={lastTrackPlayed} />
              </>

            }

            </>

            }

            {(isSafari && !isTablet && playerType === config.PLAYER.SPOTIFY ) &&
            
             
              <>
              {/* safari browswer */}
               <p><strong>The music player is not available in Safari.</strong> <br/>Please try using a different browser like <strong>Chrome</strong> or <strong>IE</strong> or  <strong>Firefox</strong>. </p>
               {renderControlButtons()}
               <TrackSelect onUpdate={updateGameController} onTrack={lastTrackPlayed} />
              </>
            }

          {(isSafari && !isTablet && playerType === config.PLAYER.YOUTUBE ) &&
            
             
            <>
            {renderControlButtons()}
            <YoutubePlayer onUpdate={updateGameController} onTrack={lastTrackPlayed} setTrackSelector={handleTrackSelector} pausePlayer={pausePlayer} /> 
            </>
          }
            
           
          </BrowserView>

          <MobileView>
            {/* showing mobile */}
            {renderControlButtons()}

            {!showTrackSelector && playerType === config.PLAYER.YOUTUBE &&

                  <YoutubePlayer onUpdate={updateGameController} onTrack={lastTrackPlayed} setTrackSelector={handleTrackSelector} pausePlayer={pausePlayer} /> 

              }

              { showTrackSelector &&

                    <TrackSelect onUpdate={updateGameController} />
              }
          </MobileView>

              {renderControlButtons()}
             
         

          </Col>
        </Row>
      </Container>
      <Modal show={showShareModal} onHide={handleShareModalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  <Row className="justify-content-md-center">
                    <Col>

                      <h1> Invite Players</h1>


                      <p>Invite your friends using the buttons below</p>


                      <div className="text-center">
                      <CopyToClipboard onCopy={onCopy} text={gameLink}>
                        <Button variant="secondary">{copyLinkCopy}</Button>
                      </CopyToClipboard>
                      </div>

                      <div className="text-center">
                        <FacebookShareButton url={gameLink} ><FacebookIcon size={32}  /> </FacebookShareButton>{' '}
                        <WhatsappShareButton url={gameLink}><WhatsappIcon size={32}  /> </WhatsappShareButton >{' '}
                        <EmailShareButton url={gameLink}><EmailIcon size={32}  /> </EmailShareButton >{' '}
                      </div>

                    </Col>

                  </Row>

                </Modal.Body>
              </Modal>


              <Modal show={showModalMessage} onHide={handleModalClose}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h1>{modalMessageBig}</h1>
                    <p>{modalMessage}</p>
                    <Button variant="primary" onClick={handleModalClose}>Close</Button>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <img alt="modalImage" src={modalImage} />
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>

            <Modal show={showDeleteConfirmation} onHide={handlesModeldeleteConfirmationClose}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h1>Delete game</h1>
                    <p>Are you sure you want to delete the game {game.name}?</p>

                    <Button variant="primary" onClick={handleDeleteGame}>Yes</Button> {'  '}
                    <Button variant="primary" onClick={handlesModeldeleteConfirmationClose}>No</Button>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <img alt="modalImage" src={`/images/${getRandomColouredLogo()}`} />
                  </Col>
                </Row>

              </Modal.Body>
            </Modal>

    </div>
  );
}
