import React from "react";
import {Button} from "react-bootstrap";
import { FaRedoAlt } from 'react-icons/fa';


export default ({
	isLoading,
	text,
	loadingText,
	className = "",
	disabled = false,
	...props
}) =>
<Button
	color="primary"
	variant="primary"
	// className={`LoaderButton ${className}`}
	disabled={disabled || isLoading}
	{...props}
	>
	{isLoading && <FaRedoAlt className="spinning" />}
	{!isLoading ? text : loadingText}
</Button>;
