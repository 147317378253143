import React, {useEffect, useState, useRef } from 'react';
import {Button, Row, Col, DropdownButton, Dropdown} from "react-bootstrap";
import { API } from "aws-amplify";
import store from 'store';
import {getUser} from "../libs/awsLib";
import CONSTANTS from '../libs/constants';
import { FaCheckCircle } from 'react-icons/fa';
import { limitText, getRandomColouredLogo, getYouTubeImage } from "../libs/util";
import { useHistory } from 'react-router-dom';






const  TrackSelect = ({onUpdate, onTrack, ...props}) => {

  const history = useHistory();
  let currentGame = store.get(CONSTANTS.STORE.CURRENT_GAME);
  const games = store.get(CONSTANTS.STORE.GAMES) ? store.get(CONSTANTS.STORE.GAMES) : [];

  


  let userid = currentGame ? currentGame.user : null ;
  // let gameid = currentGame ? currentGame.game : null;
  // let playlistid = currentGame ? currentGame.playlist : null;ß

const [playlistid, setPlaylistid] = useState(currentGame ? currentGame.playlist : null);
const [gameid, setGameid] = useState(currentGame ? currentGame.game : null);
const [playlist, setPlaylist] = useState();
const [selectedTracks, setSelectedTracks] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const [hasData, setHasData] = useState(false);
const [trackImages, setTrackImages] = useState([]);
const user = useRef(userid);
const game = useRef(gameid);




useEffect(() => {

  async function onLoad() {


    user.current = store.get(CONSTANTS.STORE.MUSINGO_USER);
  if(!user.current){
    user.current = await getUser();
    store.set(CONSTANTS.STORE.MUSINGO_USER, user.current);
  }
   
    try {

      setIsLoading(true);
      const result = await loadPlaylist();
      //TODO: Check that there is data;
      const gameDetails = result[0][0];

      if(gameDetails.images){

        setTrackImages(gameDetails.images);

      }

      
    
      setSelectedTracks(gameDetails.answer);
      const playlist = result[1];

      playlist.tracks.sort((a, b) =>{
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      });

      
      setPlaylist(playlist);
      setHasData(true)
    } catch (e) {
      console.log(e);
      setHasData(false);
    }
    setIsLoading(false);
  }

  onLoad();



}, [gameid]);

// function findTrack(text){

//   const result = playlist.tracks.find( ({ name }) => name.toLowerCase().inclues(text.toLowerCase()
//   ) );

// }

function handleGoToGames(){

  history.push(CONSTANTS.PAGE.GAMES);
}

if(!currentGame){

    return(
      <Row className="justify-content-md-center">
      <Col xs md="6" className="justify-content-md-center text-center">
      <h4>Tracks Played</h4>

      <p> Please select a game first</p>

      <Button onClick={handleGoToGames} >Games</Button>


      </Col>
      </Row>

    );

  }

function isSelected(trackId, asString) {


 
  if (selectedTracks && selectedTracks.includes(trackId)) {

    if(asString){
      return 'true';
    }

    return true;
  } else {
    if(asString){
      return 'false';
    }
    return false;
  }
}

//TODO: DRY

async function handleTracks(tracks){

  

  let requestBody = {
    body: {
      game: game.current,
      user: user.current,
      tracks

    }
  };

  API.post('api', '/users/addanswers', requestBody);

  let alltracks = [...tracks]
  setSelectedTracks(alltracks);
  
}


async function handleTrack(track){

  if(onTrack){

    onTrack(track);
  }


  let myInit = {
    body: {
      game: game.current,
      user: user.current,
      tracks:[track.id]

    }
  };

  API.post('api', '/users/addanswers', myInit);

  let tracks = [...selectedTracks]
  tracks.push(track.id);
  setSelectedTracks(tracks);
  
}



async function loadPlaylist(){




  let promises = [];
  //TODO: put this in one request
  promises.push(API.get('api', `/users/game/${game.current}`));

  if(currentGame.playlistowner){

    promises.push(API.get('api', `/users/playlist/${playlistid}?owner=${CONSTANTS.USER.MUSINGO}`));
    

  }
  else{

    promises.push(API.get('api', `/users/playlist/${playlistid}`));

  }

  
  
  let results = await Promise.all(promises);

  // console.log(results);

  return results;
}

const handleGameSwitch = (newGame) =>{

  const tempGame = JSON.parse(newGame);

  const tempGameid = tempGame.game;

  setGameid(tempGameid);
  setPlaylistid(tempGame.playlist)
  game.current = tempGameid;
  // store.set(CONSTANTS.STORE.CURRENT_GAME, tempGame);

  if(onUpdate){
    onUpdate(tempGame);
  }

  // loadPlaylist(game.current.playlist);
  // swtichPlaylist(game);

}


function handleSelectAll(){

    let ids = playlist.tracks.map(track => track.id);

    handleTracks(ids);
}


return (
  // <div id="bingo" className="content-wrap">
   
  //   <Container fluid>

      <Row className="justify-content-md-center">
        <Col xs md="6" className="justify-content-md-center text-center">
         
          {
                isLoading && <p>Loading...</p>
          }


                  <h4>Tracks Played</h4>

                  <p> As you play a track select it to add it to the bingo database</p>


       <DropdownButton id="dropdown-basic-button" title="Change Round" onSelect={handleGameSwitch} size="sm" variant="link">

       <Dropdown.Item key={'current'} eventKey={JSON.stringify(currentGame)} >{'Reload current game'}</Dropdown.Item>
       {games.length > 0 &&

                games.filter(gameObj => gameObj.game !== currentGame.game ).map(
              (newgame, i) =>
                <Dropdown.Item key={i} eventKey={JSON.stringify(newgame)} >{newgame.name}</Dropdown.Item>

        )
      }  
      </DropdownButton>

      {hasData &&

      <Button  onClick={handleSelectAll} aria-label="select" size="sm" variant="link">
           Select All
       </Button>
        }

          <Row className="card-wrap">
           

                  {hasData &&

                        playlist.tracks.map(
                          (track, i) =>
                            <Col key={i} xs="6" md="4" lg="3" className="d-flex align-items-stretch">
                              <div className="d-flex align-items-stretch">
                                <Button onClick={() => handleTrack(track)} >

                                  {/* <img alt={track.name} src={track.images[0].url ? track.images[0].url : getYouTubeImage(track.id, trackImages)} className={isSelected(track.id, true) && "selected"}  /> */}
                                <img alt={track.name} src={track.images.length > 0 && track.images[0].url ? track.images[0].url : track.images[0]} className={isSelected(track.id, true) && "selected"}  /> 
                                 
                                  <span>{ track.name ? limitText(track.name) : 'No Name' }</span> {' '}
                                  {isSelected(track.id) &&                            
                                       <FaCheckCircle className="tick"/>
                                  }                                
                                </Button>
                              </div>
                            </Col>

                      )
                      }

          </Row>
         
        </Col>
      </Row>
  //   </Container>
  // </div>
);
}

export default TrackSelect;