import React from "react";
import { Route, Switch } from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Login from "./containers/Login/Login";
import Signup from "./containers/Login/Signup";
import ResetPassword from "./containers/Login/ResetPassword";
import RequestInvite from "./containers/Login/RequestInvite";
import NotFound from "./containers/NotFound";

import SpotifyConnect from "./containers/SpotifyConnect";
import SpotifyPlaylists from "./containers/SpotifyPlaylists";
import AddGame from "./containers/AddGame";
import AddPlaylist from "./containers/CreatePlaylist";
import Bingo from "./containers/Bingo";
import Invite from "./containers/Invite";
import TrackSelect from "./containers/TrackSelect";
import Games from "./containers/Games";
import GameControl from "./containers/GameControl";


import Theme from "./containers/Theme";
import About from "./containers/About";
import Privacy from "./containers/Privacy";
import Help from "./containers/Help";
import Payment from "./containers/Payment";
import Account from "./containers/Account";
import Home from "./containers/Home";
import Wizard from "./containers/Wizard";
import AdminSignup from "./containers/Admin/AdminSignup";
import System from "./containers/Admin/System";
import YoutubePlayer from "./components/YoutubePlayer";
import AddYoutubePlaylist from "./containers/AddYoutubePlaylist";
import Playlists from "./containers/Playlists";
import Playlist from "./containers/Playlist";


// import ComingSoon from "./containers/ComingSoon";


export default function Routes({childProps}) {
  return (
    <Switch>
          <Route exact path="/yt">
            <YoutubePlayer/>     
      </Route>
      <AuthenticatedRoute exact path="/">
        <Games />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/spotifyconnect">
            <SpotifyConnect />  
       </AuthenticatedRoute>   
      <AuthenticatedRoute exact path="/spotifyplaylists">
            <SpotifyPlaylists />     
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/addgame">
            <AddGame />     
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/createplaylist">
            <AddPlaylist />     
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/addyoutubeplaylist">
            <AddYoutubePlaylist />     
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/playlists">
            < Playlists />     
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/playlist">
            < Playlist />     
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/theme">
            <Theme />     
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/trackselect">
            <TrackSelect />     
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/wizard">
            <Wizard />     
      </AuthenticatedRoute>
      {/* <AuthenticatedRoute exact path="/signup">
        <Signup />
      </AuthenticatedRoute> */}
      <AuthenticatedRoute exact path="/Games">
            <Games />     
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/rounds">
            <Games />     
      </AuthenticatedRoute>
      <Route exact path="/bingo">
            <Bingo/>     
      </Route>
      
      <Route exact path="/invite">
            <Invite/>     
      </Route>
      <AuthenticatedRoute exact path="/gamecontrol">
            <GameControl/>   
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/account">
            <Account/>   
      </AuthenticatedRoute>
      <Route exact path="/help">
            <Help/>   
      </Route>
      <Route exact path="/home">
            <Home/>   
      </Route>
      <Route exact path="/about">
            <About/>   
      </Route>
      <Route exact path="/privacy">
        <Privacy />
      </Route>
      <AuthenticatedRoute exact path="/buy">
            <Payment/>   
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/admin/createuser">
            <AdminSignup/>   
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/admin/system">
            <System />     
      </AuthenticatedRoute>
      {/* <UnauthenticatedRoute exact path="/">
        <ComingSoon />
      </UnauthenticatedRoute> */}
      <UnauthenticatedRoute exact path="/login">
           <Login />
      </UnauthenticatedRoute>
      {/* </UnauthenticatedRoute> */}
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/reset-password">
        <ResetPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/requestinvite">
            <RequestInvite/>     
      </UnauthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}