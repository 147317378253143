import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Container, Form, Alert} from "react-bootstrap";
import { useFormFields } from "../libs/hooksLib";
import LoaderButton from "../components/LoaderButton";
import {  youtube_parser} from "../libs/util";
import { useHistory } from 'react-router-dom';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { useQuery } from "../libs/hooksLib";
import CONSTANTS from '../libs/constants';
import { addplaylistManual } from "../libs/apiLib";


// import { imageCheck } from "../libs/util";







export default function CreatePlaylist() {

  const query = useQuery();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [addAsOwner, setAddAsOwner] =  useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [messageVariant, setMessageVariant] = useState('info');

  useEffect(() => {

    let isCurrent = true;

    if (query.get(CONSTANTS.QUERY.OWNER) && isCurrent ) {
      
          setAddAsOwner(true);
    }

    return () => {
      isCurrent = false;
    }
  
  }, []);

  const [fields, handleFieldChange] = useFormFields({
    name: "",
    image: ""

  });

  const [inputFields, setInputFields] = useState([
    { id: '', name: '', images:[] },
   
  ]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ id: '', name: '', images:[] });
    setInputFields(values);
  };

  const handleRemoveFields = index => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "id") {

  
      const videoid = youtube_parser(event.target.value);

      if(videoid){
        values[index].id = videoid;
        values[index].images[0] = `https://img.youtube.com/vi/${videoid}/maxresdefault.jpg`;
      }
      else{
        values[index].id = event.target.value;
        values[index].images[0] = `https://img.youtube.com/vi/${event.target.value}/maxresdefault.jpg`;
        
      }

      
    } else {
      values[index].name = event.target.value;
    }

    setInputFields(values);
  };

  const handleErroClose = () => {
    setShowMessage(false);
  }


  const validateForm = () => {
    return (

        
        fields.name || fields.image || inputFields.lenght >= 2
      

    );
  }



  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    console.log(inputFields);

    const playlistDetails = {

      name: fields.name,
      image: fields.image,
      type: 'youtube',
      tracks: inputFields

    };


       if(addAsOwner){

        playlistDetails.owner = CONSTANTS.USER.MUSINGO;
      }

      if(playlistDetails.tracks.length < 1){ // CONSTANTS.PLAYER.MIN_TRACKS_LENGTH){

        setIsLoading(false);
        setMessageVariant('danger');
        setMessageText(`You need at least ${CONSTANTS.PLAYER.MIN_TRACKS_LENGTH} tracks`);
        setShowMessage(true);

      }
      else{

         try{

           await addplaylistManual(playlistDetails);
           history.push('/playlists');
          
         }
         catch(e){

          setIsLoading(false);
          setMessageVariant('danger');
          setMessageText(e.message);
          setShowMessage(true);


         }


      }



    setIsLoading(false);
    

    

  }



  



  return (
    <div id="bingo" className="content-wrap">

      <Container fluid>

        <Row className="justify-content-center">
          <Col xs md="6" className="justify-content-center text-center">
            <h1>Create Playlist</h1>

            {
              isLoading && <p>Loading...</p>
            }


                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="name">
                        <Form.Control
                            autoFocus
                            type="text"
                            placeholder="Playlist Name"
                            aria-label="Playlist Name"
                            value={fields.name}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="image">
                        <Form.Control
                            autoFocus
                            type="text"
                            placeholder="Playlist image url"
                            aria-label="Playlist image url"
                            value={fields.image}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
           

          {inputFields.map((inputField, index) => (
            <Fragment key={`${inputField}~${index}`}>
              <div className="form-row">
                <div className="form-group col col-xl text-left">
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={() => handleRemoveFields(index)}
                  >
                    <FaMinus/>
                  </button>
                  { ' '}
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={() => handleAddFields()}
                  >
                    <FaPlus/>
                  </button>
                </div>
                <div className="form-group col-8 col-sm-4 col-md-8 col-lg-4 col-xl">
                  <Form.Control
                              autoFocus
                              type="text"
                              id="id"
                              name="id"
                              placeholder="Song ID"
                              aria-label="Song ID"
                              value={inputField.id}
                              onChange={event => handleInputChange(index, event)}
                          />
                </div>
                <div className="form-group col-12 col-sm-5 col-md-12 col-lg-5 col-xl-7">
                  
                  <Form.Control
                              autoFocus
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Song Name"
                              aria-label="Song Name"
                              value={inputField.name}
                              onChange={event => handleInputChange(index, event)}
                          />               
                </div>
              </div>
              
            </Fragment>
          ))}

             

             



              <LoaderButton
                className="pull-right"
                block
                type="submit"
                isLoading={isLoading}
                text="Add"
                loadingText="Adding..."
                disabled={!validateForm()}
              />
              <Alert style={{ marginTop: '8px' }} key='message' show={showMessage} dismissible="true" variant={messageVariant} onClose={handleErroClose}>
                {messageText}
              </Alert>
            </Form>

           




          </Col>
        </Row>
      </Container>
    </div>
  );
}